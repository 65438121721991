import React, { Component } from 'react';
import { find, propEq } from 'ramda';
import { Typography } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  header: {
    fontSize: '1em',
    fontWeight: 'bold',
    margin: '10px 0',
  },
  paragraph: {
    marginBottom: 20,
  },
};

class Index extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.all();
    this.props.getUserChannels();
  }

  selectChannel = (event) => {
    //searches array for selected channel name
    //console.log("this is a props user channels ", this.props.userChannels);
    if (
      this.props.userChannels &&
      this.props.userChannels.channels.find(function (channel) {
        return channel.name === event.name;
      })
    ) {
      this.props.deleteChannel(event);
    } else {
      this.props.createChannel(event);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Typography variant="h5" className="mb-3">
          Channel Settings
        </Typography>
        <Typography className={classes.header}>Add or Edit Channels</Typography>
        <Typography className={classes.paragraph}>
          Select respective channels to your business, offering or organization.
          Lifetaggers users will subscribe to channels they wish to stay
          productive or constantly discover while on the move.
        </Typography>
        <p className="showView">
          {/* Channel Buttons */}
          {this.props.channel.map((item) => (
            <span className="floatButton" key={item.name}>
              <Fab
                color={
                  find(propEq('name', item.name), this.props.userChannels)
                    ? 'secondary'
                    : 'default'
                }
                variant="extended"
                onClick={() => this.selectChannel({ name: item.name })}
              >
                {find(propEq('name', item.name), this.props.userChannels) ? (
                  <CheckIcon />
                ) : (
                  <AddIcon />
                )}
                &nbsp;
                {item.name}
              </Fab>
            </span>
          ))}
        </p>
      </div>
    );
  }
}

export default withStyles(styles)(Index);
