import {
  CHANNELS,
  FETCH_CHANNELS,
  FETCH_USER_CHANNELS,
  DELETE_CHANNEL,
  CREATE_CHANNEL,
  UPDATE_CHANNEL,
  updateChannel,
  setChannels,
  setUserChannels,
  setUserChannel,
} from './channel.actions';
import { setNotification } from '../shared/actions/notification.actions';
import { setLoader } from '../shared/actions/ui.actions';
import {
  API_ERROR,
  API_SUCCESS,
  apiRequest,
} from '../shared/actions/api.actions';
//import { showSnack, dismissSnack } from 'react-redux-snackbar';
//import {reject} from 'ramda'

export const channelMiddleWare = () => (next) => (action) => {
  next(action);
  switch (action.type) {
    case FETCH_CHANNELS:
      next(
        apiRequest({
          feature: CHANNELS,
          params: action.payload,
          func: 'getChannel',
        })
      );
      next(setLoader({ state: true, feature: CHANNELS }));
      break;

    case DELETE_CHANNEL:
      //removed, we are sending the id as a param to delete the channel
      //  channelApiUrl += `/${action.payload.id}`;
      ////console.log("delete channel id:", action.payload);
      next(
        apiRequest({
          feature: CHANNELS,
          params: action.payload,
          func: 'deleteChannel',
        })
      );
      next(setLoader({ state: true, feature: CHANNELS }));
      break;

    case CREATE_CHANNEL:
      ////console.log("create channel id:", action.payload.index);
      //next(createChannel({index: action.index}));
      next(
        apiRequest({
          body: action.data,
          feature: CHANNELS,
          params: action.payload,
          func: 'createChannel',
        })
      );
      next(setLoader({ state: true, feature: CHANNELS }));
      break;

    case UPDATE_CHANNEL:
      //use can't update channels. we send id as a param
      //channelApiUrl += `/${action.payload.id}`;
      next(updateChannel({ index: action.index }));
      next(
        apiRequest({
          body: action.data,
          feature: CHANNELS,
          params: action.payload,
        })
      );
      break;

    case `${CHANNELS} ${API_SUCCESS}`:
      ////console.log("channels api success:", action);
      if (action.actionType == 'getChannel') {
        next(setChannels(action.payload));
      }

      if (action.actionType == 'getUserChannels') {
        ////console.log("inside get user channels:", action);
        next(setUserChannels(action.payload));
      }

      if (action.actionType == 'createChannel') {
        ////console.log("inside create")
        next(setUserChannel(action.payload));
      }
      //next(setChannels(action.payload));
      //next(setChannels({ channel: action.payload.data }));
      next(setLoader({ state: false, feature: CHANNELS }));
      break;

    case `${CHANNELS} ${API_ERROR}`:
      next(
        setNotification({ message: action.payload.message, feature: CHANNELS })
      );
      next(setLoader({ state: false, feature: CHANNELS }));
      break;

    case FETCH_USER_CHANNELS:
      next(
        apiRequest({
          feature: CHANNELS,
          params: action.payload,
          func: 'getUserChannels',
        })
      );
      next(setLoader({ state: true, feature: CHANNELS }));
      break;

    default:
      break;
  }
};
