export const SET_LOADER = 'SET_LOADER';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const UI = 'UI';

export const setLoader = ({ state, feature }) => ({
  type: `${feature} ${SET_LOADER}`,
  payload: state,
  meta: { feature },
});

export const showNotification = ({ state, feature }) => ({
  type: SHOW_NOTIFICATION,
  payload: state,
  meta: { feature },
});
