import React from 'react';
import Parse from 'parse';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { TextField, Button, Box, Grid, Typography, Modal } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import TagMediaCapture from '../ui/tagMediaCapture.component';
import { createTag } from '../tags/tag.actions';
import { useTheme } from '@mui/material/styles';
export function CreateNFCTag() {
  const dispatch = useDispatch();
  const [tapToWrite, setTapToWrite] = useState(false);
  const [isWriting, setIsWriting] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [type, setType] = useState('');
  const [message, setMessage] = useState('');
  const theme = useTheme();

  const [isCompatible, setIsCompatible] = useState(false);

  useEffect(() => {
    const isCurrentBrowserCompatible = 'NDEFReader' in window;

    setIsCompatible(isCurrentBrowserCompatible);

    if (!isCompatible) {
      checkForCompatibleBrowser();
    }
  }, []);

  const handleMessage = (text) => {
    setMessage(text);
  };

  const handleImage = (file) => {
    if (file.length && file.length > 0) {
      setImage(file);
      for (let i = 0; i < file.length; i++) {
        switch (file[i].type) {
          case 'application/pdf':
            setType('pdf');
            break;
          case file[i].type.includes('image'):
            setType('image');
            break;
          case file[i].type.includes('video'):
            setType('video');
            break;
          default:
            break;
        }
      }
    }
  };

  const checkForCompatibleBrowser = async () => {
    if (
      navigator.userAgent.includes('iPhone') ||
      navigator.userAgent.includes('iPad')
    ) {
      // iOS - check if Chrome is installed
      if (window.navigator.standalone === true) {
        // iOS Chrome
        window.open(window.location.href, '_self', '', 'googlechrome');
      } else {
        // Default Safari
        // Prompt user to open Chrome
        const confirmOpen = window.confirm(
          'Please open this page in the Chrome app to use NFC capabilities.'
        );

        if (confirmOpen) {
          window.open('googlechrome://' + window.location.href);
        }
      }
    } else {
      // Non iOS
      // Check for compatible Android browser
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const nfcID = uuidv4();
    console.log(image);
    console.log(type);
    console.log(message);
    console.log(tapToWrite);
    console.log(isWriting);
    setTapToWrite(true);
    try {
      const url = `${process.env.REACT_APP_URL}/nfctags/${nfcID}`;
      console.log('this is the url');

      try {
        const ndef = new window.NDEFReader();
        console.log('ndef', ndef);
        ndef.scan().then(() => {
          console.log('scan started');
        });

        ndef.addEventListener(
          'reading',
          async () => {
            console.log('reading event listner');
            setIsWriting(true);

            await Parse.Cloud.run('createNFC', {
              name,
              description,
              nfcID,
            });
            console.log('reading event listner');
            await ndef.write({
              records: [
                {
                  recordType: 'url',
                  data: url,
                },
              ],
            });
            const lifeTag = {
              message: description,
              image: image,
              type: type,
              nfcId: nfcID,
            };

            const lifeTags = { lifeTags: [lifeTag] };

            dispatch(createTag({ query: lifeTags }));
            setIsWriting(false);
            setTapToWrite(false);
          },
          { once: true }
        );
      } catch (error) {
        console.log('Error writing URL to tag', error);
        setIsWriting(false);
        setTapToWrite(false);
      }
    } catch (error) {
      console.log(error);
      setIsWriting(false);
      setTapToWrite(false);
    }
  };

  return (
    <div>
      <Box sx={{ p: 2 }}>
        {isCompatible ? (
          <div>
            <Modal
              open={tapToWrite}
              style={{ alignItems: 'center', justifyContent: 'center' }}
              onClose={() => setTapToWrite(false)}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              sx={{
                position: 'absolute',
                top: '50%',
              }}
            >
              <Box sx={theme.custom.modal}>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  {isWriting
                    ? 'Writing... Please wait'
                    : 'Touch the LifeTap with your phone.'}
                </Typography>
                {!isWriting && (
                  <Typography variant="caption">
                    This may take a few seconds
                  </Typography>
                )}
              </Box>
            </Modal>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Create LifeTap
            </Typography>

            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    helperText="Enter a descriptive name"
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: true,
                      focused: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TagMediaCapture
                    messageCallback={handleMessage}
                    imageCallback={handleImage}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    fullWidth
                  >
                    Create LifeTap
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        ) : (
          <div>
            <Typography variant="h5" sx={{ mb: 2 }}>
              NFC not available
            </Typography>

            <Typography>
              Please use a compatible browser to access NFC capabilities.
            </Typography>
          </div>
        )}
      </Box>
    </div>
  );
}
