import React, { Component } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

let theme = createMuiTheme();

const styles = {
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
};

class UiBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.loader,
    };
    //this.setChannels = this.setChannels.bind(this);
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Backdrop
          className={classes.backdrop}
          open={this.props.loader}
          onClick={this.handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
}

export default withStyles(styles)(UiBlock);
