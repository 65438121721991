import React, { Component } from 'react';

import FileDrop from './fileDrop.component';
import { ALLOWED_DOCUMENT_MIMETYPES } from '../config';

// const styles = theme => ({
//     padding: {
//         padding: `0 ${theme.spacing.unit * 2}px`,
//     },
//     dropzone: {},
// });

class Upload extends Component {
  handleDocumentDrop = () => {
    return;
  };

  render() {
    const mediaTypes = this.props.mediaTypes
      ? this.props.mediaTypes
      : ALLOWED_DOCUMENT_MIMETYPES;

    return (
      <FileDrop
        accept={mediaTypes}
        setNotification={this.props.setNotification}
        handleFileUpload={this.props.handleFileUpload}
      ></FileDrop>
    );
  }
}

export default Upload;
