import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { userMiddleWare } from './users/user.middleware';
import { channelMiddleWare } from './channels/channel.middleware';
import { tagMiddleWare } from './tags/tag.middleware';
import { uiMiddleWare } from './shared/middleware/app/ui.middleware';
import { sessionMiddleWare } from './sessions/session.middleware';
import { beaconMiddleWare } from './beacons/beacon.middleware';
import { analyticMiddleWare } from './analytic/analytic.middleware';
import { normalizeMiddleWare } from './shared/middleware/core/normalize.middleware';
import { apiMiddleWare } from './shared/middleware/core/api.middleware';
import { mediaMiddleWare } from './media/media.middleware';
import { stripeMiddleWare } from './stripe/stripe.middleware';
import { productsMiddleWare } from './products/products.middleware';
import { helpMiddleWare } from './help/help.middleware';
import { connectorMiddleWare } from './connectors/connector.middleware';
import { userConnectorMiddleWare } from './userconnectors/userconnector.middleware';
import { subscriberMiddleWare } from './subscribers/subscriber.middleware';

import { helpReducer } from './help/help.reducer';
import { userReducer } from './users/user.reducer';
import { snackbarReducer } from 'react-redux-snackbar';
import { channelReducer } from './channels/channel.reducer';
import { beaconReducer } from './beacons/beacon.reducer';
import { sessionReducer } from './sessions/session.reducer';
import { analyticReducer } from './analytic/analytic.reducer';
import { tagsReducer } from './tags/tag.reducer';
import { mediaReducer } from './media/media.reducer';
import { stripeReducer } from './stripe/stripe.reducer';
import { productsReducer } from './products/products.reducer';
import { connectorReducer } from './connectors/connector.reducer';
import { userConnectorReducer } from './userconnectors/userconnector.reducer';
import { subscriberReducer } from './subscribers/subscriber.reducer';

import { uiReducer } from './shared/reducers/ui.reducer';
import { notificationsReducer } from './shared/reducers/notification.reducer';
import { composeWithDevTools } from '@redux-devtools/extension';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';

export const history = createBrowserHistory();

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    user: userReducer,
    ui: uiReducer,
    notification: notificationsReducer,
    channel: channelReducer,
    tag: tagsReducer,
    session: sessionReducer,
    beacon: beaconReducer,
    analytic: analyticReducer,
    snackbar: snackbarReducer,
    media: mediaReducer,
    stripe: stripeReducer,
    products: productsReducer,
    help: helpReducer,
    connector: connectorReducer,
    userConnector: userConnectorReducer,
    subscriber: subscriberReducer,
  });

const featureMiddleware = [
  userMiddleWare,
  tagMiddleWare,
  channelMiddleWare,
  beaconMiddleWare,
  analyticMiddleWare,
  mediaMiddleWare,
  sessionMiddleWare,
  stripeMiddleWare,
  productsMiddleWare,
  helpMiddleWare,
  connectorMiddleWare,
  userConnectorMiddleWare,
  subscriberMiddleWare,
];

const coreMiddleware = [apiMiddleWare, normalizeMiddleWare, uiMiddleWare];

//added for logs
const composeEnhancers = composeWithDevTools({
  // Specify custom devTools options
});

const enhancer = compose(
  applyMiddleware(
    routerMiddleware(history),
    ...featureMiddleware,
    ...coreMiddleware
  )
);

export const store = createStore(
  rootReducer(history),
  {},
  composeEnhancers(enhancer)
);
