import {
  SESSIONS,
  DELETE_SESSION,
  CREATE_SESSION,
  setSessions,
  deleteSession,
  createSession,
} from './session.actions';
import { setNotification } from '../shared/actions/notification.actions';
import { setLoader } from '../shared/actions/ui.actions';
import {
  API_ERROR,
  API_SUCCESS,
  apiRequest,
} from '../shared/actions/api.actions';
import { showSnack } from 'react-redux-snackbar';
import { Parse } from 'parse';

//import {reject} from 'ramda'

export const sessionMiddleWare = () => (next) => (action) => {
  next(action);

  switch (action.type) {
    case DELETE_SESSION:
      next(deleteSession({ index: action.payload.index }));
      next(
        apiRequest({
          feature: SESSIONS,
          params: action.payload,
          func: 'deleteSession',
        })
      );
      break;

    case CREATE_SESSION:
      next(createSession(action.payload));
      next(
        apiRequest({
          body: action.data,
          feature: SESSIONS,
          params: action.payload,
        })
      );
      break;

    case `${SESSIONS} ${API_SUCCESS}`:
      if (action.actionType === 'deleteSession') {
        next(setSessions(action.payload));
        next(setLoader({ state: false, feature: SESSIONS }));
        window.location.assign('/');
      } else {
        next(setSessions(action.payload));
        next(setLoader({ state: false, feature: SESSIONS }));
        window.dataLayer.push({
          event: 'event',
          eventProps: {
            value: Parse.User.current().toJSON(),
          },
        });
        window.location.assign('/dashboard');
        //next(push('/channels'));
      }
      break;

    case `${SESSIONS} ${API_ERROR}`:
      next(
        setNotification({ message: action.payload.message, feature: SESSIONS })
      );
      next(
        showSnack(SESSIONS, { label: action.payload.message, timeout: 3000 })
      );
      next(setLoader({ state: false, feature: SESSIONS }));
      break;

    default:
      break;
  }
};
