import React, { Component } from 'react';

class Edit extends Component {
  componentDidMount() {}

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        bootsrap html here for editing tag view
      </div>
    );
  }
}

export default Edit;
