import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  Link,
  Divider,
  Button,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import '@fontsource/heebo';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTags, updateTag } from './tag.actions';
import { EditorComponent } from '../ui/lifetaggerTinyMceEditor.component';

import { AccessTimeFilled, LocationOn, PermMedia } from '@mui/icons-material';
import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
import SensorsIcon from '@mui/icons-material/Sensors';
import { triggerItems } from '../ui/triggerItems';
import TriggerButtonComponent from '../ui/triggerButton.component';
import { fetchBeacons } from '../beacons/beacon.actions';
import { fetchMedia } from '../media/media.actions';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
//import {detectIframeAbility, htmlRegEx} from "../utilities";
import { Parse } from 'parse';
import { Get as Show } from './tag.container';
import IPhoneComponent from '../ui/iPhone.component';

function Edit(props) {
  const { history } = props;
  const dispatch = useDispatch();
  let tags = useSelector((state) => state.tag.data);
  let beacons = useSelector((state) => state.beacon.data);
  let media = useSelector((state) => state.media.data);
  const tagId =
    window.location.href.split('/')[window.location.href.split('/').length - 1];
  const [updatedTag, setUpdatedTag] = React.useState(null);
  const [selected, setSelected] = React.useState(null);
  const [saveChangesDisabled, setSaveChangesDisabled] = React.useState(true);
  const [preview, setPreview] = React.useState(false);
  const triggerFields = ['location', 'beaconId', 'startDate', 'acrId'];

  //const [message, setMessage] = React.useState("");

  const getBeaconData = () => {
    const headerCells = [
      {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Name',
      },
      {
        id: 'description',
        numeric: false,
        disablePadding: true,
        label: 'Description',
      },
    ];
    const selected = [];
    for (let i = 0; i < updatedTag?.beaconId?.length; i++) {
      const beaconId = updatedTag.beaconId[i];
      const foundBeacon = beacons.find((b) => b.beaconId == beaconId);
      if (foundBeacon) {
        selected.push(foundBeacon.objectId);
      }
    }
    const data = {
      rows: beacons,
      headCells: headerCells,
      selected: selected,
      orderBy: 'name',
    };
    return data;
  };

  const getMediaData = () => {
    const headerCells = [
      {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Name',
      },
      {
        id: 'description',
        numeric: false,
        disablePadding: true,
        label: 'Description',
      },
    ];
    const selected = [];

    const foundMedia = media.find((m) => m.acrId == updatedTag.acrId);
    if (foundMedia) {
      foundMedia.objectId = foundMedia.id;
      selected.push(foundMedia.id);
    }
    //}

    //}
    const data = {
      rows: media,
      headCells: headerCells,
      selected: selected,
      orderBy: 'name',
    };
    return data;
  };

  useEffect(() => {
    if (selected) {
      triggerIcons(selected?.triggerField);
    }

    for (let i = 0; i < triggerFields.length; i++) {
      const trigger = triggerFields[i];
      triggerIcons(trigger);
    }
  }, [updatedTag]);

  const triggerIcons = (field) => {
    if (updatedTag) {
      const trigger = triggerItems[field];
      const triggerIconStyle = {
        height: '23px',
        color: selected == trigger ? '#BF2420' : '#101828',
      };
      let isActive = false;
      if (updatedTag[field] && !updatedTag[field]?.length) {
        isActive = true;
      }

      if (updatedTag[field] && updatedTag[field]?.length) {
        if (updatedTag[field]?.length > 0) {
          isActive = true;
        }
      }

      trigger.active = isActive;
      trigger.triggerField = field;
      switch (field) {
        case 'location':
          trigger.icon = <LocationOn sx={triggerIconStyle}></LocationOn>;
          //setData(updatedTag);
          trigger.data = updatedTag;
          trigger.callback = locationCallBack;
          return trigger;

        case 'beaconId':
          trigger.icon = <SensorsIcon sx={triggerIconStyle} />;
          //getBeaconData();
          trigger.data = getBeaconData();
          trigger.callback = beaconCallBack;
          return trigger;
        case 'startDate':
          trigger.icon = <AccessTimeFilled sx={triggerIconStyle} />;
          //setData(updatedTag);
          trigger.data = updatedTag;
          trigger.callback = dateCallBack;
          return trigger;

        case 'acrId':
          trigger.icon = <PermMedia sx={triggerIconStyle} />;
          //getMediaData();
          trigger.data = getMediaData();
          trigger.callback = acrCallBack;
          return trigger;
        case 'lifeTapId':
          trigger.icon = <TapAndPlayIcon sx={triggerIconStyle} />;
          trigger.data = updatedTag.lifeTapId;
          trigger.callback = lifeTapCallBack;
          return trigger;
        default:
          return null;
      }
    }
    return null;
  };

  const theme = useTheme();

  const locationCallBack = (event) => {
    const { remove } = event;
    const newTag = Object.assign({}, updatedTag);

    if (remove) {
      delete newTag.location;
      delete newTag.address;
      setSelected(null);
    } else {
      newTag.location = newTag.location || {};
      let point = new Parse.GeoPoint({
        latitude: parseFloat(event.latitude),
        longitude: parseFloat(event.longitude),
      });
      newTag.location = point;
      newTag.address = event.address;
    }
    setSaveChangesDisabled(false);
    setUpdatedTag(newTag);
  };

  const beaconCallBack = (event) => {
    const { remove } = event;
    const newTag = Object.assign({}, updatedTag);

    if (remove || event.length === 0) {
      delete newTag.beaconId;
      setSelected(null);
    } else {
      newTag.beaconId = [];
      for (let i = 0; i < event.length; i++) {
        const beacon = event[i];

        const foundBeacon = beacons.find((b) => b.objectId == beacon);
        newTag.beaconId.push(foundBeacon.beaconId);
      }
    }

    setSaveChangesDisabled(false);
    setUpdatedTag(newTag);
  };

  const acrCallBack = (event) => {
    const { remove } = event;
    const newTag = Object.assign({}, updatedTag);

    if (remove || event.length === 0) {
      delete newTag.acrId;
      setSelected(null);
    } else {
      //When we turn acrIds to an array use this.
      // newTag.acrId = [];
      // for (let i = 0; i < event.length; i++) {
      // 	const acrId = event[i];
      // 	newTag.acrId.push(acrId);
      // }
      const foundMedia = media.find((m) => m.objectId == event[0]);
      newTag.acrId = foundMedia.acrId;
    }

    setSaveChangesDisabled(false);
    setUpdatedTag(newTag);
  };

  const lifeTapCallBack = (event) => {
    const { remove } = event;
    const newTag = Object.assign({}, updatedTag);

    if (remove) {
      delete newTag.lifeTapId;
      setSelected(null);
    } else {
      newTag.lifeTapId = [];
      newTag.lifeTapId.push(...event.lifeTapId);
    }

    setSaveChangesDisabled(false);
    setUpdatedTag(newTag);
  };

  const saveChanges = () => {
    const newTag = Object.assign({}, updatedTag);
    // console.log('newTag html when making changes ', newTag.html);
    newTag.id = updatedTag.objectId;
    // setUpdatedTag(newTag);
    //updatedTag.id = updatedTag.objectId;
    dispatch(updateTag({ query: newTag }));
  };

  const dateCallBack = (event, type) => {
    const { remove } = event;
    const newTag = Object.assign({}, updatedTag);

    if (remove) {
      delete newTag.startDate;
      delete newTag.endDate;
      delete newTag.expirationString;
      setSelected(null);
    } else {
      if (type === 'start') {
        newTag.startDate = event;
        newTag.expirationString = `${event.toLocaleString() || ''} - ''}`;
      }
      if (type === 'end') {
        newTag.endDate = event;
        newTag.expirationString = ` '' - ${event.toLocaleString() || ''}`;
      }
    }

    setSaveChangesDisabled(false);
    setUpdatedTag(newTag);
  };

  useEffect(() => {
    setUpdatedTag(tags[0]);
  }, [tags]);

  useEffect(() => {
    dispatch(fetchTags({ id: tagId }, 'getTags'));
    dispatch(fetchBeacons());
    dispatch(fetchMedia());
  }, [dispatch]);

  const onTriggerClick = (trigger) => {
    setSelected(trigger);
  };
  const setMessageText = async (event) => {
    const newTag = Object.assign({}, updatedTag);
    newTag.message = event.text;
    // Create temp div
    let temp = document.createElement('div');

    temp.innerHTML = event.html;

    // Get content container div from temp
    let contentContainer = temp.querySelector('.content-container');
    let hasColorContainer = temp.querySelector('.color-container');

    if (hasColorContainer && !contentContainer) {
      event.html = `<div class="color-container" style="background-color: ${hasColorContainer.style.backgroundColor};"><div class="content-container">${event.html}</div></div>`;
    }

    if (!contentContainer && !hasColorContainer) {
      event.html = `<div class="content-container">${event.html}</div>`;
    }

    // if (contentContainer && !hasColorContainer) {
    //   event.html = `<div class="content-container" style="padding-top: 10px; height: 100%;">${contentContainer.innerHTML}</div>${event.html}`;
    // }

    newTag.html = event.html;
    console.log('newTag html ', newTag.html);
    setUpdatedTag(newTag);
    setSaveChangesDisabled(false);
  };

  const onShowHeaderClick = () => {
    setSaveChangesDisabled(false);
    const newTag = Object.assign({}, updatedTag);
    newTag.showHeader = !updatedTag.showHeader;
    setUpdatedTag(newTag);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Link
          sx={theme.custom.typography.descriptionTitle}
          onClick={() => history.goBack()}
        >
          <ArrowBackIcon />
          Back
        </Link>
      </Grid>
      <Grid item xs={3}>
        <Typography sx={theme.custom.typography.title}>Edit LifeTag</Typography>
      </Grid>
      <Grid item xs={3} alignSelf={'end'}>
        {updatedTag && (
          <Button
            variant={'contained'}
            sx={theme.custom.ltButton}
            disabled={saveChangesDisabled}
            onClick={() => {
              saveChanges();
            }}
          >
            {' '}
            Save changes
          </Button>
        )}
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch checked={preview} onChange={() => setPreview(!preview)} />
          }
          label="Preview"
        />
      </Grid>
      <Grid container direction={'row'} spacing={3} item xs={12}>
        {preview && (
          <Grid item spacing={3} lg={3.5} md={12} xs={12}>
            <Grid item xs={4}>
              <Typography
                sx={{ ...theme.custom.typography.h6, marginBottom: '12px' }}
              >
                LifeTag Preview
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {updatedTag && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={updatedTag?.showHeader || false}
                      onChange={onShowHeaderClick}
                    />
                  }
                  label="Show header?"
                />
              )}
            </Grid>

            <Grid
              item
              container
              direction={'column'}
              justifyContent="space-between"
              spacing={2}
              xs={6}
            >
              <Grid item xs={10}>
                {updatedTag && (
                  <IPhoneComponent
                    screen={
                      <Grid container>
                        <Grid item xs={12}>
                          <Show
                            tag={updatedTag}
                            style={{ width: '100%', height: '100%' }}
                            useMarkup={true}
                            useHtml={true}
                            mediaHeight="100%"
                            showHeader={updatedTag?.showHeader || false}
                          />
                        </Grid>
                      </Grid>
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
        {preview && (
          <Grid item xs={0.5}>
            <Divider orientation="vertical" />
          </Grid>
        )}
        <Grid item spacing={3} lg={preview ? 3.5 : 7} md={12} xs={12}>
          <Grid item xs={12}>
            <Typography
              sx={{ ...theme.custom.typography.h6, marginBottom: '12px' }}
            >
              LifeTag
            </Typography>
          </Grid>
          <Grid container item>
            <Grid container item xs={9} spacing={2}>
              {triggerFields.map((trigger, index) => {
                const trig = triggerIcons(trigger);
                return (
                  <Grid item xs={3} key={index}>
                    <TriggerButtonComponent
                      trigger={trig}
                      selected={selected}
                      onTriggerClick={onTriggerClick}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <EditorComponent
              defaultValue={updatedTag?.html || updatedTag?.message || ''}
              setMessageText={setMessageText}
              // initialValue={defaultValue}
            ></EditorComponent>
          </Grid>
        </Grid>
        <Grid item xs={0.5}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item spacing={3} lg={3} xs={12}>
          {selected && (
            <div>
              <Grid container direction={'row'}>
                <Grid item xs={6}>
                  <TriggerButtonComponent
                    trigger={selected}
                    selected={selected}
                  />
                </Grid>
                {selected.active && (
                  <Grid item xs={6}>
                    <Button
                      onClick={() => {
                        selected.callback({ remove: true });
                      }}
                      startIcon={<HighlightOffIcon />}
                    >
                      {' '}
                      Remove{' '}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </div>
          )}
          {selected && (
            <Grid item xs={12}>
              <Typography sx={theme.custom.typography.h6}>
                {selected.subHeader}{' '}
              </Typography>
            </Grid>
          )}
          {selected && (
            <div>
              <selected.component
                data={selected.data}
                callback={selected.callback}
              />
            </div>
          )}
          {selected && (
            <div>
              <Grid item xs={12}>
                <Divider orientation="horizontal" />
              </Grid>
            </div>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Edit;
