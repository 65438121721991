import React from 'react';
import PeopleIcon from '@material-ui/icons/People';
import StarIcon from '@material-ui/icons/Star';
import ReorderIcon from '@material-ui/icons/Reorder';
import MovieFilter from '@material-ui/icons/MovieFilter';
import Dialpad from '@material-ui/icons/Dialpad';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import { HomeOutlined, LiveHelpOutlined } from '@material-ui/icons';
//import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

export const items = [
  { href: '/dashboard', icon: <HomeOutlined />, text: 'Home' },
  { href: '/qrcode', icon: <Dialpad />, text: 'LifeCodes' },
  { href: '/tags', icon: <ReorderIcon />, text: 'Tags' },
  { href: '/tags/create', icon: <StarIcon />, text: 'Create Tags' },
  { href: '/media', icon: <MovieFilter />, text: 'Media' },
  { href: '/beacons', icon: <BluetoothIcon />, text: 'Beacons' },
  //{ href: "/analytics", icon: <BarChartIcon />, text: "Analytics" },
  //{ href: "/channels", icon: <DeviceHubIcon />, text: "Channel Settings" },
  { href: '/help', icon: <LiveHelpOutlined />, text: 'Help Center' },
  { href: '/user/edit', icon: <PeopleIcon />, text: 'Account Settings' },
  // { href: '/integrations', icon: <ChevronLeftIcon />, text: 'Integrations' },
];
