export const CHANNELS = '[CHANNELS]';

// action types
export const FETCH_CHANNELS = `${CHANNELS} FETCH`;
export const FETCH_USER_CHANNELS = `${CHANNELS} FETCH_USER_CHANNELS`;
export const DELETE_CHANNEL = `${CHANNELS} DELETE`;
export const SET_CHANNELS = `${CHANNELS} SET`;
export const SET_USER_CHANNEL = `${CHANNELS} SET_USER_CHANNEL`;
export const SET_USER_CHANNELS = `${CHANNELS} SET_USER_CHANNELS`;
export const CREATE_CHANNEL = `${CHANNELS} CREATE`;
export const UPDATE_CHANNEL = `${CHANNELS} UPDATE`;
export const SHOW_CHANNEL = `${CHANNELS} SHOW`;

export const userChannels = () => ({
  type: FETCH_USER_CHANNELS,
});

export const fetchChannels = ({ query }) => ({
  type: FETCH_CHANNELS,
  payload: query,
  //function : 'getMyChannels'
  //function: 'getChannel'
});

export const deleteChannel = ({ query }) => ({
  type: DELETE_CHANNEL,
  payload: query,
  function: 'deleteChannel',
});

export const createChannel = ({ query }) => ({
  type: CREATE_CHANNEL,
  payload: query,
  function: 'createChannel',
});

export const updateChannel = ({ query }) => ({
  type: UPDATE_CHANNEL,
  index: query.index,
  payload: query.data,
});

export const showChannel = ({ query }) => ({
  type: SHOW_CHANNEL,
  index: query.index,
  payload: query.data,
});

export const setChannels = (channels) => ({
  type: SET_CHANNELS,
  payload: channels,
});

export const setUserChannels = (channels) => ({
  type: SET_USER_CHANNELS,
  payload: channels,
});

export const setUserChannel = (channel) => ({
  type: SET_USER_CHANNEL,
  payload: channel,
});
