// feature name
export const USER = '[USER]';
export const GROUP_USERS = '[GROUP_USERS]';

// action types
export const FETCH_USER = `${USER} FETCH_USER`;
export const FETCH_GROUP_USERS = `${USER} FETCH_GROUP_USERS`;
export const SET_USER = `${USER} SET_USER`;
export const SET_GROUP_USERS = `${USER} SET_GROUP_USERS`;
export const UPDATE_USER = `${USER} UPDATE_USER`;
export const LOGOUT_USER = `${USER} LOGOUT_USER`;
export const CREATE_USER = `${USER} CREATE_USER`;

export const setUser = ({ user }) => ({
  type: SET_USER,
  payload: user,
});

export const setGroupUsers = ({ groupUsers }) => ({
  type: SET_GROUP_USERS,
  payload: groupUsers,
});

export const fetchUser = ({ query }) => ({
  type: FETCH_USER,
  payload: query,
});

export const fetchGroupUsers = ({ query }) => ({
  type: FETCH_GROUP_USERS,
  payload: query,
});

export const updateUser = ({ query }) => ({
  type: UPDATE_USER,
  payload: query,
});

export const logout = ({ user }) => ({
  type: LOGOUT_USER,
  payload: user,
});

export const createUser = ({ user }) => ({
  type: CREATE_USER,
  payload: user,
  function: 'createUser',
});
