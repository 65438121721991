import React from 'react';
import SearchMap from './maps.component';
import { Grid } from '@material-ui/core';

function SearchMapComponent(props) {
  const { latitude, longitude } = props.data?.location || {
    latitude: 0,
    longitude: 0,
  };
  const callback = props.callback;
  const blockQuoteStyle = {
    height: '350px',
    width: '100%',
    fontSize: '16px',
    textAlign: 'center',
    fontFamily: 'helvetica',
    marginTop: '67px',
    marginBottom: '25px',

    background:
      'linear-gradient(to right, #039be5 4px, transparent 4px) 0 0%,' +
      'linear-gradient(to bottom, #039be5 4px, transparent 4px) 0% 0,' +
      'linear-gradient(to right, #039be5 4px, transparent 4px) 0 100%,' +
      'linear-gradient(to left, #039be5 4px, transparent 4px) 100% 0,' +
      'linear-gradient(to left, #039be5 4px, transparent 4px) 100% 100%,' +
      'linear-gradient(to bottom, #039be5 4px, transparent 4px) 100% 0,' +
      'linear-gradient(to top, #039be5 4px, transparent 4px) 100% 100%,' +
      'linear-gradient(to top, #039be5 4px, transparent 4px) 0 100%',
    margin: '1.5em 10px',
    padding: '0.5em 15px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '20px 20px',
    '&:p': {
      display: 'inline',
    },
  };
  return (
    <Grid item xs="auto">
      <SearchMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places `}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={blockQuoteStyle} />}
        mapElement={<div style={{ height: `100%`, borderRadius: '7px' }} />}
        callback={props.setAddressText || callback}
        latitude={latitude || 0}
        longitude={longitude || 0}
      />
    </Grid>
  );
}

export default SearchMapComponent;
