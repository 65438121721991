import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class MultipleSelect extends Component {
  constructor(props) {
    super(props);
    this.state = { selection: this.props.selectedItems };
  }

  handleChange = (event) => {
    this.setState({ selection: event.target.value });
    this.props.handleChange(event);
  };

  handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({ selection: value });
  };

  setSelected = (selectedItem) => {
    if (selectedItem && selectedItem.length != 0) {
      this.setState({ selection: selectedItem });
    }
  };
  render() {
    const {
      selectionArray,
      title,
      selectionKey,
      classes,
      multiple,
      weight,
      color,
    } = this.props;
    return (
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="select-multiple-chip">
            <Box fontWeight={weight} m={1} fontFamily="Roboto" color={color}>
              {title}
            </Box>
          </InputLabel>
          <Select
            style={{ margin: '6px', padding: '15px 0px' }}
            multiple={multiple}
            fullWidth
            value={this.state.selection}
            onChange={this.handleChange}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip key={value} label={value} className={classes.chip} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {selectionArray.map((selected) => (
              <MenuItem
                key={selected[selectionKey]}
                value={selected[selectionKey]}
              >
                {selected[selectionKey]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
}

export default withStyles(styles)(MultipleSelect);
