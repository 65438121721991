import { connect } from 'react-redux';
import {
  fetchMedia,
  deleteMedia,
  createMedia,
  updateMedia,
  showMedia,
} from './media.actions';
import Index from './media.index';
import New from './media.new';
import Edit from './media.edit';
import Show from './media.show';
import Remove from './media.remove';
//import DeleteMedia,rom './deleteMedia,omponent';

const mapStateToProps = (state) => ({
  medias: state.media.data,
  location: state.history,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  all: () => dispatch(fetchMedia()),
  remove: (query) => {
    query.index = ownProps.index;
    dispatch(deleteMedia({ query }));
  },
  create: (query) => {
    dispatch(createMedia({ query }));
  },

  update: (query) => dispatch(updateMedia({ query })),

  show: (query) => dispatch(showMedia({ query })),
});

const mediaConnector = connect(mapStateToProps, mapDispatchToProps);

export const All = mediaConnector(Index);
export const Create = mediaConnector(New);
export const Update = mediaConnector(Edit);
export const Get = mediaConnector(Show);
export const Delete = mediaConnector(Remove);
