export const CONNECTOR = '[CONNECTOR]';

// action types
export const FETCH_CONNECTOR = `${CONNECTOR} FETCH`;
export const SET_CONNECTOR = `${CONNECTOR} SET`;
export const SET_CONNECTOR_ACTIONS = `${CONNECTOR} SET ACTIONS`;
export const FETCH_CONNECTORS = `${CONNECTOR} FETCH ALL`;
export const FETCH_CONNECTORS_ACTIONS = `${CONNECTOR} FETCH ACTIONS`;

//The function attribute is used as the actionType in the api middleware.  The object specific middlware will use it after api success to see what action was called
export const fetchConnectors = () => ({
  type: FETCH_CONNECTORS,
  method: 'GET',
  api: 'kinects',
  endPoint: 'connector',
  function: 'getConnectors',
});

export const fetchConnector = (id) => ({
  type: FETCH_CONNECTOR,
  method: 'GET',
  api: 'kinects',
  endPoint: `${id}`,
  function: 'getConnector',
});

export const fetchConnectorAction = ({ path, params, method, body }) => ({
  type: FETCH_CONNECTORS_ACTIONS,
  method: method,
  api: 'kinects',
  endPoint: params ? `${path}?${params}` : `${path}`,
  function: 'getConnectorActions',
  body: body,
});

export const setConnector = ({ connectors }) => ({
  type: SET_CONNECTOR,
  payload: connectors,
});

export const setConnectorActions = ({ connectorActions }) => ({
  type: SET_CONNECTOR_ACTIONS,
  payload: connectorActions,
});
