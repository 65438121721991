import {
  FETCH_IMPRESSION,
  SET_IMPRESSION,
  FETCH_ANALYTIC,
  SET_ANALYTIC,
} from './analytic.actions';

const initState = { data: [] };
export const analyticReducer = (analytic = initState, action) => {
  let newAnalytic = analytic.data.slice();
  switch (action.type) {
    case SET_ANALYTIC:
      return {
        ...analytic,
        data: action.payload,
      };
    case FETCH_ANALYTIC:
      //newAnalytic.splice(action.index, 1)
      return {
        ...analytic,
        data: newAnalytic,
      };
    case SET_IMPRESSION:
      return {
        ...analytic,
        impressionData: action.payload,
      };
    case FETCH_IMPRESSION:
      //newAnalytic.splice(action.index, 1)
      return {
        ...analytic,
        impressionData: newAnalytic,
      };

    default:
      return analytic;
  }
};
