import * as R from 'ramda';

const initState = { data: [] };

const remove = (beacons, beacon) => {
  const filtered = beacons.filter((x) => x.objectId !== beacon.objectId);
  return [...filtered];
};

const upsert = (beacons, beacon) => {
  return [...remove(beacons, beacon), beacon];
};

const update = (beacons, beacon) => {
  const index = R.findIndex(R.propEq('objectId', beacon.objectId))(beacons);
  beacons.splice(index, 1, beacon);

  return [...beacons];
};

export const beaconReducer = (beacon = initState, action) => {
  let oldBeacons = beacon.data;
  switch (action.actionType) {
    case 'getBeacons':
      return {
        ...beacon,
        data: action.payload.reverse(),
      };

    case 'getDashboardBlueToothBeacons':
      return {
        ...beacon,
        data: action.payload,
      };

    case 'deleteBeacons':
      return {
        ...beacon,
        data: remove(oldBeacons, action.payload),
      };

    case 'createBeacon':
      return {
        ...beacon,
        data: upsert(oldBeacons, action.payload),
      };

    case 'getLifeCodes':
      return {
        ...beacon,
        data: action.payload.reverse(),
      };

    case 'updateBeacon':
      return {
        ...beacon,
        data: update(oldBeacons, action.payload),
      };

    default:
      return beacon;
  }
};
