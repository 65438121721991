import {
  TAGS,
  FETCH_TAGS,
  DELETE_TAG,
  CREATE_TAG,
  UPDATE_TAG,
  updateTag,
  setTags,
  deleteTag,
  createTag,
} from './tag.actions';
import { setNotification } from '../shared/actions/notification.actions';
import { NORMALIZE_COMPLETE } from '../shared/actions/normalize.actions';
import { setLoader } from '../shared/actions/ui.actions';
import {
  API_ERROR,
  API_SUCCESS,
  apiRequest,
} from '../shared/actions/api.actions';
import { showSnack } from 'react-redux-snackbar';

export const tagMiddleWare = () => (next) => (action) => {
  next(action);

  switch (action.type) {
    case FETCH_TAGS:
      next(
        apiRequest({
          feature: TAGS,
          params: action.payload,
          func: action.function,
        })
      );
      next(setLoader({ state: true, feature: TAGS }));
      break;

    case DELETE_TAG:
      next(
        apiRequest({ feature: TAGS, params: action.payload, func: 'deleteTag' })
      );
      break;

    case CREATE_TAG:
      //next(normalizeAddress({ payload: action.payload, feature: TAGS }));//send action payload and the feature to the function to return the lat long
      next(
        apiRequest({
          body: action.data,
          feature: TAGS,
          params: action.payload,
          func: 'createTag',
        })
      );
      next(setLoader({ state: true, feature: TAGS }));
      break;

    case UPDATE_TAG:
      next(updateTag({ index: action.index }));
      next(
        apiRequest({
          body: action.data,
          feature: TAGS,
          params: action.payload,
          func: action.function,
        })
      );
      break;

    case `${TAGS} ${API_SUCCESS}`:
      if (action.actionType === 'createTag') {
        next(createTag({ tags: action.payload, feature: TAGS }));
        next(setLoader({ state: false, feature: TAGS }));
        next(setNotification({ message: 'Tag Created', feature: TAGS }));
        next(showSnack(TAGS, { label: 'Tag Created', timeout: 3000 }));

        window.location.assign('/tags/create');
      } else if (action.actionType === 'deleteTag') {
        next(deleteTag({ tags: action.payload, feature: TAGS }));
        next(setNotification({ message: 'Tag Deleted', feature: TAGS }));
        next(showSnack(TAGS, { label: 'Tag Deleted', timeout: 3000 }));
      } else if (action.actionType === 'updateMyTag') {
        next(setTags({ tags: action.payload, feature: TAGS }));
        next(setNotification({ message: 'Tag Updated', feature: TAGS }));
        next(showSnack(TAGS, { label: 'Tag Updated', timeout: 3000 }));
        //window.location.assign("/tags");
      } else {
        next(setTags({ tags: action.payload, feature: TAGS }));
        next(setLoader({ state: false, feature: TAGS }));
      }

      break;

    case `${TAGS} ${API_ERROR}`:
      //console.log("error message", action.payload);
      next(setNotification({ message: action.payload.message, feature: TAGS }));
      next(setLoader({ state: false, feature: TAGS }));
      break;

    case `${TAGS} ${NORMALIZE_COMPLETE}`:
      ////console.log("is normalized complete:", action.payload);
      //go to the dashboard
      //if (action.actionType == "createTag") {
      //  next(apiRequest({ body:action.data , feature: TAGS, params: action.payload, func: "createTag" }));
      //}
      break;

    default:
      break;
  }
};
