import React, { Component } from 'react';
import './session.css';

window.Intercom('boot', {
  app_id: 'wn943hcg',
});

class New extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.create(this.state);
  };

  handleChange = (event) => {
    event.preventDefault();
    const key = event.target.id;
    const value = event.target.value;
    this.setState({ [key]: value });
  };

  render() {
    return (
      <div className="col-md-4 col-md-offset-4 loginView">
        <form onSubmit={this.handleSubmit}>
          <div className="logImgContainer">
            <img
              alt="LifeTaggerLogo"
              src="/images/dashboardlogo.png"
              className="App-logo"
            />
            &nbsp;&nbsp;LifeTagger
          </div>
          <div className="form-group">
            <label className="labelText">Username</label>
            <input
              type="text"
              className="form-control"
              id="email"
              aria-describedby="emailHelp"
              placeholder="Username"
              onChange={this.handleChange}
            />
          </div>
          <div className="form-group">
            <label className="labelText">Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Password"
              onChange={this.handleChange}
            />
          </div>
          <button type="submit" className="btn btn-primary logButton">
            Login
          </button>
          <div className="form-check loginCheckbox">
            <input
              type="checkbox"
              className="form-check-input loginCheckbox"
              id="chkRememberMe"
            />
            <label>&nbsp;Remember me</label>
          </div>
          <div>
            <a href="/resetpassword" className="loginHref">
              Forgot Password?
            </a>
          </div>
          <div>
            <a href="register" className="loginHref">
              Not a user? Sign up here
            </a>
          </div>
        </form>
      </div>
    );
  }
}

export default New;
