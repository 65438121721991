import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography, TextField } from '@material-ui/core';
import { Parse } from 'parse';

const styles = {
  background: {
    fontFamily: 'sans-serif',
    backgroundColor: '#282c34',
    color: '#fff',
    fontSize: '1.4em',
    padding: '50px',
    width: '50%',
    borderRadius: '0%',
    margin: 'auto',
  },
  labelText: {
    width: '100%',
    padding: '2px 5px',
    display: 'flex',
    fontSize: '14pt',
    fontWeight: 'bold',
    color: '#FFFFFF',
    boxSizing: 'border-box',
    align: 'left',
  },
  logButton: {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '7px 10px',
    margin: '8px 0',
    border: 'none',
    width: '100%',
    fontSize: '14pt',
    fontWeight: 'bold',
  },
};

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailError: false,
      submitted: false,
    };
  }

  handleChange = (event) => {
    if (event.target.value.includes('@') && event.target.value.includes('.')) {
      this.setState({ email: event.target.value, emailError: false });
    } else {
      this.setState({ email: event.target.value, emailError: true });
    }
  };

  handleClick = () => {
    Parse.User.requestPasswordReset(this.state.email);
    this.setState({ submitted: true });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.background}>
          {!this.state.submitted ? (
            <div>
              <Typography className={classes.labelText}>
                Enter your email address
              </Typography>
              <TextField
                fullWidth
                variant="filled"
                style={{ backgroundColor: '#FFFFFF', borderRadius: 5 }}
                label="Email Address"
                error={this.state.emailError}
                onChange={this.handleChange}
                InputProps={{ className: classes.input }}
              />
              <Button
                className={classes.logButton}
                disabled={this.state.emailError}
                onClick={this.handleClick}
              >
                Reset Password
              </Button>
            </div>
          ) : (
            <Typography className={classes.labelText}>
              If this email address exists in our system, you will receive an
              email with reset instructions
            </Typography>
          )}
          <a href="/" className="loginHref">
            Log in
          </a>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(PasswordReset);
