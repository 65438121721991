import React from 'react';
import { Tooltip, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function Show(props) {
  const { connector } = props;
  const theme = useTheme();

  return (
    <Grid container justifyContent={'center'}>
      <Grid item xs={12}>
        {connector?.svg && (
          <Tooltip title={connector.name}>
            <div>
              <img
                style={theme.custom.connectorSvg}
                src={`data:image/svg+xml;utf8,${encodeURIComponent(
                  connector.svg
                )}`}
              />
            </div>
          </Tooltip>
        )}
      </Grid>

      <Grid item xs={'auto'}>
        <Grid container direction={'column'}>
          <Grid item xs={'auto'}>
            <Typography variant={'caption'}>{connector.name}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Show;
