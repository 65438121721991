import React, { Component } from 'react';
import clsx from 'clsx';
import { Parse } from 'parse';
import {
  Button,
  IconButton,
  Toolbar,
  AppBar,
  Menu,
  MenuItem,
  Icon,
} from '@material-ui/core';
import { Grid } from '@mui/material';

// const openProfileMenu = () => {return true}
// const closeProfileMenu = () => {return false}
// const handleDrawerToggle = () => {return false}

class Header extends Component {
  render() {
    const userName = Parse.User.current().get('name');
    let companyName = Parse.User.current().get('company');
    let profileUrl = Parse.User.current().get('profile_url')
      ? Parse.User.current().get('profile_url')
      : '/images/noProfile.png';
    companyName = companyName ? companyName : userName + "'s Company";
    const {
      classes,
      profileMenuIsOpen,
      handleDrawerToggle,
      closeProfileMenu,
      openProfileMenu,
    } = this.props;

    return (
      <AppBar elevation={0} className={clsx(classes.appBar)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <Icon> menu </Icon>
          </IconButton>

          {/*<Typography className={classes.title} variant="h6" noWrap>*/}
          {/*  {clsx(companyName)}*/}
          {/*</Typography>*/}

          <Grid container justifyContent={'flex-end'}>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={openProfileMenu}
            >
              {clsx(companyName)}
              <Icon>menu</Icon>
              <img
                id="profileMenuIcon"
                align="right"
                src={profileUrl}
                height="25"
                width="25"
                className="ml-2"
              />
            </Button>
            <Menu
              anchorEl={() => document.getElementById('profileMenuIcon')}
              keepMounted
              open={profileMenuIsOpen}
              onClose={closeProfileMenu}
            >
              <MenuItem onClick={() => (window.location.href = '/user/edit')}>
                Account Settings
              </MenuItem>
              <MenuItem
                onClick={() => {
                  this.props.deleteSession();
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}

export default Header;
