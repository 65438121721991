import React from 'react';
import { Slider, Stack } from '@mui/material';
import { LightbulbCircle, LightbulbOutlined } from '@mui/icons-material';

function ControlsComponent() {
  // const handleChange = () => {
  //
  // };
  return (
    <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
      <LightbulbOutlined></LightbulbOutlined>
      <Slider />
      <LightbulbCircle />
    </Stack>
  );
}

export default ControlsComponent;
