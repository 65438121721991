import { SET_PRODUCT } from './products.actions';

const initState = { data: [] };

export const productsReducer = (products = initState, action) => {
  //let oldProducts =products.data;
  switch (action.type) {
    case SET_PRODUCT:
      return {
        ...products,
        data: action.payload.products,
      };
    default:
      return products;
  }
};
