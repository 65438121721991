import {
  SET_CONNECTOR,
  SET_CONNECTOR_ACTIONS,
  FETCH_CONNECTORS,
} from './connector.actions';
import * as R from 'ramda';

const initState = { data: [] };

const updateActions = (connectors, action) => {
  const index = R.findIndex(R.propEq(action.connector, 'id'))(connectors);
  const connector = R.clone(connectors[index]);
  if (connector) {
    connector.actionData = action.results;
    const updatedConnectors = R.update(index, connector, connectors);
    return updatedConnectors;
  }
};

export const connectorReducer = (connector = initState, action) => {
  let oldConnectors = connector.data; //.slice()
  switch (action.type) {
    case SET_CONNECTOR:
      return {
        ...connector,
        data: action.payload,
      };
    case FETCH_CONNECTORS:
      //newConnector.splice(action.index, 1)
      return {
        ...connector,
        data: oldConnectors,
      };

    case SET_CONNECTOR_ACTIONS:
      return {
        ...connector,
        data: updateActions(oldConnectors, action.payload),
      };
    default:
      return connector;
  }
};
