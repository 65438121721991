import { STRIPE, FETCH_STRIPE, setStripe } from './stripe.actions';
import { setNotification } from '../shared/actions/notification.actions';
import { setLoader } from '../shared/actions/ui.actions';
import {
  API_ERROR,
  API_SUCCESS,
  apiRequest,
} from '../shared/actions/api.actions';
//import { showSnack, dismissSnack } from 'react-redux-snackbar';
//import {reject} from 'ramda'

export const stripeMiddleWare = () => (next) => (action) => {
  next(action);

  switch (action.type) {
    case FETCH_STRIPE:
      //console.log('FETCH_STRIPE is the action', action);
      next(
        apiRequest({
          feature: STRIPE,
          params: action.payload,
          func: action.function,
        })
      );
      next(setLoader({ state: true, feature: STRIPE }));
      break;

    case `${STRIPE} ${API_SUCCESS}`:
      next(setStripe({ stripe: action.payload }));
      next(setLoader({ state: false, feature: STRIPE }));

      break;

    case `${STRIPE} ${API_ERROR}`:
      //console.log("error message", action.payload);
      next(
        setNotification({ message: action.payload.message, feature: STRIPE })
      );
      next(setLoader({ state: false, feature: STRIPE }));
      break;
    default:
      break;
  }
};
