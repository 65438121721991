import {
  USER,
  UPDATE_USER,
  updateUser,
  setUser,
  FETCH_USER,
  fetchUser,
  CREATE_USER,
  createUser,
} from './user.actions';
import { setNotification } from '../shared/actions/notification.actions';
import { setLoader } from '../shared/actions/ui.actions';
import {
  API_ERROR,
  API_SUCCESS,
  apiRequest,
} from '../shared/actions/api.actions';
import { showSnack } from 'react-redux-snackbar';
//import {reject} from 'ramda'

export const userMiddleWare = () => (next) => (action) => {
  next(action);
  switch (action.type) {
    case UPDATE_USER:
      next(updateUser({ index: action.index }));
      next(
        apiRequest({ body: action.data, feature: USER, params: action.payload })
      );
      break;
    case FETCH_USER:
      next(fetchUser({ user: action.payload }));
      next(
        apiRequest({
          body: action.payload,
          feature: USER,
          params: action.payload,
        })
      );
      break;
    case CREATE_USER:
      next(createUser({ user: action.payload }));
      next(
        apiRequest({
          body: action.payload,
          feature: USER,
          params: action.payload,
          func: action.function,
        })
      );
      break;
    case `${USER} ${API_SUCCESS}`:
      next(setUser({ user: action.payload.data }));
      next(setLoader({ state: false, feature: USER }));
      break;

    case `${USER} ${API_ERROR}`:
      next(setNotification({ message: action.payload.message, feature: USER }));
      next(showSnack(USER, { label: action.payload.message, timeout: 3000 }));
      next(setLoader({ state: false, feature: USER }));
      break;

    default:
      break;
  }
};
