import { SET_HELP, DELETE_HELP, CREATE_HELP } from './help.actions';
import * as R from 'ramda';

const initState = { data: [] };

const remove = (helps, help) => {
  const index = R.findIndex(R.propEq('objectId', help.objectId))(helps);

  if (index >= 0) {
    return [...helps.slice(0, index), ...helps.slice(index + 1, helps.length)];
  }

  return [...helps];
};

const upsert = (helps, help) => {
  return [...remove(helps, help), help];
};

export const helpReducer = (help = initState, action) => {
  let oldHelp = help.data;
  switch (action.type) {
    case SET_HELP:
      return {
        ...help,
        data: action.payload,
      };

    case DELETE_HELP:
      return {
        ...help,
        data: remove(oldHelp, action.payload),
      };

    case CREATE_HELP:
      return {
        ...help,
        data: upsert(oldHelp, action.payload),
      };

    default:
      return help;
  }
};
