import React, { useEffect } from 'react';
//import {makeStyles} from "@material-ui/core/styles";
//import {Button} from "@material-ui/core";
import { Get as Show } from './connector.container';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConnectors } from '../connectors/connector.actions';
import { fetchUserConnectors } from '../userconnectors/userconnector.actions';

function Index(props) {
  const dispatch = useDispatch();
  let connectors = useSelector((state) => state.connector?.data);
  let userConnectors = useSelector((state) => state.userConnector?.data);

  const handleConnectorClick = (id) => {
    props.getConnector(id);
  };

  useEffect(() => {
    dispatch(fetchConnectors());
    dispatch(fetchUserConnectors());
  }, [dispatch]);
  return (
    <Grid container direction={'row'} spacing={2}>
      {connectors?.map((connector, index) => {
        let t = userConnectors?.find((c) => c.connectorId === connector.id);
        if (
          !props.step ||
          (!t && connector.steps && connector.steps.includes(props.step))
        ) {
          return (
            <Grid
              item
              key={index}
              onClick={() => {
                if (props.callBack) {
                  props.callBack(connector.id);
                } else {
                  handleConnectorClick(connector.id);
                }
              }}
            >
              <Show connector={connector}></Show>
            </Grid>
          );
        }
      })}
    </Grid>
  );
}

export default Index;
