import React, { Component } from 'react';
import './user.css';
import { Parse } from 'parse';
import { CardMedia, Modal, Button, Grid, Typography } from '@mui/material';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';

import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Upload from '../ui/upload.component';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import EditIcon from '@mui/icons-material/Edit';

let theme = createMuiTheme();

const useStyles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto',
    borderRadius: 20,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 20,
  },
  cardBackground: {
    backgroundColor: '#D9E2FF',
    borderRadius: 6,
  },
  resetPassText: {
    fontFamily: 'Heebo',
    fontWeight: 400,
    fontSize: '14px',
    color: '#BF2420',
  },
  editIcon: {
    position: 'absolute',
    background: 'white',
    bottom: 0,
    left: 8,
    opacity: 0.5,
    height: '48px',
    width: '48px',
    borderRadius: '50%',
  },
};

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userAccountPlan: 0,
      accountSavedResponse: '',
      resetPwdDialog: false,
      currentPassword: '',
      newPassword: '',
      verifyPassword: '',
      errorResponse: '',
      profileImageDialog: false,
      image: '',
      fileName: '',
      showEditIcon: false,
    };

    if (Parse.User.current()) {
      this.state = { userAccountPlan: Parse.User.current().get('accountPlan') };
    }
  }

  openPassword() {
    this.setState({ resetPwdDialog: true });
    this.clearResetPassword();
  }

  closePassword() {
    this.setState({ resetPwdDialog: false });
    this.clearResetPassword();
  }

  clearResetPassword() {
    this.setState({ newPassword: '' });
    this.setState({ verifyPassword: '' });
    this.setState({ currentPassword: '' });
    this.setState({ errorResponse: '' });
  }

  showEditIcon = () => {
    this.setState({ showEditIcon: true });
  };

  hideEditIcon = () => {
    this.setState({ showEditIcon: false });
  };

  async ResetPassword() {
    //check to see if new password is equal to ver password
    var newPwd = this.state.newPassword;
    var verifyPwd = this.state.verifyPassword;
    var currentPwd = this.state.currentPassword;
    var currentUsr = Parse.User.current().get('username');
    const minLength = 7;

    if (newPwd == null || newPwd == '') {
      this.setState({ errorResponse: 'New password is empty' });
    } else if (newPwd.length < minLength) {
      this.setState({
        errorResponse: 'New password does not meet requirements',
      });
    } else if (newPwd != verifyPwd) {
      this.setState({ errorResponse: 'Invalid password verification.' });
    } else {
      //the new

      try {
        await Parse.User.logIn(currentUsr, currentPwd);
        //if there is no error then the user and old password have been verified.
        Parse.User.current().setPassword(newPwd);
        Parse.User.current().save();
        this.setState({ errorResponse: 'Password Changed Successfully!' });
      } catch (error) {
        //console.log("error saving new password", error);
        this.setState({
          errorResponse: 'Please enter the correct user password',
        });
      }
    }
  }

  handleResetPasswordInputChanges = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleAccountPlanUpdate = (event) => {
    this.setState({ userAccountPlan: event.target.value });
    this.setState({ accountSavedResponse: '' });
  };

  saveAccountPlan() {
    let userObj = Parse.User.current();
    userObj.set('accountPlan', this.state.userAccountPlan);
    userObj.save();
    this.setState({
      accountSavedResponse: 'Congratulations on updating your plan! ',
    });
  }

  openProfileImageDialog = () => {
    this.setState({ profileImageDialog: true });
  };

  closeProfileImageDialog = () => {
    this.setState({ profileImageDialog: false });
    this.setState({ image: null, fileName: null });
  };

  handleImgUpload = (image) => {
    if (image.length && image.length > 0) {
      this.setState({ image: image });
      let files = '';
      for (let i = 0; i < image.length; i++) {
        files = `${image[i].name},`;
      }
      this.setState({ fileName: files });
    }
  };

  handleSave = async () => {
    if (this.state.image) {
      let user = Parse.User.current();
      let image = new Parse.File(
        'image',
        this.state.image[0],
        this.state.image[0].type
      );
      image.save();
      user.set('profile', image);
      await user.save();
      user.set('profile_url', user.get('profile').url());
      await user.save();
      this.setState({ image: null, fileName: null });
      this.setState({ profileImageDialog: false });
    } else {
      //alert("please pick an image");
    }
  };

  renderProfileModal(classes) {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={this.state.profileImageDialog}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.paper}>
          <Upload handleFileUpload={this.handleImgUpload} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              borderRadius: '10px',
            }}
          >
            {this.state.image &&
              this.state.image.map((file, i) => (
                <CardMedia
                  className={classes.media}
                  src={URL.createObjectURL(file)}
                  key={i}
                  component="img"
                  style={{ height: 100, width: 100 }}
                />
              ))}
          </div>
          <div className="float-right" style={{ margin: '25px 0px 0px' }}>
            <Button onClick={this.closeProfileImageDialog}>Cancel</Button>
            <Button onClick={this.handleSave}>Save</Button>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    const { classes } = this.props;

    //get user information
    let userName, userCompanyName, userSince;
    let userEmail, userPassword;
    let userLogo;

    let parseUser = Parse.User.current();
    if (parseUser) {
      userName = parseUser.get('name');
      userCompanyName = parseUser.get('company')
        ? parseUser.get('company')
        : `${userName}'s Company `;
      userSince = parseUser.get('createdAt').toLocaleDateString('en-US'); //parseUser.get("createdAt").toString();
      userEmail = parseUser.get('username');
      userPassword = '************'; //parseUser.get("password");

      userLogo = parseUser.get('profile_url')
        ? parseUser.get('profile_url')
        : '/images/noProfile.png';
    }

    return (
      <div>
        <Grid container direction={'column'} spacing={4}>
          <Grid item xs>
            <Grid container direction={'row'} spacing={2}>
              <Grid item>
                <SettingsOutlinedIcon htmlColor={'#DD0B06'} />
              </Grid>
              <Grid item>
                <Typography variant={'h5'}>Account Settings</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Grid
              container
              direction={'row'}
              spacing={2}
              className={classes.cardBackground}
              sm={5}
              sx={{ ml: 2 }}
            >
              <Grid
                item
                onMouseEnter={this.showEditIcon}
                onMouseLeave={this.hideEditIcon}
                onClick={this.openProfileImageDialog}
              >
                {this.state.showEditIcon ? (
                  <div style={{ position: 'relative' }}>
                    <img
                      onClick={this.openProfileImageDialog}
                      id="profileMenuIcon"
                      src={userLogo}
                      height="48"
                      width="48"
                      className="ml-2"
                    />
                    <EditIcon className={classes.editIcon} />
                  </div>
                ) : (
                  <img
                    onClick={this.openProfileImageDialog}
                    id="profileMenuIcon"
                    src={userLogo}
                    height="48"
                    width="48"
                    className="ml-2"
                  />
                )}
              </Grid>
              <Grid item>
                <Grid container direction={'column'} spacing={2} sx={{ mb: 2 }}>
                  <Grid item>
                    <Typography className={'h1'}>{userCompanyName}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant={'subtitle1'}>
                      Member Since: {userSince}
                    </Typography>
                    <Typography variant={'subtitle1'}>
                      Username: {userEmail}
                    </Typography>
                    <Typography variant={'subtitle1'}>
                      password: {userPassword}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <a href="#">
                      <Typography
                        variant={'caption'}
                        onClick={() => this.openPassword()}
                      >
                        Reset Password
                      </Typography>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* RESET USE PASSWORD USING DIALOG BOX BELOW */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={this.state.resetPwdDialog}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={this.state.resetPwdDialog}>
              <div className={classes.paper}>
                <div className="d-flex justify-content-center">
                  <div>
                    <img
                      alt="LifeTaggerLogo"
                      src="/images/dashboardlogo.png"
                      className={classes.appLogo}
                    />
                    <Typography
                      variant="h6"
                      align="right"
                      className={classes.logoText}
                    >
                      LifeTagger
                    </Typography>
                  </div>
                </div>
                <div className="form-group" align="center">
                  <label className="labelText">Reset Password</label>
                </div>
                <div className="form-group" align="center">
                  <label>{this.state.errorResponse}</label>
                </div>
                <div className="form-group" align="left">
                  <label className="labelText">Current Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="currentPassword"
                    name="currentPassword"
                    placeholder="enter current password"
                    onChange={this.handleResetPasswordInputChanges.bind(this)}
                  />
                </div>
                <div className="form-group" align="left">
                  <label className="labelText">Enter new password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="newPassword"
                    name="newPassword"
                    placeholder="enter new password"
                    onChange={this.handleResetPasswordInputChanges.bind(this)}
                  />
                </div>
                <div className="form-group" align="left">
                  <label className="labelText">Verify new password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="verifyPassword"
                    name="verifyPassword"
                    placeholder="verify new password"
                    onChange={this.handleResetPasswordInputChanges.bind(this)}
                  />
                </div>
                <div className="form-group" align="right">
                  <Button color="primary" onClick={() => this.ResetPassword()}>
                    Ok
                  </Button>
                  <Button color="primary" onClick={() => this.closePassword()}>
                    Cancel
                  </Button>
                </div>
              </div>
              {/*</Dialog>*/}
            </Fade>
          </Modal>
          {this.renderProfileModal(classes)}
        </Grid>
      </div>
    );
  }
}

export default withStyles(useStyles)(Edit);
