const initState = { data: [] };
const remove = (tags, tag) => {
  const filtered = tags.filter((t) => t.objectId !== tag.objectId);
  return [...filtered];
};

const upsert = (tags, tag) => {
  return [...remove(tags, tag), tag];
};

const update = (tags, tag) => {
  const index = tags.findIndex((x) => x.objectId === tag.objectId);
  //console.log("tags before splice ", tags);
  //console.log("the index ", index);
  tags.splice(index, 1, tag);
  //console.log("tags after splice ", tags);

  return [...tags];
};

export const tagsReducer = (tags = initState, action) => {
  let oldTags = tags.data; //.slice()

  switch (action.actionType) {
    case 'tagsByBeaconId':
      return {
        //...tags,
        data: action.payload,
      };

    case 'getTag':
      return {
        ...tags,
        data: action.payload,
      };

    case 'getTags':
      return {
        //...tags,
        data: action.payload.reverse(),
      };

    case 'getTagsByBeaconId':
      return {
        //...tags,
        data: action.payload.reverse(),
      };

    case 'deleteTag':
      return {
        ...tags,
        data: remove(oldTags, action.payload),
      };

    case 'updateMyTag':
      return {
        ...tags,
        data: update(oldTags, action.payload),
      };

    case 'createTag':
      return {
        ...tags,
        data: upsert(oldTags, action.payload),
        // ...tags,
        // data: newTags//.splice(0,0,action.payload)
      };
    default:
      return tags;
  }
};
