import { showSnack } from 'react-redux-snackbar';
import { SHOW_NOTIFICATION } from '../../actions/ui.actions';

export const uiMiddleWare = () => (next) => (action) => {
  next(action);

  switch (action.type) {
    case SHOW_NOTIFICATION:
      next(showSnack('UI', { label: action.payload.message, timeout: 3000 }));
      break;

    default:
      break;
  }
};
