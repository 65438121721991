import {
  SET_SUBSCRIBERS,
  FETCH_SUBSCRIBERS,
  UPDATE_SUBSCRIBER,
} from './subscriber.actions';
import * as R from 'ramda';

const initState = { data: [] };

const update = (subscribers, subscriber) => {
  const index = R.findIndex(R.propEq('objectId', subscriber.objectId))(
    subscribers
  );
  subscribers.splice(index, 1, subscriber);

  return [...subscribers];
};

export const subscriberReducer = (subscriber = initState, action) => {
  let oldSubscribers = subscriber.data; //.slice()

  switch (action.type) {
    case SET_SUBSCRIBERS:
      return {
        ...subscriber,
        data: action.payload,
      };
    case FETCH_SUBSCRIBERS:
      //newConnector.splice(action.index, 1)
      return {
        ...subscriber,
        data: oldSubscribers,
      };

    case UPDATE_SUBSCRIBER:
      return {
        ...subscriber,
        data: update(oldSubscribers, action.payload),
      };

    default:
      return subscriber;
  }
};
