import { API_REQUEST, apiError, apiSuccess } from '../../actions/api.actions';
import { forEach, forEachObjIndexed, isEmpty, isNil } from 'ramda';
import axios from 'axios';
import uuid from 'react-uuid';
import { Parse } from 'parse';
import { extractImageAndIframe } from '../../../utilities';

export const apiMiddleWare =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    next(action);

    if (action.type.includes(API_REQUEST)) {
      const { feature, params, func, method, api, endPoint } = action.meta;

      if (feature === '[SESSIONS]') {
        if (func === 'deleteSession') {
          Parse.User.logOut();
          const response = Parse.User.logOut();
          dispatch(apiSuccess({ response, feature, func }));
        } else {
          try {
            const response = await Parse.User.logIn(
              params.email,
              params.password
            );
            dispatch(apiSuccess({ response, feature }));
          } catch (error) {
            dispatch(apiError({ error, feature }));
          }
        }
      } else if (api === 'kinects') {
        const headers = {};
        headers['X-Parse-Application-Id'] = process.env.REACT_APP_API_KEY;
        headers['X-Parse-Session-Token'] =
          Parse.User.current().getSessionToken();

        let stage =
          process.env.REACT_APP_STAGE == 'DEV' ||
          process.env.REACT_APP_STAGE == 'DEVELOP'
            ? 'DEVELOP'
            : 'PRODUCTION';

        if (process.env.REACT_APP_STAGE == 'LOCAL') {
          stage = 'LOCAL';
        }

        const requestParams = {};
        requestParams.method = method;
        requestParams.headers = headers;
        requestParams.url =
          process.env[`REACT_APP_KINECTS_${stage}_URL`] +
          '/' +
          endPoint +
          '?' +
          'redirectPath=' +
          window.location;

        if (action.payload) {
          //not sure why right now but will investigate...
          requestParams.data = action.payload;
        }

        try {
          let results = await axios(requestParams);

          if (results?.data?.redirectUrl) {
            window.location = results?.data?.redirectUrl;
            return;
          }

          results = results?.data;
          dispatch(apiSuccess({ results, feature, func }));
        } catch (e) {
          dispatch(apiError({ e, feature }));
        }
      } else if (func === 'createTag') {
        let pushNotification = params.pushNotification;
        let pushNotificationId = uuid();

        if (pushNotification) {
          let date = new Date(pushNotification);
          const lifeTag = {
            tag: { pushNotification: date, id: pushNotificationId },
          };

          Parse.Cloud.run('scheduleWebPush', lifeTag);
        }

        for (let i = 0; i < params.lifeTags.length; i++) {
          const lifeTag = params.lifeTags[i];
          const Message = Parse.Object.extend('Message');
          let message = new Message();
          let createTagParams = await extractImageAndIframe(
            lifeTag.html,
            lifeTag
          );

          if (pushNotification) {
            await message.save();
            message.addUnique('pushId', pushNotificationId);
          }

          const setMessage = async (v, k) => {
            if (k === 'message') message.set(k, v);
            if (k === 'phoneNumbers' && !isEmpty(v)) message.add(k, v);
            if (k === 'recipients' && !isEmpty(v)) message.set(k, v);
            if (k === 'latitude' && v !== '') {
              if (lifeTag.longitude) {
                var point = new Parse.GeoPoint({
                  latitude: parseFloat(lifeTag.latitude),
                  longitude: parseFloat(lifeTag.longitude),
                });
                message.set('location', point);
              }
            }

            if (k === 'expiration' && v !== '') {
              let date = new Date(v);
              message.set(k, date);
            }

            if (k === 'startDate' && !isEmpty(v) && !isNil(v)) {
              let date = new Date(v);
              message.set(k, date);
            }

            if (k === 'hasWeb') {
              message.set(k, v);
            }

            if (k === 'webUrl' && !isEmpty(v)) {
              message.set(k, v);
            }

            if (k === 'endDate' && !isEmpty(v) && !isNil(v)) {
              let date = new Date(v);
              message.set(k, date);
            }

            if (k === 'recurrence' && v !== 'none' && !isEmpty(v)) {
              message.set(k, v);
            }

            if (k === 'state' && v !== '') {
              message.set(k, v);
            }

            if (k === 'address' && v !== '') message.set(k, v);

            if (k === 'image' && v !== '') {
              if (v && v.length) {
                let image = new Parse.File('image', v[0], v[0].type);
                message.set(k, image);
                if (v[0].type.indexOf('video') != -1) {
                  message.set('isVideo', 'true');
                }
              }
            }

            if (k === 'acrId' && v !== '') message.set(k, v);

            if (k === 'media' && v !== '') {
              let acrId = v[0];
              message.set('acrId', acrId);
            }

            if (k === 'channels' && v !== '') {
              for (var i = 0; i < v.length; i++) {
                let channelName = v[i];
                var query = new Parse.Query('Channel');
                query.equalTo('name', channelName);
                let channel = await query.find();
                if (channel) {
                  await message.addUnique(k, channel[0].get('name'));
                  //await message.save();
                }
              }
            }

            if (k === 'sendToChannel') {
              message.set(k, v);
            }

            if (
              k === 'html' &&
              v !== '' &&
              v !== undefined &&
              v !== null &&
              v !== ' '
            ) {
              message.set(k, v);
            }

            if (k === 'social') {
              message.set(k, v);
            }

            if (k === 'type') {
              message.set(k, v);
            }

            if (k === 'showHeader') {
              message.set(k, v);
            }

            if (k === 'beacons' && v !== '') {
              const saveBeacon = async (b) => {
                let query = new Parse.Query('Beacon');

                let beacons = await query.get(b);

                if (beacons) {
                  await message.addUnique('beaconId', beacons.get('beaconId'));
                }
              };

              await forEach(saveBeacon, v);
              await message.save();
            }
          };

          forEachObjIndexed(setMessage, createTagParams);

          try {
            await message.save();
          } catch (error) {
            console.log('error', error);
          }
        }
        try {
          let results = {};
          dispatch(apiSuccess({ results, feature, func }));
        } catch (error) {
          dispatch(apiError({ error, feature }));
        }
      } else if (func === 'createMedia') {
        try {
          params.id = uuid();
          if (params) {
            params.apiKey = process.env.REACT_APP_TAGGER_API_KEY;
          }

          let file = params.audio_file[0];
          params.type = file.type;

          const lambdaUrl =
            process.env[`REACT_APP_${process.env.REACT_APP_STAGE}_LAMBDA_URL`];

          let upResults = await axios({
            method: 'post',
            url: `${lambdaUrl}upload`,
            data: params,
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': params.apiKey,
            },
          });

          let headers = {};
          headers['Content-Type'] = file.type;

          await axios({
            method: 'put',
            url: upResults.data.url,
            data: file,
            headers: headers,
          });

          let fingerPrintData = await axios({
            method: 'post',
            url: `${lambdaUrl}fingerPrint`,
            data: params,
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': params.apiKey,
            },
          });

          let response = fingerPrintData.data;
          //
          let tagParams = {};

          tagParams.acrId = response.acr_id;
          tagParams.name = params.title;
          tagParams.description = params.description;
          tagParams.apiKey = params.apiKey;

          let results = await Parse.Cloud.run('createMedia', tagParams);

          dispatch(apiSuccess({ results, feature, func }));
        } catch (error) {
          dispatch(apiError({ error, feature }));
        }
      } else {
        try {
          if (params) {
            params.apiKey = process.env.REACT_APP_TAGGER_API_KEY;
          }

          if (params && params.parent) delete params.parent;

          let results = await Parse.Cloud.run(func, params);

          if (results && results.statusCode && results.statusCode == 302) {
            window.location = results.headers.Location;
            return;
          }

          dispatch(apiSuccess({ results, feature, func }));
        } catch (error) {
          if (error.message === 'Invalid session token') {
            Parse.User.logOut();
          } else {
            dispatch(apiError({ error, feature }));
          }
        }
      }
    }
  };
