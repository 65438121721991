// DoctorsMap.js

import React, { Component } from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import Autocomplete from 'react-google-autocomplete';

class SearchMap extends Component {
  constructor(props) {
    super(props);
    const { latitude, longitude } = this.props;
    this.state = {
      address: '',
      city: '',
      area: '',
      state: '',
      mapPosition: {
        lat: latitude,
        lng: longitude,
      },
      markerPosition: {
        lat: latitude,
        lng: longitude,
      },
    };

    let s = this.state;

    navigator.geolocation.getCurrentPosition(function (position) {
      //console.log("Latitude is :", position.coords.latitude);
      //console.log("Longitude is :", position.coords.longitude);
      s.mapPosition.lat = position.coords.latitude;
      s.mapPosition.lng = position.coords.longitude;
    });
  }

  /**
   * Get the city and set the city input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCity = (addressArray = []) => {
    let city = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        'administrative_area_level_2' === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };
  /**
   * Get the area and set the area input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getArea = (addressArray = []) => {
    let area = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray && addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            'sublocality_level_1' === addressArray[i].types[j] ||
            'locality' === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };
  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getState = (addressArray = []) => {
    let state = '';
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          'administrative_area_level_1' === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  onPlaceSelected = (place) => {
    if (place.geometry) {
      const address = place.formatted_address,
        addressArray = place.address_components,
        city = this.getCity(addressArray),
        area = this.getArea(addressArray),
        state = this.getState(addressArray),
        latValue = place.geometry.location.lat(),
        lngValue = place.geometry.location.lng(); // Set these values in the state.

      //console.log("there is an address array", addressArray);

      this.setState({
        address: address ? address : '',
        area: area ? area : '',
        city: city ? city : '',
        state: state ? state : '',
        latitude: latValue,
        longitude: lngValue,
        markerPosition: {
          lat: latValue,
          lng: lngValue,
        },
        mapPosition: {
          lat: latValue,
          lng: lngValue,
        },
      });

      if (this.props.callback) {
        this.props.callback({
          address: address ? address : '',
          latitude: latValue,
          longitude: lngValue,
        });
      }
    }
  };

  render() {
    const { googleMapURL } = this.props;
    //console.log("this is the props in the maps component", this.props);

    return (
      <GoogleMap
        defaultZoom={14}
        center={{
          lat: this.state.mapPosition.lat,
          lng: this.state.mapPosition.lng,
        }}
        googleMapURL={googleMapURL}
      >
        <Marker
          position={{
            lat: this.state.mapPosition.lat,
            lng: this.state.mapPosition.lng,
          }}
        />
        <Autocomplete
          style={{
            width: '100%',
            height: '40px',
            paddingLeft: '16px',
            marginTop: '5px',
            marginBottom: '100px',
          }}
          onPlaceSelected={this.onPlaceSelected}
          types={['establishment', 'geocode']}
        ></Autocomplete>
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(SearchMap));
