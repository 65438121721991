import { connect } from 'react-redux';
import {
  fetchChannels,
  showChannel,
  createChannel,
  deleteChannel,
  userChannels,
} from './channel.actions';
import Index from './channel.index';
import Show from './channel.show';

const mapStateToProps = (state) => ({
  //maps all channeldata for the state to the channel
  channel: state.channel.data,
  location: state.history,
  userChannels: state.channel.userData,
});

const mapDispatchToProps = (dispatch) => ({
  all: (query) => dispatch(fetchChannels({ query })),

  deleteChannel: (query) => {
    ////console.log("own props", ownProps);
    //query.index = ownProps.index;
    dispatch(deleteChannel({ query }));
  },

  createChannel: (query) => {
    //query.index = ownProps.index;
    dispatch(createChannel({ query }));
  },

  getUserChannels: () => {
    //query.index = ownProps.index;
    dispatch(userChannels());
  },

  //updateChannel: (query) => dispatch(updateChannel({query})),

  show: (query) => dispatch(showChannel({ query })),
});

const channelsConnector = connect(mapStateToProps, mapDispatchToProps);

export const All = channelsConnector(Index);
//export const Create = channelsConnector(New);
//export const Update = channelsConnector(Edit);
export const Get = channelsConnector(Show);
//export const Delete = channelsConnector(Remove);
