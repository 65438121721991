import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CardContent, CardMedia, Grid } from '@material-ui/core';

const styles = {
  grow: {
    flexGrow: 1,
  },
  hidden: {
    display: 'none',
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },

  iframeBorder: {
    marginLeft: '15px',
  },

  media: {
    width: '75%',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '15px',
  },

  descText: {
    fontSize: '18px',
    fontWeight: 400,
    fontFamily: 'helvetica',
    overflow: 'auto',
    marginTop: '15px',
    padding: '15px',
    textAlign: 'center',
  },

  messageText: {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'helvetica',
    justifyContent: 'center',
    display: 'flex',
  },

  blockquote: {
    fontSize: '16px',
    textAlign: 'center',
    fontFamily: 'helvetica',
    background:
      'linear-gradient(to right, #039be5 4px, transparent 4px) 0 0%,' +
      'linear-gradient(to bottom, #039be5 4px, transparent 4px) 0% 0,' +
      'linear-gradient(to right, #039be5 4px, transparent 4px) 0 100%,' +
      'linear-gradient(to left, #039be5 4px, transparent 4px) 100% 0,' +
      'linear-gradient(to left, #039be5 4px, transparent 4px) 100% 100%,' +
      'linear-gradient(to bottom, #039be5 4px, transparent 4px) 100% 0,' +
      'linear-gradient(to top, #039be5 4px, transparent 4px) 100% 100%,' +
      'linear-gradient(to top, #039be5 4px, transparent 4px) 0 100%',
    margin: '1.5em 10px',
    padding: '0.5em 15px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '20px 20px',
    '&:p': {
      display: 'inline',
    },
  },
};

class TagIframe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  hideSpinner = () => {
    this.setState({
      loading: false,
    });
  };

  render() {
    const { url, classes, metaData, iframeAble, height } = this.props;
    let image;
    let desc;

    if (metaData) {
      desc =
        metaData.description ||
        metaData?.open_graph?.description ||
        metaData?.twitter_card?.description;
      if (metaData?.open_graph?.images && metaData?.open_graph?.images[0]) {
        image = metaData?.open_graph?.images[0].url;
      } else if (
        metaData?.twitter_card?.images &&
        metaData?.twitter_card?.images[0]
      ) {
        image = metaData?.twitter_card?.images[0].url;
      } else {
        image = metaData.favicon;
      }

      if (!metaData.url) {
        metaData.url = url;
      }
    }
    return (
      <div>
        {metaData && !iframeAble && (
          <div>
            <Grid
              container
              spacing={0}
              alignContent="center"
              alignItems="center"
              justify="center"
            >
              {metaData.title && (
                <Grid item sm={12} className={classes.descText}>
                  {metaData.title}
                </Grid>
              )}

              {image && (
                <Grid item sm={12}>
                  <CardMedia
                    className={classes.media}
                    src={image}
                    title="image metadata"
                    component="img"
                  />
                </Grid>
              )}

              {desc && (
                <Grid item sm={12} className={classes.blockquote}>
                  {desc}
                </Grid>
              )}

              {(metaData.canonical || metaData.url) && (
                <Grid item sm={12}>
                  <CardContent>
                    <Grid item sm={12}>
                      <a
                        href={metaData.url ? metaData.url : metaData.canonical}
                        className={classes.messageText}
                      >
                        {metaData.url ? 'Click Here' : metaData.canonical}
                      </a>
                    </Grid>
                  </CardContent>
                </Grid>
              )}
            </Grid>
          </div>
        )}
        {!iframeAble ? (
          <div></div>
        ) : (
          <iframe
            src={url}
            title={url}
            width="100%"
            height={height || '400px'}
            onLoad={this.hideSpinner}
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(TagIframe);
