import { connect } from 'react-redux';
import { fetchAnalytic, fetchImpression } from './analytic.actions';
import { fetchTags } from '../tags/tag.actions';
import Index from './analytic.index';
import Show from './analytic.show';

const mapStateToProps = (state) => ({
  analytics: state.analytic.data,
  impressions: state.analytic.impressionData,
  tags: state.tag.data,
});

const mapDispatchToProps = (dispatch) => ({
  getTags: (query) => dispatch(fetchTags({ query })),
  getAnalytics: (query, func) => dispatch(fetchAnalytic({ query, func })),
  getImpressions: (query, func) => dispatch(fetchImpression({ query, func })),
});

const analyticsConnector = connect(mapStateToProps, mapDispatchToProps);

export const All = analyticsConnector(Index);
//export const Create = analyticsConnector(New);
//export const Update = analyticsConnector(Edit);
export const Get = analyticsConnector(Show);
//export const Delete = analyticsConnector(Remove);
