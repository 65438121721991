export const ANALYTIC = '[ANALYTIC]';
export const IMPRESSION = '[IMPRESSION]';

// action types
export const FETCH_ANALYTIC = `${ANALYTIC} FETCH`;
export const SET_ANALYTIC = `${ANALYTIC} SET`;
export const FETCH_IMPRESSION = `${IMPRESSION} FETCH`;
export const SET_IMPRESSION = `${IMPRESSION} SET`;

export const fetchAnalytic = ({ query, func }) => ({
  type: FETCH_ANALYTIC,
  payload: query,
  function: func,
});

export const setAnalytic = ({ analytic }) => ({
  type: SET_ANALYTIC,
  payload: analytic,
});

export const fetchImpression = ({ query, func }) => ({
  type: FETCH_IMPRESSION,
  payload: query,
  function: func,
});

export const setImpression = ({ impression }) => ({
  type: SET_IMPRESSION,
  payload: impression,
});
