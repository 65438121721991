export const NORMALIZE_DATE = 'NORMALIZE_DATE';
export const NORMALIZE_COMPLETE = 'NORMALIZE_COMPLETE';
export const NORMALIZE_ADDRESS = 'NORMALIZE_ADDRESS';

export const normalizeComplete = ({ payload, feature }) => ({
  type: `${feature} ${NORMALIZE_COMPLETE}`,
  payload: payload,
  meta: { feature },
});

export const normalizeDate = ({ state, feature, format }) => ({
  type: `${feature} ${NORMALIZE_DATE}`,
  payload: state,
  meta: { feature, format },
});

export const normalizeAddress = ({ payload, feature }) => ({
  type: `${feature} ${NORMALIZE_ADDRESS}`,
  payload: payload,
  meta: { feature },
});
