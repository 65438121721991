import React, { Component } from 'react';
import './dashboard.css';

import { find, propEq } from 'ramda';
import { Typography, FormControl, MenuItem, Select } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Button from '@mui/material/Button';
import { withStyles } from '@material-ui/core/styles';
import AnalyticChart from '../ui/chart.component';
import { Divider, Grid } from '@mui/material';
import TagPreview from '../ui/tagPreview.component';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import TimelineIcon from '@mui/icons-material/Timeline';
import '@fontsource/heebo';
import * as R from 'ramda';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  card: {
    marginRight: '90px',
    width: '95%',
    height: '409px',
    marginBottom: '20px',
    padding: '32px',
  },
  createTag: {
    textAlign: 'center',
    width: '100%',
    height: '409px',
    margin: '0,auto',
  },
  myButton: {
    backgroundColor: '#A80000',
    color: '#FFFFFF',
    borderRadius: 29.5,
    fontWeight: 700,
    fontSize: 10,
    fontFamily: 'helvetica',
    marginLeft: '22px',
    marginBottom: 18,
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },

  totalChartBackground: {
    backgroundColor: '#FAFAFA',
    borderRadius: '4px',
  },
  usersChartBackground: {
    backgroundColor: '#06123E',
    borderRadius: '4px',
  },
  chartTitle: {
    fontFamily: 'Heebo',
    fontSize: '21px',
    color: '#0F152A',
    fontWeight: 400,
  },
  chartSubtitle: {
    fontFamily: 'Heebo',
    color: '#424242',
    fontSize: '12px',
    fontWeight: 300,
  },
  usersChartTitle: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '14px',
    color: '#FFFFFF',
  },
  usersChartBigNumber: {
    fontFamily: 'Heebo',
    fontWeight: 400,
    fontSize: '35px',
    color: '#FFFFFF',
  },
});

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      latitude: '',
      longitude: '',
      timeframe: 7,
    };
    this.props.getTags();
    this.props.fetchMedia();
    this.getLocation();
    this.getImpressions({ func: 'getImpressions' });
    this.getAnalytics();
  }

  handleTimeChange = (event) => {
    this.setState({ timeframe: event.target.value }, () => {
      this.getImpressions({ func: 'getImpressions' });
      this.getAnalytics();
    });
  };

  getLocation() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.setState({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    });
  }

  getImpressions() {
    let impressionsParam = {
      days: this.state.timeframe,
    };
    this.props.getImpressions({
      query: impressionsParam,
      func: 'getImpressions',
    });
  }

  getAnalytics() {
    var analyticParam = {
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      amountOfMinutes: 1440 * this.state.timeframe,
    };
    this.props.getAnalytics(analyticParam);
  }

  componentDidMount() {
    //return all tags
  }

  renderMedia(acrId) {
    let media = find(propEq('acrId', acrId), this.props.media);
    media = media && media.name ? media.name : '';

    return media;
  }

  closeAllTags() {
    this.setState({ open: false });
  }

  showAllTags() {
    this.setState({ open: true });
  }

  renderChart(props) {
    return (
      <AnalyticChart
        ChartTitle={props.chartTitle}
        ChartType={props.chartType}
        DataArray={props.data}
        xTitle={props.xTitle}
        yTitle={props.yTitle}
        colors={props.colors}
      />
    );
  }

  renderTimeSelect() {
    return (
      <FormControl variant="outlined">
        <Select
          fullwidths={true}
          onChange={this.handleTimeChange}
          label={this.state.timeframe}
          value={this.state.timeframe}
          style={{ color: '#000000' }}
          InputProps={{ disableUnderline: true }}
        >
          <MenuItem value={1}>Day</MenuItem>
          <MenuItem value={7}>Week</MenuItem>
          <MenuItem value={30}>Month</MenuItem>
          <MenuItem value={365}>Year</MenuItem>
        </Select>
      </FormControl>
    );
  }

  render() {
    const { classes, history } = this.props;
    const lifeCodeScanned = (imp) => imp.type === 'LIFE_CODE_SCANNED';
    const notLifeCodeScanned = (imp) => imp.type !== 'LIFE_CODE_SCANNED';
    let lifeCodeImpressions = [];
    let noLifeCodeImpressions = [];
    if (this.props.impressions) {
      lifeCodeImpressions.push(
        ...R.filter(lifeCodeScanned, this.props.impressions)
      );
      noLifeCodeImpressions.push(
        ...R.filter(notLifeCodeScanned, this.props.impressions)
      );
    }
    return (
      <div className={classes.root}>
        <Grid container spacing={3} direction={'column'}>
          <Grid item xs={12} container direction={'row'} spacing={2}>
            <Grid item>
              <LocalOfferOutlinedIcon htmlColor={'#DD0B06'} />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h6">Latest Tags</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} item justifyContent={'flex-start'}>
            {this.props.tags &&
              this.props.tags.slice(0, 2).map((item, key) => (
                <Grid item xs={'auto'} key={key}>
                  <TagPreview tag={item} showEditButtons={true} />
                </Grid>
              ))}
          </Grid>
          <Grid item container sm={12} justifyContent={'flex-end'}>
            <Button
              style={{ whiteSpace: 'nowrap' }}
              color="primary"
              variant="contained"
              endIcon={<AddIcon />}
              onClick={() => history.push('/tags/create')}
            >
              Create a Tag
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {
            <Grid container item xs={12} direction={'column'} spacing={1}>
              <Grid container item xs={12} direction={'row'} spacing={2}>
                <Grid item>
                  <TimelineIcon htmlColor={'#DD0B06'} />
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="h6">Analytics</Typography>
                </Grid>
                <Grid item>{this.renderTimeSelect()}</Grid>
              </Grid>
              <Grid container item xs={12} direction={'row'}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Grid
                    container
                    direction={'column'}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    spacing={2}
                  >
                    <Grid item>
                      <Typography variant="h5">Total Impressions</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {!this.props.loading ? (
                        this.renderChart({
                          chartType: 'LineChart',
                          data: noLifeCodeImpressions,
                          colors: ['#2F3F73', '#2F3F73'],
                        })
                      ) : (
                        <Typography>Loading...</Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {/*<Grid item xs={12} sm={12} md={12} lg={6}>*/}
                {/*  <Grid container className={classes.usersChartBackground} spacing={2} direction={"column"}>*/}
                {/*    <Grid item xs={12}>*/}
                {/*      <Typography className={classes.usersChartTitle}>LifeTagger users in your area</Typography>*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={12}>*/}
                {/*      { this.renderChart({chartType: "ColumnChart", data: this.props.analytics, colors: ["#0F1F54", "#0F1F54"]}) }*/}
                {/*    </Grid>*/}
                {/*  </Grid>*/}
                {/*</Grid>*/}
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Grid container spacing={1} direction={'column'}>
                    <Grid item>
                      <Typography variant="h5">LifeCode Scans</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {!this.props.loading ? (
                        this.renderChart({
                          chartType: 'ColumnChart',
                          data: lifeCodeImpressions,
                          colors: ['#DD0B06', '#2F3F73'],
                        })
                      ) : (
                        <Typography>Loading...</Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Index);
