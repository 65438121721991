import { connect } from 'react-redux';
import { fetchConnector, fetchConnectors } from './connector.actions';
import Index from './connector.index';
import Show from './connector.show';
import { fetchUserConnectors } from '../userconnectors/userconnector.actions';

const mapStateToProps = (state) => ({
  connectors: state.connector.data,
  userConnectors: state.userConnector.data,
});

const mapDispatchToProps = (dispatch) => ({
  getUserConnectors: () => dispatch(fetchUserConnectors()),
  getConnectors: () => dispatch(fetchConnectors()),
  getConnector: (id) => dispatch(fetchConnector(id)),
});

const connectorConnector = connect(mapStateToProps, mapDispatchToProps);

export const All = connectorConnector(Index);
//export const Create = connectorConnector(New);
//export const Update = connectorConnector(Edit);
export const Get = connectorConnector(Show);
//export const Delete = connectorConnector(Remove);
