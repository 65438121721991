import React, { Component } from 'react';

class Show extends Component {
  componentDidMount() {}

  render() {
    return <div>bootsrap html here for editing tag view</div>;
  }
}

export default Show;
