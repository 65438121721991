import React, { Component } from 'react';
import { Grid, Fab, Box } from '@material-ui/core';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';

let theme = createMuiTheme();

const useStyles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto',
    borderRadius: 20,
    paddingBottom: 15,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 20,
  },
};

class Remove extends Component {
  componentDidMount() {}

  confirmDelete = () => {
    this.props.remove(this.props.beacon);
    this.props.handleClose();
  };

  render() {
    const { beacon, classes } = this.props;
    return (
      <div className={classes.paper}>
        <div>
          <h5 id="transition-modal-title" color="red">
            Remove &quot;{beacon.name}&quot;
          </h5>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box>
                <Fab
                  size="medium"
                  style={{
                    backgroundColor: '#a80000',
                    color: '#FFFFFF',
                    float: 'center',
                  }}
                  variant="extended"
                  onClick={this.confirmDelete}
                >
                  Are you sure?
                </Fab>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(Remove);
