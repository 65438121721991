import React from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
//import FilterListIcon from '@mui/icons-material/FilterList';

let theme;
import { visuallyHidden } from '@mui/utils';
import { Grid, Pagination } from '@mui/material';
import Select from '@material-ui/core/Select';
import { MenuItem } from 'material-ui';
import { useTheme } from '@mui/material/styles';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;

  theme = useTheme();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ bgcolor: theme.palette.background.default }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const { numSelected, headerText, theme } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        minHeight: '40px',
        bgcolor: theme.palette.primary.main,
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%', color: '#fff' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {headerText}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton></IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton></IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

export default function TriggerTableComponent(props) {
  let { rows, headCells, headerText } = props.data;

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(props.data?.orderBy);
  const [selected, setSelected] = React.useState(props.data?.selected);
  const [page, setPage] = React.useState(1);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const theme = useTheme();

  const { callback } = props;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name.objectId);
    let newSelected = [];

    if (!name.objectId) {
      name.objectId = name.id;
    }

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.objectId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    callback(newSelected);
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (object) => {
    //return eqProps('objectId', selected, object);
    return selected.indexOf(object.objectId) !== -1;
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box>
      <Paper elevation={0}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selected={selected}
          callback={callback}
          headerText={headerText}
          theme={theme}
        />
        <TableContainer
          sx={{ maxHeight: 440 }}
          color={theme.palette.background.paper}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 750, bgcolor: theme.palette.background.default }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
              sx={{ bgcolor: theme.palette.background.default }}
            />
            <TableBody sx={{ bgcolor: theme.palette.background.paper }}>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
                .map((row, index) => {
                  const isItemSelected = isSelected(row);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      sx={{
                        bgcolor: isItemSelected
                          ? theme.palette.background.default
                          : theme.palette.background.paper,
                      }}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      {headCells.map((value, hIndex) => {
                        return hIndex === 0 ? (
                          <TableCell
                            component="th"
                            size={'small'}
                            id={labelId}
                            scope="row"
                            key={hIndex}
                            padding="none"
                          >
                            <Typography variant={'caption'}>
                              {row[value.id]}
                            </Typography>
                          </TableCell>
                        ) : (
                          <TableCell key={hIndex}>
                            <Typography variant={'caption'}>
                              {row[value.id]}
                            </Typography>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid
          container
          direction={'row'}
          sx={{
            paddingTop: '22px',
            paddingBottom: '18px',
            bgcolor: theme.palette.background.default,
          }}
        >
          <Grid item xs={5}>
            <Pagination
              count={Math.ceil(rows.length / 10)}
              shape={'rounded'}
              size={'small'}
              variant={'outlined'}
              page={page}
              color={'primary'}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </Grid>
          <Grid item xs={2} />

          <Grid item xs={2}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={rowsPerPage}
              label="Rows"
              onChange={handleChangeRowsPerPage}
              color={'primary'}
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </Grid>

          <Grid item xs={3}>
            <Typography variant={'caption'}>
              Showing {(page * rowsPerPage) / 10}-
              {rowsPerPage * page < rows.length
                ? rowsPerPage * page
                : rows.length}{' '}
              of {rows.length}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
