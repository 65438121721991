import { DELETE_SESSION, SET_SESSIONS } from './session.actions';

const initState = null;
export const sessionReducer = (session = initState, action) => {
  let newSessions = session;
  switch (action.type) {
    case DELETE_SESSION:
      return {};

    case SET_SESSIONS:
      if (action.payload && action.payload.get('adminAccess')) {
        newSessions = action.payload;
      }
      return newSessions;

    default:
      return newSessions;
  }
};
