import { SET_USER, FETCH_USER } from './user.actions';

const initState = { data: [] };

export const userReducer = (users = initState, action) => {
  switch (action.type) {
    case SET_USER:
      //console.log("set user action payload: ", action.payload)
      return {
        user: action.payload,
      };
    case FETCH_USER:
      return {
        user: action.payload,
      };

    case 'getUsers':
      return users;

    default:
      return null;
  }
};
