import React from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';

const CTAButton = styled(Button)(({ theme }) => ({
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontWeight: 'bold',
  fontSize: '1rem',
  padding: '10px 20px',
  borderRadius: '8px',
  width: '100%',

  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
  },

  '&:focus': {
    animation: 'pulse 1.5s infinite',
    outline: 'none',
  },

  '@keyframes pulse': {
    '0%': {
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    },
    '50%': {
      boxShadow: '0 0 20px rgba(0, 0, 0, 0.6)',
    },
    '100%': {
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    },
  },
}));
export function Show(props) {
  const { product, backgroundColor, isYearly, setPlan } = props;

  const defaultPrice = isYearly
    ? product.prices.find((price) => price.id !== product['default_price'])
    : product.prices.find((price) => price.id === product['default_price']);

  const metaData = Object.entries(product.metadata)
    .map(([key, value]) => ({ key, value }))
    .sort((a, b) => {
      if (a.value !== 'false' && b.value === 'false') return -1;
      if (a.value === 'false' && b.value !== 'false') return 1;
      if (a.key.includes('Everything included')) return -1;
      return 0;
    });

  const cardStyle = {
    backgroundColor: backgroundColor || '#fff',
    borderRadius: '15px',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
    width: '100%',
    transition: 'box-shadow 0.3s ease',
    '&:hover': {
      boxShadow: '0 6px 20px rgba(0, 0, 0, 0.3)',
    },
  };

  return (
    <Card sx={cardStyle}>
      <CardContent>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: '0.5rem',
          }}
        >
          {product.name}
        </Typography>
        <Typography
          variant="body2"
          sx={{ textAlign: 'center', color: '#666', marginBottom: '1rem' }}
        >
          {product.description}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: '1.5rem',
            color: '#000',
          }}
        >
          {`$${defaultPrice.unit_amount_decimal.slice(
            0,
            defaultPrice.unit_amount_decimal.length - 2
          )}.${defaultPrice.unit_amount_decimal.slice(
            defaultPrice.unit_amount_decimal.length - 2
          )}`}
          <Typography
            variant="caption"
            sx={{ color: '#666', marginLeft: '5px' }}
          >
            {isYearly ? '/year' : '/month'}
          </Typography>
        </Typography>
        <CardActions>
          <CTAButton
            variant="contained"
            size="large"
            onClick={() => setPlan(defaultPrice.id)}
          >
            Get Started
          </CTAButton>
        </CardActions>
      </CardContent>
      {product.metadata && (
        <CardContent>
          <Typography
            variant="subtitle1"
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              color: '#333',
              marginBottom: '1rem',
            }}
          >
            Features
          </Typography>
          <Grid container spacing={2}>
            {metaData.map((metadata, key) => (
              <Grid item xs={6} key={key}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: 'bold',
                    color: '#333',
                    marginBottom: '0.25rem',
                  }}
                >
                  ✅ {metadata.key}
                </Typography>
                <Typography variant="caption" sx={{ color: '#666' }}>
                  {metadata.value}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      )}
    </Card>
  );
}
