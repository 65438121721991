import React, { Component } from 'react';

class Remove extends Component {
  componentDidMount() {}

  render() {
    return <div>bootsrap html here for editing tag view</div>;
  }
}

export default Remove;
