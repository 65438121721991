import { connect } from 'react-redux';
import ShowSnack from './showSnack.component';
import { deleteSession } from '../sessions/session.actions';
import Header from './header.component';
import UiBlock from './uiBlock.component';
import DragContainer from './dragContainer.component';
import { updateTag } from '../tags/tag.actions';
import PasswordReset from './passwordReset.component';

const mapStateToProps = (state) => ({
  notification: state.notification.message,
  loader: state.ui.loading,
});

const mapDispatchToProps = (dispatch) => ({
  deleteSession: (query) => dispatch(deleteSession({ query })),
  updateTag: (query) => {
    dispatch(updateTag({ query }));
  },
});

const appConnector = connect(mapStateToProps, mapDispatchToProps);

export const snack = appConnector(ShowSnack);
export const header = appConnector(Header);
export const uiBlock = appConnector(UiBlock);
export const dragContainer = appConnector(DragContainer);
export const ForgotPassword = appConnector(PasswordReset);
