import React, { Component } from 'react';
import { CardContent, CardMedia, CardHeader } from '@material-ui/core';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { createMarkup, setUpPdfRendering } from '../utilities';
import { withStyles } from '@material-ui/core/styles';
import LocationOn from '@material-ui/icons/LocationOn';
import TagIframe from '../ui/tagIframe.component';
import PDF from '../ui/widgets/pdf.component';
import { Parse } from 'parse';
//import DOMPurify from 'dompurify';
//import {SocialMediaIconsReact} from "social-media-icons-react";

import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { Grid } from '@mui/material';
import HeaderPullDownComponent from '../ui/headerPullDown.component';
//let profileImage = '/images/noProfile.png';
let userName, userCompanyName;

const useStyles = {
  card: {
    width: '100%',
    wordWrap: 'break-word',
    position: 'relative',
    boxShadow: '0 28px 14px 0 rgba(0,0,0,0.5)',
    overflow: 'visible',
    borderRadius: '16px',
    transition: '0.4s',
    marginBottom: '15px',
    paddingBottom: '10px',
    background: [
      '-moz-linear-gradient(top, rgba(139,0,0,0.93) 0%, rgba(139,0,0,0.41) 100%)',
      '-webkit-linear-gradient(top, rgba(139,0,0,0.93) 0%,rgba(139,0,0,0.41) 100%)',
      'linear-gradient(to bottom, rgba(139,0,0,0.93) 0%,rgba(139,0,0,0.41) 100%)',
    ],
    filter:
      "progid:DXImageTransform.Microsoft.gradient( startColorstr='#ed8b0000', endColorstr='#698b0000',GradientType=0 )",
    top: 12,
    marginLeft: '2%',
    color: 'black',
    '&:hover': {
      transform: 'translateY(-7px)',
      '& $shadow': {
        bottom: '-1.5rem',
      },
      '& $shadow2': {
        bottom: '-2.5rem',
      },
    },
    '&:before': {
      //content: '""',
      position: 'absolute',
      zIndex: 0,
      display: 'block',
      width: '100%',
      bottom: 70,
      height: '100%',
      borderRadius: '1.5rem',
      backgroundColor: 'rgba(252,252,252,1)',
    },
  },
  media: {
    height: 'auto',
    width: '100%',
    objectFit: 'contain',
  },

  fromText: {
    fontSize: '22px',
    color: 'rgba(168,0,0)',
    fontWeight: 700,
    fontFamily: 'helvetica',
  },

  sketchy: {
    fontSize: '2.5rem',
    background: '#ffffff',
    boxShadow: 'rgb(0 0 0 / 40%) 0px -3px 0px 0px',
  },

  messageText: {
    fontSize: '14px',
    color: 'rgba(0,0,0)',
    fontWeight: 400,
    fontFamily: 'helvetica',
    marginBottom: '10px',
  },

  locationText: {
    fontSize: '10px',
    color: 'rgba(0,0,0)',
    fontWeight: 400,
    fontFamily: 'helvetica',
    marginBottom: '10px',
  },

  noWebText: {
    position: 'absolute',
    fontSize: '14px',
    fontStyle: 'italic',
    color: 'rgba(169,169,169)',
    fontWeight: 400,
    fontFamily: 'helvetica',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    marginTop: '50px',
  },

  expandOpen: {
    transform: 'rotate(180deg)',
  },
};

class Show extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    };

    if (Parse.User.current()) {
      let parseUser = Parse.User.current();
      userName = parseUser.get('name');
      userCompanyName = parseUser.get('company');
      userCompanyName = userCompanyName
        ? userCompanyName
        : userName + "'s Company";
    }
  }

  componentDidMount() {
    setUpPdfRendering();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tag !== this.props.tag) {
      setUpPdfRendering();
    }
  }

  handleResize = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });
  };

  render() {
    const { tag, classes, useMarkup, mediaHeight, dontShowIframe, showHeader } =
      this.props;

    if (!useMarkup) {
      tag.markUp = tag.message;
    }

    const renderWeb = tag.hasWeb && !tag.imgUrl && !tag.isVideo ? true : false;
    const renderImage =
      (tag.image || tag.imgUrl) &&
      !tag.isVideo &&
      !tag.hasWeb &&
      !tag.html &&
      tag.type != 'pdf'
        ? true
        : false;
    const fileURL = tag.image ? URL.createObjectURL(tag.image[0]) : tag.imgUrl;

    return (
      <Grid item xs={12}>
        <Grid item xs={12}>
          {tag && showHeader && (
            <HeaderPullDownComponent
              tag={tag}
              fontSize={'16px'}
            ></HeaderPullDownComponent>
          )}
        </Grid>

        <div>
          {tag.social && (
            <Grid container direction="row" justify="space-evenly">
              {tag.social.map((item, key) => (
                <Grid item xs={'auto'} key={key} style={{ marginTop: '22px' }}>
                  {/*<SocialMediaIconsReact*/}
                  {/*    borderWidth="0"*/}
                  {/*    roundness="50%"*/}
                  {/*    size="75"*/}
                  {/*    icon={item.icon}*/}
                  {/*    url={item.url}>*/}
                  {/*</SocialMediaIconsReact>*/}
                </Grid>
              ))}
            </Grid>
          )}

          {tag.address && (
            <Grid item xs={12}>
              <MuiThemeProvider>
                <CardHeader
                  subheader={
                    tag.address && (
                      <Grid container direction={'row'} alignItems="center">
                        <Grid item xs={2}>
                          <LocationOn />
                        </Grid>
                        <Grid item xs={10} sx={{ fontSize: '10px' }}>
                          {tag.address}
                        </Grid>
                      </Grid>
                    )
                  }
                ></CardHeader>
              </MuiThemeProvider>
            </Grid>
          )}
          {tag.html && (
            <Grid item xs={12}>
              {/* <CardContent className={classes.messageText}> */}
              <div dangerouslySetInnerHTML={createMarkup(tag.html)}></div>
              {/* </CardContent> */}
            </Grid>
          )}

          {tag.markUp && !tag.html && (
            <Grid item xs={12}>
              <CardContent className={classes.messageText}>
                <ReactMarkdown plugins={[gfm]}>{tag.markUp}</ReactMarkdown>
              </CardContent>
            </Grid>
          )}

          {!tag.markUp && !tag.html && (
            <Grid item xs={12}>
              <CardContent className={classes.messageText}>
                {tag.message}
              </CardContent>
            </Grid>
          )}

          {renderImage && (
            <CardMedia
              className={classes.media}
              src={fileURL}
              title={tag.message}
              component="img"
              style={{ height: mediaHeight }}
            />
          )}

          {tag.isVideo && (
            <CardMedia
              className={classes.media}
              src={tag.imgUrl}
              component="video"
              style={{ height: mediaHeight }}
              controls
            />
          )}

          {/*{ renderImage &&*/}
          {/*  tag.image.map((file, i) =>*/}
          {/*      <CardMedia*/}
          {/*          className={classes.media}*/}
          {/*          src={URL.createObjectURL(file)}*/}
          {/*          title={tag.message || ""}*/}
          {/*          key={i}*/}
          {/*          component="img"*/}
          {/*          style={{height: mediaHeight}}*/}
          {/*          />*/}
          {/*  )*/}
          {/*}*/}

          {renderWeb && !dontShowIframe ? (
            <CardContent>
              <TagIframe
                url={tag.hasWeb}
                metaData={tag.metaData}
                iframeAble={tag.iframeAble}
                style={{ height: mediaHeight }}
              />
            </CardContent>
          ) : (
            renderWeb && (
              <CardContent style={{ height: mediaHeight }}>
                <div className={classes.noWebText}>Web content</div>
              </CardContent>
            )
          )}
          {tag.type === 'pdf' && (
            <Grid item xs={12}>
              <CardContent
                className={`${classes.media} ${classes.bottomLayer}`}
              >
                <PDF advanceButtons={true} file={fileURL} />
              </CardContent>
            </Grid>
          )}
        </div>
      </Grid>
    );
  }
}

export default withStyles(useStyles)(Show);
