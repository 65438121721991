import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Avatar, Typography } from '@mui/material';

const styles = {
  appBar: {
    backgroundColor: '#fff',
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: 30,
    left: 0,
    right: 0,
    width: 58,
    height: 'auto',
    margin: '0 auto',
  },
  powered: {
    right: -30,
  },
};

class QrHeader extends Component {
  render() {
    const { tag, fontSize } = this.props;
    const avatar = tag.userProfileImage || '/static/images/avatar/1.jpg';
    const typeFontSize = fontSize || '4vw';

    return (
      // <AppBar sx={{backgroundColor:'#fff'}} position="fixed">
      //   <Toolbar>
      <div style={{ backgroundColor: '#fff', marginBottom: '27px' }}>
        <Grid
          container
          direction={'row'}
          spacing={2}
          alignItems={'center'}
          sx={{ height: '119px', paddingTop: '20px' }}
          justifyContent="center"
        >
          <Grid item>
            <Avatar alt={tag.from} src={avatar} sx={{ marginTop: '20px' }} />
          </Grid>
          <Grid item>
            <Typography
              variant={'h4'}
              sx={{
                fontSize: typeFontSize,
                color: '#0F1F54',
                marginTop: '20px',
              }}
            >
              {tag.from}
            </Typography>
          </Grid>
        </Grid>
      </div>
      //  </Toolbar>
      // </AppBar>
    );
  }
}

export default withStyles(styles)(QrHeader);
