import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import React from 'react';
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';

export function DatePickerComponent(props) {
  const [startDate, setStartDate] = React.useState(
    props.data?.startDate || Date.now()
  );
  const [endDate, setEndDate] = React.useState(
    props.data?.endDate || Date.now()
  );
  const [recurrence, setRecurrence] = React.useState(
    props.data?.recurrence || 'none'
  );
  const { callback } = props;

  const dateChange = (date, type) => {
    if (type === 'start') {
      if (date > endDate) {
        alert('Start date cannot be after end date');
        return;
      }
      setStartDate(date);
    }
    if (type === 'end') {
      if (date < startDate) {
        alert('End date cannot be before start date');
        return;
      }
      setEndDate(date);
    }

    if (type === 'recurrence') {
      setRecurrence(date);
    }

    callback(date, type);
  };

  return (
    <Grid item xs="auto">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container direction={'column'} spacing={3}>
          <Grid item xs>
            <Typography variant={'subtitle2'}>
              Start date for your tag
            </Typography>
            <DateTimePicker
              value={startDate}
              onChange={(date) => dateChange(date, 'start')}
              style={{ width: '50%' }}
            />
          </Grid>

          <Grid item xs>
            <Typography variant={'subtitle2'}>End date for your tag</Typography>
            <DateTimePicker
              value={endDate}
              onChange={(date) => dateChange(date, 'end')}
              style={{ width: '50%' }}
            />
          </Grid>

          <Grid item xs>
            <FormControl style={{ width: '50%' }}>
              <InputLabel>Recurrence</InputLabel>
              <Select
                sx={{ marginTop: '5px' }}
                value={recurrence}
                onChange={(event) =>
                  dateChange(event.target.value, 'recurrence')
                }
              >
                <MenuItem value={'none'}>None</MenuItem>
                <MenuItem value={'daily'}>Daily</MenuItem>
                <MenuItem value={'weekly'}>Weekly</MenuItem>
                <MenuItem value={'monthly'}>Monthly</MenuItem>
                <MenuItem value={'yearly'}>Yearly</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </Grid>
  );
}
