import { SET_NOTIFICATION } from '../actions/notification.actions';

const initState = {};

export const notificationsReducer = (notifications = initState, action) => {
  switch (true) {
    case action.type.includes(SET_NOTIFICATION):
      //console.log('notification action', action);
      return { message: action.payload };

    default:
      return notifications;
  }
};
