import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Button } from '@material-ui/core';

const useStyles = {
  card: {
    width: 250,
    position: 'relative',
    boxShadow: '0 8px 14px 0 rgba(0,0,0,0.5)',
    overflow: 'visible',
    borderRadius: '26px',
    transition: '0.4s',
    marginBottom: '15px',
    color: 'black',
    '&:hover': {
      transform: 'translateY(-7px)',
      '& $shadow': {
        bottom: '-1.5rem',
      },
      '& $shadow2': {
        bottom: '-2.5rem',
      },
    },
    //   '&:before': {
    //     content: '""',
    //     position: 'absolute',
    //     zIndex: 0,
    //     display: 'block',
    //     width: '100%',
    //     bottom: -1,
    //     height: '100%',
    //     borderRadius: '1.5rem',
    //     backgroundColor: 'rgba(252,252,252,1)',
    // },
  },

  myButton: {
    backgroundColor: '#A80000',
    color: '#FFFFFF',
    borderRadius: 29.5,
    fontWeight: 700,
    fontSize: 14,
    fontFamily: 'helvetica',
    marginLeft: '22px',
  },
};

class Show extends Component {
  componentDidMount() {}

  render() {
    const { classes } = this.props;

    return (
      <Card className={classes.card}>
        <CardContent>
          <Button
            variant="contained"
            className={classes.myButton}
            size="medium"
            onClick={() => {
              this.setState({ open: true });
            }}
          >
            Delete
          </Button>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(useStyles)(Show);
