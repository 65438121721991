import {
  SUBSCRIBER,
  FETCH_SUBSCRIBER,
  FETCH_SUBSCRIBERS,
  setSubscribers,
} from './subscriber.actions';
import { setNotification } from '../shared/actions/notification.actions';
import { setLoader } from '../shared/actions/ui.actions';
import {
  API_ERROR,
  API_SUCCESS,
  apiRequest,
} from '../shared/actions/api.actions';

export const subscriberMiddleWare = () => (next) => (action) => {
  next(action);
  switch (action.type) {
    case FETCH_SUBSCRIBERS:
      next(
        apiRequest({
          feature: SUBSCRIBER,
          params: action.payload,
          api: action.api,
          method: action.method,
          endPoint: action.endPoint,
          func: action.function,
        })
      );
      next(setLoader({ state: true, feature: SUBSCRIBER }));
      break;
    case FETCH_SUBSCRIBER:
      next(
        apiRequest({
          feature: SUBSCRIBER,
          params: action.payload,
          api: action.api,
          method: action.method,
          endPoint: action.endPoint,
          func: action.function,
        })
      );
      next(setLoader({ state: true, feature: SUBSCRIBER }));
      break;
    case `${SUBSCRIBER} ${API_SUCCESS}`:
      //if (action.actionType == 'getSubscribers') {
      next(setSubscribers({ subscribers: action.payload }));
      next(setLoader({ state: false, feature: SUBSCRIBER }));
      //}
      //next(setLoader({ state: false, feature: SUBSCRIBER }));
      break;
    case `${API_ERROR}`:
      //console.log("api error = ", action.payload);
      next(
        setNotification({
          message: action.payload.message,
          feature: SUBSCRIBER,
        })
      );
      next(setLoader({ state: false, feature: SUBSCRIBER }));
      break;
    default:
      break;
  }
};
