import { Parse } from 'parse';
import DOMPurify from 'dompurify';
import * as pdfjsLib from 'pdfjs-dist/webpack';
//import html2canvas from 'html2canvas';

//import axios from 'axios';

export const htmlRegEx =
  /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9$\-_.+!*'(),;?&=]|(?:%[a-fA-F0-9]{2})){1,64}(?::(?:[a-zA-Z0-9$\-_.+!*'(),;?&=]|(?:%[a-fA-F0-9]{2})){1,25})?@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9-]{0,64}\.)+(?:(?:aero|app|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?::\d{1,5})?)(\/(?:(?:[a-zA-Z0-9;/?:@&=#~\-.+!*'(),_])|(?:%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;

export const detectIframeAbility = async (url) => {
  try {
    let iframeAble = await Parse.Cloud.run('detectIframeAbility', { url: url });
    return iframeAble;
  } catch (e) {
    console.log('this is the error ', e);
    return false;
  }
};

export const setUpPdfRendering = async () => {
  const pdfElements = window.document.querySelectorAll('.data-pdf-url'); // Look for elements with class 'pdf'
  const pdfMap = new Map(); // Map to store PDFs by URL
  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const pageNum = parseInt(entry.target.getAttribute('data-page-num'));
          const pdfUrl = entry.target.getAttribute('data-pdf-url');

          const pdf = pdfMap.get(pdfUrl); // R

          // Only render the page if it hasn't been rendered before
          if (pdf && !entry.target.getAttribute('data-rendered')) {
            pdf.getPage(pageNum).then((page) => {
              const viewport = page.getViewport({ scale: 1.0 });
              const canvas = entry.target.querySelector('canvas');
              canvas.setAttribute('class', 'responsive-canvas');
              const context = canvas.getContext('2d');

              canvas.height = viewport.height;
              canvas.width = viewport.width;

              // Render the page
              const renderContext = {
                canvasContext: context,
                viewport: viewport,
              };

              page.render(renderContext);
            });

            entry.target.setAttribute('data-rendered', 'true');
          }

          observer.unobserve(entry.target);
        }
      });
    },
    { rootMargin: '0px 0px 100px 0px' }
  ); // Load pages when they are within 100px of the viewport

  for (const element of pdfElements) {
    const pdfUrl = element.getAttribute('src');
    //const myPdf = pdfMap.get(pdfUrl);

    // if (myPdf) {
    // 	console.log('the map already exists');
    // } else {// Assuming the PDF URL is stored in a 'data-pdf-url' attribute
    const container = window.document.createElement('div');
    const loadingIndicator = window.document.createElement('p');
    loadingIndicator.innerText = 'Loading PDF...';
    container.appendChild(loadingIndicator);

    element.parentNode.replaceChild(container, element);

    // Use PDF.js to load and render the PDF
    const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
    const numPages = pdf.numPages;

    container.removeChild(loadingIndicator);
    pdfMap.set(pdfUrl, pdf); // Store the PDF in the Map

    for (let pageNum = 1; pageNum <= numPages; pageNum++) {
      const pageContainer = window.document.createElement('div');
      pageContainer.setAttribute('data-page-num', pageNum);
      pageContainer.setAttribute('data-pdf-url', pdfUrl);
      const canvas = window.document.createElement('canvas');
      pageContainer.appendChild(canvas);
      container.appendChild(pageContainer);

      observer.observe(pageContainer);
    }
  }
  //}
};

export const getOrdinalSuffix = (n) => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const createMarkup = (html) => {
  const parser = new DOMParser();
  const dom = parser.parseFromString(html, 'text/html');
  let scriptTag;
  const trackingPixelElements = dom.querySelectorAll('[data-tracking-pixel]');

  trackingPixelElements.forEach((element) => {
    const trackingPixelData = JSON.parse(
      element.getAttribute('data-tracking-pixel')
    );

    switch (trackingPixelData.type) {
      case 'facebook':
        scriptTag = window.document.createElement('script');
        scriptTag.innerHTML = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '${trackingPixelData.pixelId}');fbq('track', 'PageView');`;
        break;
      case 'google':
        // Generate the Google Analytics script tag
        scriptTag = window.document.createElement('script');
        scriptTag.innerHTML = scriptTag.innerHTML = `
				(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
				(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
				m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
				})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
			  
				ga('create', '${trackingPixelData.pixelId}', 'auto');
				ga('send', 'pageview');
			  `;
        break;
      case 'twitter':
        // Generate the Twitter script tag
        scriptTag = window.document.createElement('script');
        scriptTag.innerHTML = `!function (e, t, n, s, u, a) {
					e.twq || (s = e.twq = function () { s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments); },
					s.version = '1.1', s.queue = [], u = t.createElement(n), u.async = !0, u.src = 'https://static.ads-twitter.com/uwt.js',
					a = t.getElementsByTagName(n)[0], a.parentNode.insertBefore(u, a))
				}(window, document, 'script');
				twq('init', '${trackingPixelData.pixelId}');
				twq('track', 'PageView');`;
        break;

      case 'hubspot':
        // Generate the HubSpot script tag
        scriptTag = window.document.createElement('script');
        scriptTag.innerHTML = `!function(){var t=window.hs=window.hs||{};t._hsp=t._hsp||[];t._hsp.push(["addPrivacyConsentListener",function(e){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src="https://js.hs-scripts.com/${trackingPixelData.pixelId}.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n)}])}();`;
        break;

      default:
        console.log('no tracking pixel');
    }

    if (scriptTag) {
      window.document.head.appendChild(scriptTag);
    }
  });

  checkForInstagram(dom);

  return {
    __html: DOMPurify.sanitize(html, {
      FORCE_BODY: true,
      ADD_TAGS: ['iframe', 'script'],
      ADD_ATTR: ['href'],
      ALLOWED_URI_REGEXP:
        /^(?:(?:(?:https?|ftp):|mailto:|tel:|file:|data:)|[^a-z]|[a-z+.-]+(?:[^a-z+.-:]|$))/i,
    }),
  };
};

export const isIOSAndVersionAtLeast = (versionThreshold) => {
  const ua = navigator.userAgent;
  const ios = /iPad|iPhone|iPod/.test(ua);
  const macos =
    navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;

  if (!ios && !macos) return false; // Not iOS or iPadOS

  const match = ua.match(/OS (\d+)_(\d+)_?(\d+)?/);
  if (!match) return false; // iOS version not found

  const major = parseInt(match[1], 10);
  const minor = parseFloat(`${match[2]}.${match[3] || 0}`);
  const version = major + minor / 10;

  return version >= versionThreshold;
};

export const checkForInstagram = (dom) => {
  const instagram = dom.querySelectorAll('blockquote.instagram-media');
  let scriptTag;

  instagram.forEach(() => {
    scriptTag = window.document.createElement('script');
    scriptTag.setAttribute('async', '');
    scriptTag.setAttribute('src', '//www.instagram.com/embed.js');
    window.document.head.appendChild(scriptTag);
  });
};

export const extractImageAndIframe = async (html, params) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  //const img = doc.querySelector('img');
  const iframe = doc.querySelector('iframe');

  // const tempContainer = document.createElement('div');
  // // tempContainer.style.position = 'absolute';
  // // tempContainer.style.left = '0px';
  // // tempContainer.style.visibility = 'hidden';
  // tempContainer.innerHTML = html;
  // document.body.appendChild(tempContainer);

  // console.log('tem container ', tempContainer.innerHTML);
  // await new Promise((resolve) => setTimeout(resolve, 5000));

  // // Use html2canvas to render the container to an image
  // const canvas = await html2canvas(tempContainer, {
  //   useCORS: true, // Ensures cross-origin images are loaded
  //   logging: true,
  //   allowTaint: true,
  //   scale: 2, // for higher resolution
  // });
  // console.log('canvas', canvas);
  // const imageBlob = await new Promise((resolve) =>
  //   canvas.toBlob(resolve, 'image/jpeg')
  // );

  // // Clean up the temporary container
  // document.body.removeChild(tempContainer);

  // const file = new File([imageBlob], 'image.jpg', { type: 'image/jpeg' });

  // params.image = [file];

  // if (img) {
  //   try {
  //     const { data } = await axios.get(img.src, { responseType: 'blob' });
  //     const file = new File([data], 'image.jpg', { type: 'image/jpeg' });
  //     params.image = [file];
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  if (iframe) {
    const src = iframe.getAttribute('src');
    params.hasWeb = true;
    params.webUrl = [src];
    if (src) {
      try {
        console.log('this is the iframe src', src);
      } catch (error) {
        console.error(error);
      }
    }
  }
  return params;
};
