import React, { Component } from 'react';
import AnalyticChart from '../ui/chart.component';
import SearchMap from '../ui/maps.component';
import { Typography, Grid, Card, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  header: {
    fontWeight: 'bold',
    margin: '10px 0',
  },
  paragraph: {
    marginBottom: 20,
  },
};

class Index extends Component {
  constructor(props) {
    super(props);

    this.currentState = {
      latitude: '',
      longitude: '',
      amountOfMinutes: 1440 * 7,
    };

    //console.log('current state 1: ', this.currentState)
  }

  updateState(updatedState) {
    Object.keys(updatedState).forEach((item) => {
      this.currentState[item] = updatedState[item];
    });
    this.forceUpdate();
  }

  componentDidMount() {
    this.props.getTags();
    if ('geolocation' in navigator) {
      //console.log("Geolocation Available");
    } else {
      //console.log("geolocation Not Available");
    }

    var analyticParam = {
      latitude: ``,
      longitude: ``,
      amountOfMinutes: 1440 * 7,
    };

    navigator.geolocation.getCurrentPosition(function (position) {
      analyticParam.latitude = position.coords.latitude;
      analyticParam.longitude = position.coords.longitude;
    });

    this.props.getAnalytics(analyticParam);
    this.props.getImpressions({ func: 'getImpressions' });
  }

  setCoordinates = (data) => {
    this.updateState({
      latitude: data.latitude,
      longitude: data.longitude,
    });
    this.props.getAnalytics(this.currentState);
    this.props.getImpressions({ func: 'getImpressions' });
    //console.log("new state: ", this.currentState)
  };

  setTagMenuIsOpen(isOpen) {
    this.tagMenuIsOpen = isOpen;
  }

  renderChart(ChartTitle, ChartType, DataArray, xTitle, yTitle) {
    return (
      <AnalyticChart
        ChartTitle={ChartTitle}
        ChartType={ChartType}
        DataArray={DataArray}
        xTitle={xTitle}
        yTitle={yTitle}
      />
    );
  }

  render() {
    //const {classes} = this.props;
    //  const {tagMenuIsOpen} = false;//React.useState(false);
    //  const {tagMenuIsOpen} = React.useState(false);
    //  const {setTagMenuIsOpen}  = React.useState(false);

    return (
      <div>
        <Typography variant="h5" className="mb-3">
          Analytics
        </Typography>
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <Card>
              <CardContent>
                <Typography varient="h3" className="mb-3">
                  Location
                </Typography>
                <SearchMap
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places `}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={
                    <div
                      style={{
                        height: `250px`,
                        width: `100%`,
                        marginBottom: `30px`,
                      }}
                    />
                  }
                  mapElement={<div style={{ height: `100%` }} />}
                  callback={this.setCoordinates}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={6}>
            <Card>
              <CardContent>
                {this.renderChart(
                  'Total Tags',
                  'ColumnChart',
                  this.props.tags,
                  '',
                  'Tags'
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item sm={6}>
            <Card>
              <CardContent>
                {this.renderChart(
                  'Total Impressions',
                  'BarChart',
                  this.props.impressions,
                  'Impressions',
                  ''
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item sm={6}>
            <Card>
              <CardContent>
                {this.renderChart(
                  'How Many People In Your Area',
                  'LineChart',
                  this.props.analytics,
                  '',
                  'Users'
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item sm={6}>
            <Card>
              <CardContent>
                {this.renderChart(
                  'Return On Investment',
                  'PieChart',
                  null,
                  '',
                  ''
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Index);
