// feature name
export const STRIPE = '[STRIPE]';
// action types
export const FETCH_STRIPE = `${STRIPE} FETCH_STRIPE`;
export const SET_STRIPE = `${STRIPE} SET_STRIPE`;

export const fetchCheckoutSession = (query) => ({
  type: FETCH_STRIPE,
  payload: query,
  function: 'createStripeSession',
});

export const setStripe = (stripe) => ({
  type: SET_STRIPE,
  payload: stripe,
});
