import { connect } from 'react-redux';
import Index from './help.index';
import {
  createHelp,
  deleteHelp,
  fetchHelp,
  showHelp,
  updateHelp,
} from './help.actions';

const mapStateToProps = (state) => ({
  faqs: state.help.data,
  location: state.history,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  all: () => dispatch(fetchHelp()),
  remove: (query) => {
    query.index = ownProps.index;
    dispatch(deleteHelp({ query }));
  },
  create: (query) => {
    dispatch(createHelp({ query }));
  },

  update: (query) => dispatch(updateHelp({ query })),

  show: (query) => dispatch(showHelp({ query })),
});

const helpConnector = connect(mapStateToProps, mapDispatchToProps);

export const All = helpConnector(Index);
//export const Create = helpConnector(New);
//export const Update = helpConnector(Edit);
//export const Get = helpConnector(Show);
//export const Delete = helpConnector(Remove);
