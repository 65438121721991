import {
  USERCONNECTOR,
  FETCH_USERCONNECTOR,
  FETCH_USERCONNECTORS,
  setUserConnector,
} from './userconnector.actions';
import { setNotification } from '../shared/actions/notification.actions';
import { setLoader } from '../shared/actions/ui.actions';
import {
  API_ERROR,
  API_SUCCESS,
  apiRequest,
} from '../shared/actions/api.actions';
//import { showSnack, dismissSnack } from 'react-redux-snackbar';
//import {reject} from 'ramda'

export const userConnectorMiddleWare = () => (next) => (action) => {
  next(action);

  switch (action.type) {
    case FETCH_USERCONNECTORS:
      ////console.log('FETCH_USERCONNECTOR is the action', action.payload);
      next(
        apiRequest({
          feature: USERCONNECTOR,
          params: action.payload,
          api: action.api,
          method: action.method,
          endPoint: action.endPoint,
          func: action.function,
        })
      );
      next(setLoader({ state: true, feature: USERCONNECTOR }));
      break;
    case FETCH_USERCONNECTOR:
      ////console.log('FETCH_USERCONNECTOR is the action', action.payload);
      next(
        apiRequest({
          feature: USERCONNECTOR,
          params: action.payload,
          api: action.api,
          method: action.method,
          endPoint: action.endPoint,
          func: action.function,
        })
      );
      next(setLoader({ state: true, feature: USERCONNECTOR }));
      break;
    case `${USERCONNECTOR} ${API_SUCCESS}`:
      //if (action.actionType == "getUserConnectors") {
      ////console.log('FETCH_USERCONNECTOR is the action', action.payload);
      next(setUserConnector({ userConnectors: action.payload }));
      next(setLoader({ state: false, feature: USERCONNECTOR }));
      //}
      next(setLoader({ state: false, feature: USERCONNECTOR }));
      break;
    case `${USERCONNECTOR} ${API_ERROR}`:
      next(
        setNotification({
          message: action?.payload?.message,
          feature: USERCONNECTOR,
        })
      );
      next(setLoader({ state: false, feature: USERCONNECTOR }));
      break;
    default:
      break;
  }
};
