import React, { Component } from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { IconButton, Hidden, ListItemText } from '@material-ui/core';
import {
  Drawer,
  Typography,
  ListItemIcon,
  List,
  ListItemButton,
} from '@mui/material';
import { items as mainListItems } from './mainListItems';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@mui/material';
import { withRouter } from 'react-router-dom';

// const handleDrawerToggle = () => {return false}
// const mobileOpen = () => {return false}

const styles = () => ({
  icon: {
    color: '#DD0B06',
    width: '16px',
    height: '16px',
  },
});

class Navigate extends Component {
  render() {
    const {
      classes,
      sentInClasses,
      theme,
      history,
      mobileOpen,
      handleDrawerToggle,
    } = this.props;

    return (
      <nav className={sentInClasses.drawer}>
        {/* Drawer */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            sx={theme.custom.drawerPaperClosed}
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            open={mobileOpen}
            onClose={handleDrawerToggle}
          >
            <div className={sentInClasses.toolbarIcon}>
              <IconButton onClick={handleDrawerToggle}>
                <ChevronLeftIcon />
              </IconButton>
            </div>

            {/* Logo */}
            <div className="d-flex justify-content-center">
              <Grid
                container
                direction={'row'}
                style={{ marginTop: '10px', marginBottom: '20px' }}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Grid item xs={'auto'}>
                  <img
                    alt="LifeTaggerLogo"
                    style={theme.custom.logo}
                    src="/images/lifeTagger_logo.png"
                  />
                </Grid>
                <Grid item xs={'auto'}>
                  {!mobileOpen && (
                    <Typography style={theme.custom.typography.main}>
                      LifeTagger
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </div>

            {/* Plus button */}

            {/* Menu items */}
            <List>
              {mainListItems.map((item) => (
                <ListItemButton
                  onClick={() => {
                    history.push(item.href);
                  }}
                  key={item.text}
                >
                  <ListItemIcon className={classes.icon}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2">{item.text}</Typography>
                    }
                  />
                </ListItemButton>
              ))}
            </List>
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer sx={theme.custom.drawerPaper} variant="permanent" open>
            <div>
              {/* Logo */}
              <Grid
                container
                direction={'row'}
                style={{ marginTop: '10px', marginBottom: '20px' }}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Grid item xs={'auto'}>
                  <img
                    alt="LifeTaggerLogo"
                    style={theme.custom.logo}
                    src="/images/lifeTagger_logo.png"
                  />
                </Grid>
                <Grid item xs={'auto'}>
                  {!mobileOpen && (
                    <Typography style={theme.custom.typography.main}>
                      LifeTagger
                    </Typography>
                  )}
                </Grid>
              </Grid>

              {/* Plus button */}

              {/* Menu items */}
              <List component="nav" sx={theme.custom.listItem}>
                {mainListItems.map((item) => (
                  <ListItemButton
                    onClick={() => {
                      history.push(item.href);
                    }}
                    key={item.text}
                  >
                    <ListItemIcon className={classes.icon}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle2">{item.text}</Typography>
                      }
                    />
                  </ListItemButton>
                ))}
              </List>
            </div>
          </Drawer>
        </Hidden>
      </nav>
    );
  }
}

export default withStyles(styles)(withRouter(Navigate));
