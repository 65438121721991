import { SET_STRIPE } from './stripe.actions';

const initState = {};

export const stripeReducer = (stripe = initState, action) => {
  switch (action.type) {
    case SET_STRIPE:
      return {
        ...stripe,
        data: action.payload?.stripe,
      };
    default:
      return stripe;
  }
};
