import {
  ANALYTIC,
  IMPRESSION,
  FETCH_ANALYTIC,
  setAnalytic,
  FETCH_IMPRESSION,
  setImpression,
} from './analytic.actions';
import { setNotification } from '../shared/actions/notification.actions';
import { setLoader } from '../shared/actions/ui.actions';
import {
  API_ERROR,
  API_SUCCESS,
  apiRequest,
} from '../shared/actions/api.actions';
//import { showSnack, dismissSnack } from 'react-redux-snackbar';
//import {reject} from 'ramda'
export const analyticMiddleWare = () => (next) => (action) => {
  next(action);

  switch (action.type) {
    case FETCH_ANALYTIC:
      ////console.log('FETCH_ANALYTIC is the action', action.payload);
      next(
        apiRequest({
          feature: ANALYTIC,
          params: action.payload,
          func: action.function,
        })
      );
      next(setLoader({ state: true, feature: ANALYTIC }));
      break;
    case FETCH_IMPRESSION:
      //////console.log('FETCH_IMPRESSION is the action', action.payload);
      next(
        apiRequest({
          feature: IMPRESSION,
          params: action.payload,
          func: action.function,
        })
      );
      //next(setLoader({ state: true, feature: IMPRESSION }));
      break;
    case `${ANALYTIC} ${API_SUCCESS}`:
      //if (action.actionType == "getAnalytic")
      next(setAnalytic({ analytic: action.payload }));
      next(setLoader({ state: false, feature: ANALYTIC }));
      break;
    case `${IMPRESSION} ${API_SUCCESS}`:
      //if (action.actionType == "getImpressions")
      next(setImpression({ impression: action.payload }));
      next(setLoader({ state: false, feature: IMPRESSION }));
      break;
    case `${API_ERROR}`:
      //console.log("api error = ", action.payload);
      next(
        setNotification({ message: action.payload.message, feature: ANALYTIC })
      );
      next(setLoader({ state: false, feature: ANALYTIC }));
      break;
    default:
      break;
  }
};
