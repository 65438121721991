import { Grid } from '@mui/material';
import React, { Component } from 'react';

class IPhoneComponent extends Component {
  render() {
    return (
      <div
        className="marvel-device iphone-x scale--01"
        style={{ width: '312.15px', height: '676.6px' }}
      >
        {/*<div className="notch">*/}
        {/*    <div className="camera"/>*/}
        {/*    <div className="speaker"/>*/}
        {/*</div>*/}
        <div className="top-bar" />
        <div className="sleep" />
        <div className="bottom-bar" />
        <div className="volume" />
        <div className="overflow">
          <div className="shadow shadow--tr" />
          <div className="shadow shadow--tl" />
          <div className="shadow shadow--br" />
          <div className="shadow shadow--bl" />
        </div>
        <div className="inner-shadow" />
        <div
          className="screen"
          style={{
            overflowY: 'scroll',
            paddingLeft: '5px',
            paddingRight: '5px',
          }}
        >
          <Grid container>{this.props.screen}</Grid>
        </div>
      </div>
    );
  }
}

export default IPhoneComponent;
