import { SET_MEDIA, DELETE_MEDIA, CREATE_MEDIA } from './media.actions';
import * as R from 'ramda';

const initState = { data: [] };

const remove = (medias, media) => {
  const index = R.findIndex(R.propEq('objectId', media.objectId))(medias);

  if (index >= 0) {
    return [
      ...medias.slice(0, index),
      ...medias.slice(index + 1, medias.length),
    ];
  }

  return [...medias];
};

const upsert = (medias, media) => {
  return [...remove(medias, media), media];
};

export const mediaReducer = (media = initState, action) => {
  let newMedia = media.data.slice();
  let oldMedia = media.data;

  switch (action.type) {
    case SET_MEDIA:
      return {
        ...media,
        data: action.payload,
      };

    case DELETE_MEDIA:
      //console.log('action in remove media', action)
      newMedia.splice(action.index, 1);
      return {
        ...media,
        data: newMedia,
      };

    case CREATE_MEDIA:
      return {
        ...media,
        data: upsert(oldMedia, action.payload),
      };

    default:
      return media;
  }
};
