import {
  BEACONS,
  FETCH_BEACONS,
  DELETE_BEACON,
  CREATE_BEACON,
  UPDATE_BEACON,
  updateBeacon,
  setBeacons,
  createBeacon,
  FETCH_LIFECODES,
} from './beacon.actions';
import { setNotification } from '../shared/actions/notification.actions';
import { setLoader } from '../shared/actions/ui.actions';
import {
  API_ERROR,
  API_SUCCESS,
  apiRequest,
} from '../shared/actions/api.actions';
//import { showSnack, dismissSnack } from 'react-redux-snackbar';
//import {reject} from 'ramda'

export const beaconMiddleWare = () => (next) => (action) => {
  next(action);

  switch (action.type) {
    case FETCH_BEACONS:
      next(
        apiRequest({
          feature: BEACONS,
          params: action.payload,
          func: action.function,
        })
      );
      next(setLoader({ state: true, feature: BEACONS }));
      break;

    case FETCH_LIFECODES:
      next(
        apiRequest({
          feature: BEACONS,
          params: action.payload,
          func: action.function,
        })
      );
      next(setLoader({ state: true, feature: BEACONS }));
      break;

    case DELETE_BEACON:
      next(
        apiRequest({
          feature: BEACONS,
          params: action.payload,
          func: 'deleteBeacons',
        })
      );
      break;

    case CREATE_BEACON:
      next(createBeacon({ index: action.index }));
      next(
        apiRequest({
          body: action.data,
          feature: BEACONS,
          params: action.payload,
          func: action.function,
        })
      );
      next(setLoader({ state: true, feature: BEACONS }));
      break;

    case UPDATE_BEACON:
      next(updateBeacon({ index: action.index }));
      next(
        apiRequest({
          body: action.data,
          feature: BEACONS,
          params: action.payload,
          func: action.function,
        })
      );
      break;

    case `${BEACONS} ${API_SUCCESS}`:
      if (action.actionType === 'createBeacon') {
        next(setBeacons({ beacons: action.payload, feature: BEACONS }));
        next(setLoader({ state: false, feature: BEACONS }));
        next(
          setNotification({ message: 'Created Successfully', feature: BEACONS })
        );
      } else if (action.actionType === 'deleteBeacons') {
        next(setBeacons({ beacons: action.payload, feature: BEACONS }));
        next(
          setNotification({ message: 'Successfully Deleted', feature: BEACONS })
        );
      } else {
        next(setBeacons({ beacons: action.payload, feature: BEACONS }));
        next(setLoader({ state: false, feature: BEACONS }));
      }

      //console.log("action on beacon api cll", action);
      // next(setBeacons({ beacons: action.payload }));
      // next(setLoader({ state: false, feature: BEACONS }));
      break;

    case `${BEACONS} ${API_ERROR}`:
      next(
        setNotification({ message: action.payload.message, feature: BEACONS })
      );
      next(setLoader({ state: false, feature: BEACONS }));
      break;

    default:
      break;
  }
};
