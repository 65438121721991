import React, { Component } from 'react';
import { Grid } from '@mui/material';
import { Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ShowQr } from './beacon.container';
import AddIcon from '@material-ui/icons/Add';

const styles = {
  header: {
    fontFamily: 'Heebo',
    fontSize: '24px',
    marginLeft: '20px',
    fontWeight: 'bold',
    color: '#0F152A',
  },
  createButton: {
    backgroundColor: '#A80000',
    color: '#FFFFFF',
    borderRadius: 29.5,
    fontWeight: 700,
    fontSize: 10,
    fontFamily: 'helvetica',
    marginLeft: '22px',
    marginBottom: 18,
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
};

class Index extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.blueToothBeacons();
  }

  render() {
    const { classes, history } = this.props;

    return (
      <div>
        <Grid container direction={'column'}>
          <Grid item xs={12}>
            <Grid
              container
              direction={'row'}
              sx={{ justifyContent: 'space-between' }}
            >
              <Grid item xs={2}>
                <Typography className={classes.header}>Beacons</Typography>
              </Grid>
              <Grid item xs={2}>
                <Button
                  className={classes.createButton}
                  onClick={() => history.push('/beacon/create')}
                >
                  <AddIcon />
                  New Beacon
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/*<Grid item xs={12}>*/}
          <Grid container direction={'row'} item xs={12} spacing={3}>
            {this.props.blueBeacons && this.props.blueBeacons.length > 0 ? (
              this.props.blueBeacons.map((beacon, key) => {
                return (
                  <Grid
                    item
                    xs={4}
                    key={key}
                    sx={{ marginRight: '15px' }}
                    onClick={() =>
                      history.push('/qrcode/details/' + beacon.beaconId)
                    }
                  >
                    <ShowQr qrCode={beacon} />
                  </Grid>
                );
              })
            ) : (
              <Typography>No Beacons to show</Typography>
            )}
            {/*</Grid>*/}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Index);
