import React, { useState } from 'react';
import { FormControl, FormControlLabel, Grid, Typography } from '@mui/material';
//import {useTheme} from "@material-ui/core/styles";
//import {useDispatch, useSelector} from "react-redux";
import { Button, FormHelperText, TextField } from '@material-ui/core';
import CountryComponent from '../ui/countryComponent';
import { isEmpty } from 'ramda';
import { Parse } from 'parse';
//import {loadStripe} from "@stripe/stripe-js";
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCheckoutSession } from '../stripe/stripe.actions';

//const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

function UserNewLifeTap() {
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const dispatch = useDispatch();
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [accountPlan] = useState('price_1MMDDhG7JJ7XmqTHdaKpLyAp');
  const [company, setCompany] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');
  const [numberOfTaps, setNumberOfTaps] = useState('');
  const [userName, setUserName] = useState('');
  const [emailError, setEmailError] = useState(false);

  let stripeSession = useSelector((state) => state.stripe.stripe);

  // useEffect(() => {
  // 	console.log('about to get the checkout session')
  // 	let query = {accountPlan, userName, email}
  // 	dispatch(fetchCheckoutSession(query));
  // }, [dispatch])

  const handleEmail = (event) => {
    if (event.target.value.includes('@') && event.target.value.includes('.')) {
      setEmailError(false);
      setEmail(event.target.value);
    } else {
      setEmailError(true);
    }
    let query = { accountPlan, userName, email };
    dispatch(fetchCheckoutSession(query));
  };

  const shouldBeDisabled = () => {
    return (
      isEmpty(passwordConfirmation) ||
      isEmpty(password) ||
      isEmpty(email) ||
      isEmpty(name) ||
      isEmpty(company) ||
      isEmpty(address) ||
      isEmpty(city) ||
      isEmpty(state) ||
      isEmpty(zip) ||
      isEmpty(country) ||
      isEmpty(numberOfTaps) ||
      isEmpty(userName) ||
      password != passwordConfirmation
    );
  };

  const handleSubmit = async () => {
    const user = new Parse.User();
    user.set('email', email);
    user.set('name', name);
    user.set('accountPlan', accountPlan);
    user.set('company', company);
    user.set('address', address);
    user.set('city', city);
    user.set('state', state);
    user.set('zip', zip);
    user.set('country', country);
    user.set('phone', phone);
    user.set('numberOfTaps', numberOfTaps);
    user.set('username', userName);
    user.set('password', password);

    try {
      let newUser = await user.save();
      if (newUser) {
        //const sessionId = stripeSession.id;
        // When the customer clicks on the button, redirect them to Checkout.
        //const stripe = await stripePromise;
        // await stripe.redirectToCheckout({
        // 	sessionId,
        // });
        window.location.href = stripeSession.url;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleCountrySelected = (value) => {
    setCountry(value);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems={'center'}
      direction={'row'}
    >
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Paper elevation={3} sx={{ padding: 3 }}>
          <Grid item>
            <Typography variant="h5" gutterBottom>
              LifeTap Registration
            </Typography>
          </Grid>
          <FormControl>
            <Grid container justify="center" alignItems="center">
              <TextField
                required
                id="Name"
                label="Name: "
                fullWidth
                variant="outlined"
                defaultValue=""
                margin="normal"
                onChange={(event) => setName(event.target.value)}
              />
              <Grid item container direction="row" spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    required
                    id="userName"
                    variant="outlined"
                    label="Username: "
                    fullWidth
                    defaultValue=""
                    margin="normal"
                    onChange={(event) => {
                      setUserName(event.target.value);
                      let query = { accountPlan, userName, email };
                      dispatch(fetchCheckoutSession(query));
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    required
                    id="email"
                    label="Email: "
                    variant={'outlined'}
                    fullWidth
                    defaultValue=""
                    margin="normal"
                    onChange={handleEmail}
                    error={emailError}
                  />
                  {emailError && (
                    <FormHelperText
                      error={emailError}
                      style={
                        emailError
                          ? { visibility: 'visible' }
                          : { visibility: 'hidden' }
                      }
                    >
                      The email you entered is invalid
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid item container direction="row" spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    required
                    id="password"
                    label="Password: "
                    type="password"
                    fullWidth
                    minRows="3"
                    defaultValue=""
                    margin="normal"
                    variant="outlined"
                    error={
                      password != passwordConfirmation || isEmpty(password)
                    }
                    name="password"
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    id="password"
                    label="Password Confirmation: "
                    type="password"
                    fullWidth
                    rows="3"
                    defaultValue=""
                    margin="normal"
                    variant="outlined"
                    error={
                      password != passwordConfirmation ||
                      isEmpty(passwordConfirmation)
                    }
                    name="passwordConfirmation"
                    onChange={(event) =>
                      setPasswordConfirmation(event.target.value)
                    }
                  />
                  {password != passwordConfirmation && (
                    <FormHelperText error={password != passwordConfirmation}>
                      The passwords do not match
                    </FormHelperText>
                  )}
                  {!isEmpty(password) && (
                    <FormHelperText
                      error={isEmpty(password)}
                      style={
                        isEmpty(password)
                          ? { visibility: 'visible' }
                          : { visibility: 'hidden' }
                      }
                    >
                      Please enter a password
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid item container direction="row" spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    required
                    id="company"
                    label="Company: "
                    variant="outlined"
                    defaultValue=""
                    margin="normal"
                    onChange={(event) => setCompany(event.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    id="phone"
                    label="Phone Number: "
                    variant="outlined"
                    defaultValue=""
                    margin="normal"
                    onChange={(event) => setPhone(event.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="row" spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    required
                    id="address"
                    label="Address: "
                    variant={'outlined'}
                    defaultValue=""
                    margin="normal"
                    onChange={(event) => setAddress(event.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    id="city"
                    label="City: "
                    variant={'outlined'}
                    defaultValue=""
                    margin="normal"
                    onChange={(event) => setCity(event.target.value || '')}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="row" spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    required
                    id="state"
                    label="State: "
                    variant={'outlined'}
                    defaultValue=""
                    margin="normal"
                    onChange={(event) => setState(event.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    id="zip"
                    label="Zip Code: "
                    variant={'outlined'}
                    defaultValue=""
                    margin="normal"
                    onChange={(event) => setZip(event.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    control={
                      <CountryComponent
                        handleCountrySelected={handleCountrySelected}
                      ></CountryComponent>
                    }
                    label="Country"
                    labelPlacement={'bottom'}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={'Number of LifeTaps needed: '}
                  type={'number'}
                  variant={'outlined'}
                  defaultValue=""
                  margin="normal"
                  onChange={(event) => setNumberOfTaps(event.target.value)}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  disabled={shouldBeDisabled()}
                  onClick={() => handleSubmit()}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </FormControl>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default UserNewLifeTap;
