export const USERCONNECTOR = '[USERCONNECTOR]';

// action types
export const FETCH_USERCONNECTOR = `${USERCONNECTOR} FETCH`;
export const SET_USERCONNECTOR = `${USERCONNECTOR} SET`;
export const FETCH_USERCONNECTORS = `${USERCONNECTOR} FETCH ALL`;

export const fetchUserConnectors = () => ({
  type: FETCH_USERCONNECTORS,
  method: 'GET',
  api: 'kinects',
  endPoint: 'userconnector',
  function: 'getUserConnectors',
});

export const fetchUserConnector = (id) => ({
  type: FETCH_USERCONNECTOR,
  method: 'GET',
  api: 'kinects',
  endPoint: `userconnector/${id}`,
  function: 'getUserConnector',
});

export const setUserConnector = ({ userConnectors }) => ({
  type: SET_USERCONNECTOR,
  payload: userConnectors,
});
