// feature name
export const PRODUCT = '[PRODUCT]';
// action types
export const FETCH_PRODUCT = `${PRODUCT} FETCH_PRODUCT`;
export const SET_PRODUCT = `${PRODUCT} SET_PRODUCT`;
export const SHOW_PRODUCT = `${PRODUCT} SHOW_PRODUCT`;

export const getProducts = () => ({
  type: FETCH_PRODUCT,
  payload: {},
  function: 'getProducts',
});

export const setProducts = (product) => ({
  type: SET_PRODUCT,
  payload: product,
});

export const showProducts = (product) => ({
  type: SHOW_PRODUCT,
  payload: product.data,
});
