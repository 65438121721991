import { connect } from 'react-redux';
import App from '../App';
import { push } from 'connected-react-router';

const mapStateToProps = (state) => ({
  route: state.route,
  session: state.session,
  notification: state.notification.message,
});

const mapDispatchToProps = (dispatch) => ({
  goToRoute: (url) => dispatch(push(url)),
});

const appConnector = connect(mapStateToProps, mapDispatchToProps);

export const AppContainer = appConnector(App);
