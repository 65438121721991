import { createMuiTheme } from '@material-ui/core/styles';
import '@fontsource/heebo';
import '@fontsource/roboto';
import { createTheme } from '@mui/material/styles';

export const toggledTheme = (mode) =>
  createTheme({
    components: {
      ...(mode === 'light'
        ? {
            MuiDrawer: {
              styleOverrides: {
                paper: {
                  backgroundColor: '#F7F9FF',
                  width: '224px',
                  borderColor: '#F0F4FF',
                },
              },
            },
            MuiListItemIcon: {
              styleOverrides: {
                root: {
                  color: '#DD0B06',
                },
              },
            },
            MuiStepper: {
              styleOverrides: {
                paper: {
                  backgroundColor: '#F0F4FF',
                },
              },
            },
          }
        : {
            MuiDrawer: {
              styleOverrides: {
                paper: {
                  backgroundColor: '#06123E',
                  width: '224px',
                  borderColor: '#0F1F54',
                },
              },
            },
          }),
    },
    typography: {
      h1: {
        fontFamily: 'SatoshiVariable',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '64px',
        lineHeight: '80px',
        color: mode === 'light' ? '#0F1f54' : '#fff',
      },
      h2: {
        fontFamily: 'SatoshiVariable',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '48px',
        lineHeight: '64px',
        color: mode === 'light' ? '#0F1f54' : '#fff',
      },
      h3: {
        fontFamily: 'SatoshiVariable',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '32px',
        lineHeight: '48px',
        color: mode === 'light' ? '#0F1f54' : '#fff',
      },
      h4: {
        fontFamily: 'SatoshiVariable',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '32px',
        color: mode === 'light' ? '#0F1f54' : '#fff',
      },
      h5: {
        fontFamily: 'SatoshiVariable',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '30px',
        color: mode === 'light' ? '#0F1f54' : '#fff',
      },
      h6: {
        fontFamily: 'Heebo',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '28px',
        color: mode === 'light' ? '#0F1f54' : '#fff',
      },
      subtitle1: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '28px',
        color: mode === 'light' ? '#0F1f54' : '#fff',
      },
      subtitle2: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '22px',
        color: mode === 'light' ? '#424242' : '#fff',
      },
      body1: {
        fontFamily: 'SatoshiVariable',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: mode === 'light' ? '#0F1f54' : '#fff',
      },
      body2: {
        fontFamily: 'SatoshiVariable',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '22px',
        color: mode === 'light' ? '#0F1f54' : '#fff',
      },
      caption: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        color: mode === 'light' ? '#0F1f54' : '#fff',
      },
    },

    palette: {
      mode,
      ...(mode === 'light'
        ? {
            background: {
              default: '#FCFCFD',
              paper: '#F0F4FF',
            },

            text: {
              primary: '#0F152A',
              secondary: '#424242',
            },
            primary: {
              main: '#BF2420',
              light: '#BF2420',
              dark: '#BF2420',
            },
            secondary: {
              main: '#297AF4',
              light: '#0F1F54',
              dark: '#297AF4',
            },
            divider: '#818181',
          }
        : {
            background: {
              default: '#475467',
              paper: '#101828',
            },

            text: {
              primary: '#0F152A',
              secondary: '#424242',
            },
            primary: {
              main: '#BF2420',
              light: '#BF2420',
              dark: '#BF2420',
            },
            secondary: {
              main: '#297AF4',
              light: '#297AF4',
              dark: '#297AF4',
            },
            divider: '#818181',
          }),
    },
    custom: {
      mode,
      ...(mode === 'light'
        ? {
            typography: {
              subTitle: {
                fontFamily: 'Roboto',
                fontSize: '14px',
                color: '#424242',
                lineHeight: 1.5,
                letterSpacing: 0.3,
              },
              title: {
                fontFamily: 'SatoshiVariableBold',
                fontStyle: 'bold',
                fontWeight: 700,
                fontSize: '18px',
                color: '#0F1F54',
              },
              subTitleV2: {
                fontFamily: 'SatoshiVariableBold',
                fontStyle: 'bold',
                fontWeight: 700,
                fontSize: '14px',
                lineHeight: '15px',
                color: '#0F1F54',
              },
              descriptionTitle: {
                fontFamily: 'SatoshiVariable',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '14px',
                color: '#0F1F54',
              },
              main: {
                color: mode === 'light' ? '#0F152A' : '#fff',
                fontSize: '18px',
                fontFamily: 'Heebo',
                lineHeight: 1.369,
                fontWeight: '400',
                letterSpacing: 0.3,
              },
              header: {
                fontFamily: 'Heebo',
                fontSize: '24px',
                marginLeft: '20px',
                fontWeight: 'bold',
                color: mode === 'light' ? '0F152A' : '#fff',
              },
              tagSubTitle: {
                fontFamily: 'Heebo-Medium',
                fontSize: '10px',
                color: mode === 'light' ? '0F152A' : '#fff',
              },
              tagBody: {
                fontFamily: 'Roboto',
                fontSize: '14px',
                color: mode === 'light' ? '0F152A' : '#fff',
              },
              tagHeader: {
                fontFamily: 'Heebo-Medium',
                fontSize: '21px',
                color: mode === 'light' ? '0F152A' : '#fff',
              },
            },
            modal: {
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'flexEnd',
              alignItems: 'center',
              backgroundColor: '#fff',
              color: ' #000',
              textAlign: 'center',
              borderRadius: '20px',
              padding: '30px 30px 70px',
            },

            modalCloseButton: {
              width: '30px',
              fontSize: '20px',
              color: '#c0c5cb',
              alignSelf: 'flex-end',
              backgroundColor: 'transparent',
              border: 'none',
              marginBottom: '10px',
            },
            modalAcceptButton: {
              backgroundColor: '#BF2420',
              border: 'none',
              borderRadius: '5px',
              width: '170px',
              padding: '14px',
              fontSize: '16px',
              color: 'white',
              boxShadow: ' 0px 6px 18px -5px rgba(237, 103, 85, 1)',
            },
            modalText: {
              fontSize: '18px',
              fontFamily: 'SatoshiVariable',
            },
            ltButton: {
              backgroundColor: '#A80000',
              color: '#FFFFFF',
              borderRadius: 29.5,
              fontWeight: 700,
              fontSize: 10,
              fontFamily: 'SatoshiVariable',
              whiteSpace: 'nowrap',
              textAlign: 'center',
            },
            lifeCodeDivBox: {
              borderRadius: '8px',
              padding: '7px',
              marginRight: '8px',
              backgroundColor: mode === 'light' ? '#ffffff90' : '#06123E',
              boxShadow: '0px 4px 24px #D9E2FF',
            },
            connectorSvg: {
              height: '24px',
              width: 'auto',
            },
            tagDivBox: {
              borderRadius: '8px',
              paddingLeft: '13px',
              paddingTop: '13px',
              paddingBottom: '20px',
              paddingRight: '13px',
              backgroundColor: mode === 'light' ? '#ffffff90' : '#06123E',
              width: '465px',
              height: '300px',
              boxShadow: '0px 4px 24px #D9E2FF',
            },
            smallTagDivBox: {
              borderRadius: '8px',
              paddingLeft: '13px',
              paddingTop: '13px',
              paddingBottom: '20px',
              paddingRight: '13px',
              backgroundColor: mode === 'light' ? '#ffffff90' : '#06123E',
              boxShadow: '0px 4px 24px #D9E2FF',
            },
            logo: {
              height: '28px',
              width: '28px',
              marginRight: '5px',
            },
            drawerPaper: {
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': {
                backgroundColor: '#F7F9FF',
                width: '224px',
                borderColor: '#F7F9FF',
              },
            },
            drawerPaperClosed: {
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': {
                backgroundColor: '#F7F9FF',
                width: '224px',
                borderColor: '#F7F9FF',
              },
            },
            listItem: {
              '&& .Mui-selected, && .Mui-selected:hover': {
                backgroundColor: '#DD0B06',
              },
            },
          }
        : {
            typography: {
              subTitle: {
                fontFamily: 'Roboto',
                fontSize: '14px',
                color: '#FFFFFF',
                fontWeight: '400',
              },
              main: {
                color: '#FFFFFF',
                fontSize: '18px',
                fontFamily: 'Heebo',
                lineHeight: '1.369em',
                fontWeight: '400',
              },
              subTitleV2: {
                fontFamily: 'SatoshiVariableBold',
                fontStyle: 'bold',
                fontWeight: 700,
                fontSize: '15px',
                lineHeight: '15px',
                color: '#ffffff',
              },
              descriptionTitle: {
                fontFamily: 'SatoshiVariable',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '14px',
                color: '#ffffff',
              },
            },
            logo: {
              height: '28px',
              width: '28px',
              marginRight: '5px',
            },
            connectorSvg: {
              height: '24x',
              width: 'auto',
            },

            drawerPaper: {
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': {
                backgroundColor: '#06123E',
                width: '224px',
                borderColor: '#0F1F54',
              },
            },
            drawerPaperClosed: {
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': {
                backgroundColor: '#06123E',
                width: '224px',
                borderColor: '#0F1F54',
              },
            },
          }),
      buttons: {
        color: '#f0f4ff',
      },
    },
  });

const theme = createMuiTheme({
  palette: {
    dark: {
      main: '#06123E',
    },
    light: {
      main: '#F7F9FF',
    },
  },
  custom: {
    header: {
      dark: {
        image: {
          width: '32px',
          height: '32px',
          objectFit: 'cover',
          background: 'white',
          borderRadius: '50%',
          marginTop: '-12px',
        },
        typeography: {
          main: {
            color: '#fff',
            fontSize: '18px',
            fontFamily: 'Heebo',
            lineHeight: '1.369em',
            fontWeight: '400',
          },
          sub: {
            color: '#fff',
            fontSize: '14px',
            fontFamily: 'Roboto',
            lineHeight: '1.5em',
            fontWeight: '400',
          },
        },
      },
      light: {
        image: {
          width: '32px',
          height: '32px',
          objectFit: 'cover',
          background: '#F0F4FF',
          borderRadius: '50%',
          marginTop: '-12px',
        },
        typeography: {
          main: {
            color: '#424242',
            fontSize: '18px',
            fontFamily: 'Heebo',
            lineHeight: '1.369em',
            fontWeight: '400',
          },
          sub: {
            color: '#424242',
            fontSize: '14px',
            fontFamily: 'Roboto',
            lineHeight: '1.5em',
            fontWeight: '400',
          },
        },
      },
    },
    menu: {
      dark: {
        type: {
          color: '#fff',
          fontSize: '14px',
          fontFamily: 'Roboto',
        },
        icon: {
          color: '#fff',
        },
      },
      light: {
        type: {
          color: '#424242',
          fontSize: '14px',
          fontFamily: 'Roboto',
        },
        icon: {
          color: '#DD0B06',
        },
      },
    },
    logo: {
      width: '140px',
      height: '32px',
      marginTop: '12px',
      marginBottom: '28px',
    },
    connectorSvg: {
      height: '24px',
      width: '24px',
    },
    common: {
      dark: {
        typography: {
          header: {
            fontFamily: 'Heebo',
            fontSize: '24px',
            marginLeft: '20px',
            fontWeight: 'bold',
            color: 'fff',
          },
          title: {
            fontFamily: 'Heebo',
            fontSize: '18px',
            marginLeft: '20px',
            fontWeight: 'bold',
            color: '#fff',
          },
          subTitle: {
            fontFamily: 'Roboto',
            fontSize: '14px',
            color: '#fff',
          },
        },
      },
      light: {
        typography: {
          header: {
            fontFamily: 'Heebo',
            fontSize: '24px',
            marginLeft: '20px',
            fontWeight: 'bold',
            color: '0F152A',
          },
          title: {
            fontFamily: 'Heebo',
            fontSize: '18px',
            marginLeft: '20px',
            fontWeight: 'bold',
            color: '#0F152A',
          },
          subTitle: {
            fontFamily: 'Roboto',
            fontSize: '14px',
            color: '#424242',
          },
        },
        redColor: {
          color: '#BF2420',
        },
      },
    },
  },
});

export default theme;
