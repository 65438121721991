import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { showSnack } from 'react-redux-snackbar';
import * as R from 'ramda';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import '@fontsource/heebo';

const useStyles = makeStyles((theme) => ({
  image: {
    objectFit: 'fill',
    height: '31px',
    marginBottom: '9.8px',
  },

  stopComplaining: theme.custom,

  uploadButton: {
    backgroundColor: '#F5F5F5',
    fontFamily: 'Heebo',
    color: '#424242',
    width: '56px',
    height: '24px',
  },

  subTitle: {
    color: '#E0E0E0',
    fontFamily: 'Heebo',
    fontSize: '14px',
  },

  uploadOutline: {
    borderColor: '#9575CD',
    border: 'dotted 1px',
    borderRadius: '4px',
    height: '137.7px',
    backgroundColor: '#F0F4FF',
    width: '280px',
  },
}));

function FileDrop(props) {
  const classes = useStyles();
  const [image, setImage] = React.useState(null);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    // Do something with the files
    if (!R.isEmpty(rejectedFiles)) {
      //console.log('about to show snackbar', rejectedFiles);
      showSnack('UI', {
        label: `Only ${acceptedFiles} allowed`,
        timeout: 3000,
      });
      return;
    }

    if (!R.isEmpty(acceptedFiles)) {
      //console.log('these are the accepted files', acceptedFiles);
      setImage(acceptedFiles[0]);
      props.handleFileUpload(acceptedFiles);
    }
  }, []);
  const {
    acceptedFiles,
    rejectedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: props.accept });

  return (
    <section className="container">
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragAccept && !R.isEmpty(acceptedFiles) && <p>{acceptedFiles}</p>}
        {(isDragReject || !R.isEmpty(rejectedFiles)) && (
          <p>Only images and videos allowed</p>
        )}
        {!isDragActive && (
          <Grid
            container
            alignItems={'center'}
            justify={'center'}
            className={classes.uploadOutline}
            direction={'column'}
          >
            <Grid item xs={'auto'}>
              {image ? (
                <img
                  alt="uploadTag"
                  src={URL.createObjectURL(image)}
                  className={classes.image}
                />
              ) : (
                <img
                  alt="uploadTag"
                  src="/images/upload-cloud.png"
                  className={classes.image}
                />
              )}
            </Grid>
            <Grid item xs={'auto'}>
              <Typography className={classes.uploadButton}>Upload</Typography>
            </Grid>
            <Grid item={'auto'}>
              <Typography className={classes.subTitle}>
                Or drop files to upload
              </Typography>
            </Grid>
          </Grid>
        )}
      </div>
    </section>
  );
}

export default FileDrop;
