import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TagPreview from './tagPreview.component';
import { Grid } from '@mui/material';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const removed = result[startIndex];
  result[startIndex] = result[endIndex];
  result[endIndex] = removed;

  return result;
};

const styles = {
  card: {
    position: 'relative',
    boxShadow: '0 8px 14px 0 rgba(0,0,0,0.5)',
    overflow: 'visible',
    borderRadius: '26px',
    transition: '0.4s',
    marginBottom: '15px',
    color: 'black',
    '&:hover': {
      transform: 'translateY(-7px)',
      '& $shadow': {
        bottom: '-1.5rem',
      },
      '& $shadow2': {
        bottom: '-2.5rem',
      },
    },
    //   '&:before': {
    //     content: '""',
    //     position: 'absolute',
    //     zIndex: 0,
    //     display: 'block',
    //     width: '100%',
    //     bottom: -1,
    //     height: '100%',
    //     borderRadius: '1.5rem',
    //     backgroundColor: 'rgba(252,252,252,1)',
    // },
  },
  heading: {
    fontFamily: ['Helvetica'].join(','),
    fontSize: 16,
    color: 'black',
    body1: {
      fontWeight: 500,
      fontFamily: 'Helvetica',
    },
    marginTop: 60,
    marginRight: 5,
  },
};

class DragContainer extends Component {
  constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.state = {
      items: this.props.items,
    };
    this.handleClick.bind(this);
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items,
    });

    for (var i = 0; i < items.length; i++) {
      let tag = items[i];
      tag.order = i;
      tag.id = tag.objectId;
      this.props.updateTag(tag);
    }
  }

  handleClick = (e, tag) => {
    this.props.clicked(tag);
  };

  render() {
    const { direction } = this.props;

    return (
      <div>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable" direction={direction}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                {...provided.dragHandleProps}
              >
                <Grid
                  container
                  spacing={2}
                  direction={direction}
                  justify="center"
                  alignItems="center"
                  style={{ marginLeft: '10px' }}
                >
                  {this.state.items.map((item, index) => (
                    <Draggable
                      key={index}
                      draggableId={item.objectId}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Grid
                            item
                            xs={12}
                            sx={{ m: 2 }}
                            onClick={(e) => this.handleClick(e, item)}
                          >
                            <TagPreview tag={item} showEditButtons={true} />
                          </Grid>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Grid>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}

export default withStyles(styles)(DragContainer);
