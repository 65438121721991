import {
  HELP,
  FETCH_HELP,
  DELETE_HELP,
  CREATE_HELP,
  UPDATE_HELP,
  updateHelp,
  setHelp,
  deleteHelp,
  createHelp,
} from './help.actions';
import { setNotification } from '../shared/actions/notification.actions';
import { setLoader } from '../shared/actions/ui.actions';
import {
  API_ERROR,
  API_SUCCESS,
  apiRequest,
} from '../shared/actions/api.actions';

export const helpMiddleWare = () => (next) => (action) => {
  next(action);

  switch (action.type) {
    case FETCH_HELP:
      //console.log('FETCH_HELP is the action', action);
      next(
        apiRequest({
          feature: HELP,
          params: action.payload,
          func: action.function,
        })
      );
      next(setLoader({ state: true, feature: HELP }));
      break;

    case DELETE_HELP:
      next(deleteHelp({ index: action.payload.index }));
      next(apiRequest({ feature: HELP, params: action.payload }));
      break;

    case CREATE_HELP:
      next(
        apiRequest({
          body: action.data,
          feature: HELP,
          params: action.payload,
          func: 'createHelp',
        })
      );
      break;

    case UPDATE_HELP:
      next(updateHelp({ index: action.index }));
      next(
        apiRequest({ body: action.data, feature: HELP, params: action.payload })
      );
      break;

    case `${HELP} ${API_SUCCESS}`:
      if (action.actionType === 'createHelp') {
        next(createHelp({ query: action.payload, feature: HELP }));
        next(setNotification({ message: 'Help Created', feature: HELP }));
      } else {
        next(setHelp({ help: action.payload }));
        next(setLoader({ state: false, feature: HELP }));
      }
      break;

    case `${HELP} ${API_ERROR}`:
      next(setNotification({ message: action.payload.message, feature: HELP }));
      next(setLoader({ state: false, feature: HELP }));
      break;

    default:
      break;
  }
};
