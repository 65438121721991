import {
  FETCH_USERCONNECTORS,
  SET_USERCONNECTOR,
} from './userconnector.actions';

const initState = { data: [] };

export const userConnectorReducer = (userConnector = initState, action) => {
  let newUserConnector = userConnector.data; //.slice()

  switch (action.type) {
    case SET_USERCONNECTOR:
      return {
        ...userConnector,
        data: action.payload,
      };
    case FETCH_USERCONNECTORS:
      //newUserConnector.splice(action.index, 1)
      return {
        ...userConnector,
        data: newUserConnector,
      };

    default:
      return userConnector;
  }
};
