import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Button, Grid, Link, TextField, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch } from 'react-redux';
import { createBeacon } from './beacon.actions';
//import {fetchTags} from "../tags/tag.actions";

function New(props) {
  const history = props.history;
  const theme = useTheme();
  const dispatch = useDispatch();
  const [name, setName] = React.useState('');
  const [uuid, setUUID] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [hasID, setHasID] = React.useState(false);

  const submitBeacon = () => {
    const query = {
      beaconId: uuid,
      description: description,
      name: name,
      type: 'beacon',
    };
    dispatch(createBeacon({ query: query }));
    setName('');
    setUUID('');
    setDescription('');
  };

  const updateUUID = (UUID) => {
    setUUID(UUID);
    if (uuid.length > 1) {
      setHasID(true);
    } else {
      setHasID(false);
    }
  };

  return (
    <div>
      <Grid container direction={'column'} spacing={2}>
        <Grid item xs={12}>
          <Typography sx={theme.custom.typography.title}>New Beacon</Typography>
        </Grid>
        <Grid item xs={12}>
          <Link
            sx={theme.custom.typography.descriptionTitle}
            onClick={() => history.push('/beacons')}
          >
            <ArrowBackIcon />
            Back
          </Link>
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            direction={'row'}
            item
            xs={6}
            spacing={2}
            justifyContent={'space-between'}
          >
            <Grid item xs={6}>
              <TextField
                id="name"
                value={name}
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  width: '100%',
                }}
                variant="outlined"
                label="Name"
                margin="normal"
                onChange={(value) => {
                  setName(value.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="uuid"
                value={uuid}
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  width: '100%',
                }}
                variant="outlined"
                label="UUID"
                margin="normal"
                required
                error={!hasID}
                onChange={(value) => {
                  updateUUID(value.target.value);
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid item xs={12}>
              <TextField
                id="description"
                value={description}
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  width: '100%',
                }}
                multiline={true}
                rows={4}
                variant="outlined"
                label="Description"
                margin="normal"
                onChange={(value) => {
                  setDescription(value.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Grid item xs={3}>
              <Button
                disabled={!hasID}
                sx={theme.custom.ltButton}
                onClick={() => submitBeacon()}
              >
                Create Beacon
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default New;
