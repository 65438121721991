import { connect } from 'react-redux';
import {
  fetchUserConnector,
  fetchUserConnectors,
} from './userconnector.actions';
import Index from './userconnector.index';
import Show from './userconnector.show';
import { fetchConnectors } from '../connectors/connector.actions';

const mapStateToProps = (state) => ({
  userConnectors: state.userConnector.data,
  connectors: state.connector.data,
});

const mapDispatchToProps = (dispatch) => ({
  getUserConnectors: () => dispatch(fetchUserConnectors()),
  getUserConnector: (id) => dispatch(fetchUserConnector(id)),
  fetchConnectors: () => dispatch(fetchConnectors()),
});

const connectorUserConnector = connect(mapStateToProps, mapDispatchToProps);

export const All = connectorUserConnector(Index);
//export const Create = connectorUserConnector(New);
//export const Update = connectorUserConnector(Edit);
export const Get = connectorUserConnector(Show);
//export const Delete = connectorUserConnector(Remove);
