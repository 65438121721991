import {
  NORMALIZE_DATE,
  NORMALIZE_ADDRESS,
  normalizeComplete,
} from '../../actions/normalize.actions';

export const normalizeMiddleWare =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    //console.log("returned action type:", action.type);
    if (action.type.includes(NORMALIZE_DATE)) {
      dispatch(normalizeComplete({}, 'feature'));
    }

    if (action.type.includes(NORMALIZE_ADDRESS)) {
      // //added for google maps
      //  const googleMapsClient = require('@google/maps').createClient({ Promise: Promise,
      //        key:  "AIzaSyDISV1DUR34c7b_E5ucQzkd2LlZc-p4yz0"
      //      });
      //
      // //googleMapsClient.headers
      //  googleMapsClient.findPlace({input: action.payload.address, inputtype: 'textquery', fields: 'geometry'})
      //      .asPromise()
      //        .then(() => {
      //          //console.log("find place success results", response.json.results);
      //        })
      //        .catch(() => {
      //          //console.log("find place error results", err);
      //        });
      //
      dispatch(normalizeComplete({}, 'feature'));
    }
  };
