import React, { Component } from 'react';

class Index extends Component {
  componentDidMount() {
    this.props.all();
  }

  render() {
    return (
      <iframe
        src="https://help.lifetaggerapp.com"
        width="100%"
        style={{ height: '90vh' }}
      ></iframe>
    );
  }
}

export default Index;
