import { Card, CardContent, CardHeader, Grid } from '@mui/material';
//import {useTheme} from "@material-ui/styles";
import { Get as Show } from '../tags/tag.container';
import React from 'react';
import ControlsComponent from './widgets/controls.component';
import TempatureComponent from './widgets/tempature.component';

function Demo() {
  //const theme = useTheme();
  const tag = {
    message:
      'Hello Brian,\n' +
      'Welcome to your room.  My name is Katrina and I will be helping to make sure your stay with us is an amazing experience.  Below this message you will find some helpful tools to enhance your in room experience and my recommendations for fun and entertainment while you are here.\n' +
      'Katrina',
    from: 'ALoft',
    html:
      '<p>Hello Brian,</p>\n' +
      '<p>Welcome to your room.  My name is Katrina and I will be helping to make sure your stay with us is an amazing experience.  Below this message you will find some helpful tools to enhance your in room experience and my reccomendations for fun and entertainment while you are here.</p>\n' +
      '<p>Katrina</p>\n',
    isPublic: true,
    hasWeb: false,
    userProfileImage:
      'https://lifetagger.s3.amazonaws.com/e7c2d34f28dff325be2c9cf293938bd2_image.png',
    imgUrl:
      'https://lifetagger.s3.amazonaws.com/b558114e57b3c3e5d52f660ee4ce11aa_image.jpeg',
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Show
          tag={tag}
          style={{ width: '100%', height: '100%' }}
          useMarkup={true}
          useHtml={true}
          mediaHeight="100%"
        />
      </Grid>
      <Grid item xs={12}>
        <Card
          style={{
            width: '100%',
            wordWrap: 'break-word',
            position: 'relative',
            boxShadow: '0 28px 14px 0 rgba(0,0,0,0.5)',
            overflow: 'visible',
            borderRadius: '16px',
            transition: '0.4s',
            marginBottom: '15px',
            paddingBottom: '10px',
            background: [
              '-moz-linear-gradient(top, rgba(139,0,0,0.93) 0%, rgba(139,0,0,0.41) 100%)',
              '-webkit-linear-gradient(top, rgba(139,0,0,0.93) 0%,rgba(139,0,0,0.41) 100%)',
              'linear-gradient(to bottom, rgba(139,0,0,0.93) 0%,rgba(139,0,0,0.41) 100%)',
            ],
            filter:
              "progid:DXImageTransform.Microsoft.gradient( startColorstr='#ed8b0000', endColorstr='#698b0000',GradientType=0 )",
            top: 12,
            marginLeft: '2%',
            color: 'black',
            '&:hover': {
              transform: 'translateY(-7px)',
              '& $shadow': {
                bottom: '-1.5rem',
              },
              '& $shadow2': {
                bottom: '-2.5rem',
              },
            },
          }}
        >
          <CardHeader title={<h4>Lighting Controls</h4>}></CardHeader>
          <CardContent>
            <ControlsComponent></ControlsComponent>
          </CardContent>
        </Card>
        <Card
          style={{
            width: '100%',
            wordWrap: 'break-word',
            position: 'relative',
            boxShadow: '0 28px 14px 0 rgba(0,0,0,0.5)',
            overflow: 'visible',
            borderRadius: '16px',
            transition: '0.4s',
            marginBottom: '15px',
            paddingBottom: '10px',
            background: [
              '-moz-linear-gradient(top, rgba(139,0,0,0.93) 0%, rgba(139,0,0,0.41) 100%)',
              '-webkit-linear-gradient(top, rgba(139,0,0,0.93) 0%,rgba(139,0,0,0.41) 100%)',
              'linear-gradient(to bottom, rgba(139,0,0,0.93) 0%,rgba(139,0,0,0.41) 100%)',
            ],
            filter:
              "progid:DXImageTransform.Microsoft.gradient( startColorstr='#ed8b0000', endColorstr='#698b0000',GradientType=0 )",
            top: 12,
            marginLeft: '2%',
            color: 'black',
            '&:hover': {
              transform: 'translateY(-7px)',
              '& $shadow': {
                bottom: '-1.5rem',
              },
              '& $shadow2': {
                bottom: '-2.5rem',
              },
            },
          }}
        >
          <CardHeader title={<h4>Temperature Controls</h4>}></CardHeader>
          <CardContent>
            <TempatureComponent></TempatureComponent>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Demo;
