import {
  MEDIA,
  FETCH_MEDIA,
  DELETE_MEDIA,
  CREATE_MEDIA,
  UPDATE_MEDIA,
  updateMedia,
  setMedia,
  deleteMedia,
  createMedia,
} from './media.actions';
import { setNotification } from '../shared/actions/notification.actions';
import { setLoader } from '../shared/actions/ui.actions';
import {
  API_ERROR,
  API_SUCCESS,
  apiRequest,
} from '../shared/actions/api.actions';
//import { showSnack, dismissSnack } from 'react-redux-snackbar';
//import {reject} from 'ramda'

export const mediaMiddleWare = () => (next) => (action) => {
  next(action);

  switch (action.type) {
    case FETCH_MEDIA:
      //console.log('FETCH_MEDIA is the action', action);
      next(
        apiRequest({
          feature: MEDIA,
          params: action.payload,
          func: action.function,
        })
      );
      next(setLoader({ state: true, feature: MEDIA }));
      break;

    case DELETE_MEDIA:
      next(deleteMedia({ index: action.payload.index }));
      next(apiRequest({ feature: MEDIA, params: action.payload }));
      break;

    case CREATE_MEDIA:
      next(
        apiRequest({
          body: action.data,
          feature: MEDIA,
          params: action.payload,
          func: 'createMedia',
        })
      );
      break;

    case UPDATE_MEDIA:
      next(updateMedia({ index: action.index }));
      next(
        apiRequest({
          body: action.data,
          feature: MEDIA,
          params: action.payload,
        })
      );
      break;

    case `${MEDIA} ${API_SUCCESS}`:
      if (action.actionType === 'createMedia') {
        next(createMedia({ query: action.payload, feature: MEDIA }));
        next(setNotification({ message: 'Media Created', feature: MEDIA }));
      } else {
        next(setMedia({ media: action.payload }));
        next(setLoader({ state: false, feature: MEDIA }));
      }
      break;

    case `${MEDIA} ${API_ERROR}`:
      next(
        setNotification({ message: action.payload.message, feature: MEDIA })
      );
      next(setLoader({ state: false, feature: MEDIA }));
      break;

    default:
      break;
  }
};
