import { connect } from 'react-redux';
import { deleteSession, createSession } from './session.actions';
import New from './session.new';
import Remove from './session.remove';
//import DeleteSession from './deleteSession.component';

const mapStateToProps = (state) => ({
  session: state.session,
  location: state.history,
  email: '',
  password: '',
});

const mapDispatchToProps = (dispatch) => ({
  remove: (query) => {
    dispatch(deleteSession(query));
  },

  create: (query) => {
    dispatch(createSession({ query }));
  },
});

const sessionsConnector = connect(mapStateToProps, mapDispatchToProps);

export const Create = sessionsConnector(New);
export const Delete = sessionsConnector(Remove);
