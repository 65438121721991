import React, { useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConnectors } from '../connectors/connector.actions';
import { fetchUserConnectors } from '../userconnectors/userconnector.actions';
import { Get as Show } from './userconnector.container';

function Index(props) {
  const dispatch = useDispatch();
  let connectors = useSelector((state) => state.connector?.data);
  let userConnectors = useSelector((state) => state.userConnector?.data);

  useEffect(() => {
    dispatch(fetchConnectors());
    dispatch(fetchUserConnectors());
  }, [dispatch]);

  return (
    <Grid container spacing={2} direction={'row'}>
      {userConnectors?.map((connector, index) => {
        let t = connectors?.find((c) => c.id === connector.connectorId);
        if (t && t.steps && t.steps.includes(props.step)) {
          return (
            <Grid item key={index}>
              <Button
                onClick={() => {
                  if (props.callBack) {
                    props.callBack(
                      t?.actions?.find((c) => c.step === props.step),
                      t.id
                    );
                  }
                }}
              >
                <Show connector={t}></Show>
              </Button>
            </Grid>
          );
        }
      })}
    </Grid>
  );
}

export default Index;
