import React, { Component } from 'react';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Card,
  CardContent,
  Box,
  Typography,
} from '@material-ui/core';
import { TableHeaderColumn } from 'material-ui/Table';
import { Create as CreateMedia } from '../media/media.container';

let theme = createMuiTheme();
const styles = {
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

  margin: {
    margin: theme.spacing.unit * 2,
  },

  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`,
  },

  card: {
    margin: '10px auto',
    width: '95%',
  },
  delete: {
    background: '#c22d08',
    border: 0,
    color: 'white',
    padding: '10px',
    boxShadow: '0 3px 5px 2px rgba(235, 64, 52 .3)',
    borderRadius: 5,
  },
};

class Index extends Component {
  componentDidMount() {
    this.props.all();
  }

  render() {
    const { classes } = this.props;
    return (
      <MuiThemeProvider>
        <CreateMedia></CreateMedia>

        <Box mt={8}>
          <Typography>
            <h3>My Media</h3>
          </Typography>
          <Card>
            <CardContent>
              <Table
                responsive
                striped
                bodered
                hover="true"
                size="small"
                fixedfooter="true"
              >
                <TableHead>
                  <TableRow>
                    <TableHeaderColumn align="center">Media </TableHeaderColumn>
                    <TableHeaderColumn align="center">Name</TableHeaderColumn>
                    <TableHeaderColumn align="center">
                      Description
                    </TableHeaderColumn>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.medias.map((item, key) => (
                    <TableRow className={classes.tabbleRow} key={key}>
                      <TableCell align="left">{item.name}</TableCell>
                      <TableCell align="left">{item.name}</TableCell>
                      <TableCell align="left">{item.description}</TableCell>
                      {/*<TableCell align="left">
                                            <Button className={classes.delete} onClick={(e) => this.props.deleteMedia(item.objectId) }>
                                              Delete
                                           </Button>
                                         </TableCell>*/}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Box>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(Index);
