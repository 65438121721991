import {
  SET_CHANNELS,
  SET_USER_CHANNELS,
  SET_USER_CHANNEL,
} from './channel.actions';
import * as R from 'ramda';

const initState = { data: [], userData: { channels: [] } };

const remove = (tags, tag) => {
  const index = R.findIndex(R.propEq('objectId', tag.objectId))(tags);

  if (index >= 0) {
    return [...tags.slice(0, index), ...tags.slice(index + 1, tags.length)];
  }

  return [...tags];
};

export const channelReducer = (channel = initState, action) => {
  //let newChannels = channel.userData.slice()
  switch (action.type) {
    case SET_CHANNELS:
      ////console.log("inside set channels : ", action.payload);
      return {
        ...channel,
        data: action.payload,
      };
    case SET_USER_CHANNELS:
      ////console.log("inside set user channels : ", action.payload);
      return {
        ...channel,
        userData: [...channel.userData.channels, action.payload],
      };

    case 'deleteChannel':
      return {
        ...channel,
        data: remove(channel, action.payload),
      };

    case SET_USER_CHANNEL:
      ////console.log("set user channel action payload:", action.payload)
      return {
        ...channel,
        userData: [...channel.userData.channels, action.payload],
      };

    default:
      return channel;
  }
};
