import { connect } from 'react-redux';
import { fetchTags } from '../tags/tag.actions';
import { fetchAnalytic, fetchImpression } from '../analytic/analytic.actions';
import { fetchMedia } from '../media/media.actions';
import Index from './dashboard.index';

const mapStateToProps = (state) => ({
  //maps all channeldata for the state to the channel
  tags: state.tag.data,
  location: state.history,
  analytics: state.analytic.data,
  impressions: state.analytic.impressionData,
  media: state.media.data,
  loading: state.ui.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getTags: (query) => dispatch(fetchTags(query, 'getTag')),
  getAnalytics: (query) => dispatch(fetchAnalytic({ query })),
  getImpressions: ({ query, func }) =>
    dispatch(fetchImpression({ query, func })),
  fetchMedia: () => dispatch(fetchMedia()),
});

const dashboardConnector = connect(mapStateToProps, mapDispatchToProps);

export const All = dashboardConnector(Index);
