import React from 'react';
import enLocale from 'i18n-iso-countries/langs/en.json';
import countries from 'i18n-iso-countries';
import { MenuItem, Select } from '@mui/material';

function CountrySelector(props) {
  const { handleCountrySelected } = props;
  countries.registerLocale(enLocale);
  const countryObj = countries.getNames('en', { select: 'official' });
  const countryArr = [];
  for (const [key, value] of Object.entries(countryObj)) {
    if (value && key) {
      countryArr.push({
        label: value,
        value: key,
      });
    }
  }

  return (
    <Select
      label={'Country'}
      variant={'standard'}
      onChange={(e) => handleCountrySelected(e.target.value)}
    >
      {countryArr.map((country) =>
        country ? (
          <MenuItem key={country.value} value={country.value}>
            {country.label}
          </MenuItem>
        ) : null
      )}
    </Select>
  );
}

export default CountrySelector;
