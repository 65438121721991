export const SUBSCRIBER = '[SUBSCRIBER]';

// action types
export const FETCH_SUBSCRIBER = `${SUBSCRIBER} FETCH`;
export const DELETE_SUBSCRIBER = `${SUBSCRIBER} DELETE`;
export const FETCH_SUBSCRIBERS = `${SUBSCRIBER} FETCH ALL`;

export const SET_SUBSCRIBERS = `${SUBSCRIBER} SET`;
export const CREATE_SUBSCRIBER = `${SUBSCRIBER} CREATE`;
export const UPDATE_SUBSCRIBER = `${SUBSCRIBER} UPDATE`;
export const SHOW_SUBSCRIBER = `${SUBSCRIBER} SHOW`;

export const fetchSubscribers = () => ({
  type: FETCH_SUBSCRIBERS,
  payload: null,
  function: 'getFollowers',
});

export const deleteSubscriber = ({ query }) => ({
  type: DELETE_SUBSCRIBER,
  payload: query,
  function: 'deleteSubscribers',
});

export const createSubscriber = ({ query }) => ({
  type: CREATE_SUBSCRIBER,
  payload: query,
  function: 'createSubscriber',
});

export const updateSubscriber = ({ query }) => ({
  type: UPDATE_SUBSCRIBER,
  index: query.index,
  payload: query.data,
});

export const showSubscriber = ({ query }) => ({
  type: SHOW_SUBSCRIBER,
  index: query.index,
  payload: query.data,
});

export const setSubscribers = ({ subscribers }) => ({
  type: SET_SUBSCRIBERS,
  payload: subscribers,
});
