import SearchMapComponent from './searchMap.component';
import TriggerTableComponent from './triggerTable.component';
import { DatePickerComponent } from './datePicker.component';

export const triggerItems = {
  location: {
    subHeader: 'Set tag location',
    prettyName: 'address',
    resource: 'address',
    component: SearchMapComponent,
  },
  beaconId: {
    subHeader: 'LifeCode/Beacons',
    prettyName: 'beacons',
    resource: 'beacons',
    component: TriggerTableComponent,
  },
  startDate: {
    subHeader: 'Date/Time',
    prettyName: 'Time',
    resource: 'expirationString',
    component: DatePickerComponent,
  },
  acrId: {
    subHeader: 'Media',
    prettyName: 'Media',
    resource: 'media',
    component: TriggerTableComponent,
  },
  pushId: {
    subHeader: 'Push',
    prettyName: 'Push Notification',
    resource: 'pushNotification',
    component: DatePickerComponent,
  },
  // lifeTapId: {
  // 	subHeader: "LifeTap",
  // 	prettyName: "LifeTap",
  // 	resource: "lifeTap",
  // 	component: TriggerTableComponent
  //
  // }
};
