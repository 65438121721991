import React, { Component } from 'react';
import {
  Grid,
  TextField,
  Card,
  CardContent,
  FormControl,
  RadioGroup,
  Button,
  FormHelperText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { isEmpty, forEachObjIndexed } from 'ramda';
import { Snackbar } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Parse } from 'parse';

import { loadStripe } from '@stripe/stripe-js';
import { Get as Show } from '../products/products.container';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

window.Intercom('boot', {
  app_id: 'wn943hcg',
});

let theme = createMuiTheme();
const styles = {
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

  margin: {
    margin: theme.spacing.unit * 2,
  },

  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`,
  },

  card: {
    position: 'relative',
    boxShadow: '0 8px 14px 0 rgba(0,0,0,0.5)',
    overflow: 'visible',
    borderRadius: '26px',
    transition: '0.4s',
    marginBottom: '15px',
    color: 'black',
    '&:hover': {
      transform: 'translateY(-7px)',
      '& $shadow': {
        bottom: '-1.5rem',
      },
      '& $shadow2': {
        bottom: '-2.5rem',
      },
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 0,
      display: 'block',
      width: '100%',
      bottom: -1,
      height: '100%',
      borderRadius: '1.5rem',
      backgroundColor: 'rgba(252,252,252,1)',
    },
  },
  formControl: {
    margin: theme.spacing(3),
  },

  descriptionText: {
    color: '#ffffff',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
  },
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

class New extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordConfirmation: '',
      username: '',
      phoneNumber: '',
      company: '',
      email: '',
      accountPlan: 'price_1JxGjpG7JJ7XmqTHBo3Y2o9t',
      disabled: true,
      emailError: false,
      passwordError: false,
      name: '',
      status: 'unpaid',
      countryCode: 'us',
      submitError: '',
      open: false,
    };
  }

  componentDidMount() {
    this.props.getProducts();
    document.body.style.backgroundColor = '#282c34';
  }

  handleClick = async () => {
    try {
      // Call your backend to create the Checkout session.
      const user = new Parse.User();
      const blackList = [
        'passwordConfirmation',
        'passwordError',
        'emailError',
        'disabled',
        'submitError',
        'open',
      ];

      const setParseUser = (value, key) => {
        if (!blackList.includes(key)) {
          user.set(key, value);
        }
      };

      forEachObjIndexed(setParseUser, this.state);

      let newUser = await user.save();

      if (newUser) {
        const sessionId = this.props.stripe.id;
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        await stripe.redirectToCheckout({
          sessionId,
        });
      }
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `error.message`.
    } catch (error) {
      this.setState({ submitError: error.message });
      this.setState({ open: true });
    }
  };

  setPassword = (event) => {
    this.setState({ password: event.target.value });
    this.shouldBeDisabled();
  };

  setPasswordConfirmation = (event) => {
    this.setState({ passwordConfirmation: event.target.value });
    if (this.state.password == event.target.value) {
      this.setState({ passwordError: false });
    }
    this.shouldBeDisabled();
  };

  setUserName = (event) => {
    this.setState({ username: event.target.value });
    this.shouldBeDisabled();
  };

  setName = (event) => {
    this.setState({ name: event.target.value });
    this.shouldBeDisabled();
  };

  setPhoneNumber = (phone, country) => {
    this.setState({ phoneNumber: phone });
    this.setState({ countryCode: country.countryCode });
    this.shouldBeDisabled();
  };

  setCompany = (event) => {
    this.setState({ company: event.target.value });
    this.shouldBeDisabled();
  };

  setAccountPlan = (event) => {
    this.setState({ accountPlan: event.target.value }, function () {
      this.props.fetchCheckoutSession(this.state);
    });
    this.setState({ plan: event.target.value });
    this.shouldBeDisabled();
  };

  setEmail = (event) => {
    if (event.target.value.includes('@') && event.target.value.includes('.')) {
      this.setState({ email: event.target.value, emailError: false });
      this.shouldBeDisabled();
    } else {
      this.setState({ emailError: true });
    }
  };

  handleEmailErrorChange = (event) => {
    if (event.target.value.includes('@') && event.target.value.includes('.')) {
      this.setState({ emailError: false });
    }
  };

  handlePasswordErrorChange = () => {
    if (this.state.password != this.state.passwordConfirmation) {
      this.setState({ passwordError: true });
    } else {
      this.setState({ passwordError: false });
    }
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  shouldBeDisabled = () => {
    let disabled = false;
    const blackList = [
      'passwordError',
      'emailError',
      'disabled',
      'submitError',
      'open',
    ];

    const allValuesFilled = (value, key) => {
      if (!blackList.includes(key)) {
        if (isEmpty(value) || (key === 'accountPlan' && value === 'trash')) {
          disabled = true;
        }
      }
    };

    //console.log("this is the current state ", this.state);

    forEachObjIndexed(allValuesFilled, this.state);
    this.setState({ disabled: disabled });
  };

  render() {
    const { classes } = this.props;
    let visibilityString = 'hidden';
    if (this.state.emailError) {
      visibilityString = 'visible';
    }

    let passwordVisibilityString = 'hidden';
    if (this.state.passwordError) {
      passwordVisibilityString = 'visible';
    }

    return (
      <Grid container justify="center">
        <Grid item sm={6}>
          <form onSubmit={this.handleSubmit}>
            <Grid item sm={12}>
              <Grid item sm={12}>
                <FormControl className={classes.formControl} varient="outlined">
                  <div>
                    <RadioGroup
                      aria-label="Account Plan"
                      name="accountPlan"
                      onChange={this.setAccountPlan.bind(this)}
                    >
                      <Grid container>
                        {this.props.products.map(
                          (product, key) =>
                            product.active &&
                            product.id != 'prod_N6Q3ALnPyziPpP' && (
                              <Grid item xs={12} xm={12} key={key}>
                                {key !== 0 ? (
                                  <Show product={product} />
                                ) : (
                                  <Show product={product} bestSeller={true} />
                                )}
                              </Grid>
                            )
                        )}
                      </Grid>
                    </RadioGroup>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </Grid>

        <Grid item sm={6}>
          <Card className={classes.card}>
            <CardContent>
              <TextField
                required
                id="Name"
                label="Name: "
                fullWidth
                defaultValue=""
                margin="normal"
                onChange={this.setName.bind(this)}
              />
              <TextField
                required
                id="userName"
                label="Username: "
                fullWidth
                defaultValue=""
                margin="normal"
                onChange={this.setUserName.bind(this)}
              />

              <TextField
                required
                id="email"
                label="Email: "
                fullWidth
                defaultValue=""
                margin="normal"
                onBlur={this.setEmail.bind(this)}
                onChange={this.handleEmailErrorChange.bind(this)}
                error={this.state.emailError}
              />
              <FormHelperText
                error={this.state.emailError}
                style={{ visibility: visibilityString }}
              >
                The email you entered is invalid
              </FormHelperText>

              <TextField
                required
                id="password"
                label="Password: "
                type="password"
                fullWidth
                rows="3"
                defaultValue=""
                margin="normal"
                variant="outlined"
                error={this.state.passwordError}
                name="password"
                onChange={this.setPassword.bind(this)}
                onBlur={this.handlePasswordErrorChange.bind(this)}
              />
              <TextField
                required
                id="password"
                label="Password Confirmation: "
                type="password"
                fullWidth
                rows="3"
                defaultValue=""
                margin="normal"
                variant="outlined"
                error={this.state.passwordError}
                name="passwordConfirmation"
                onChange={this.setPasswordConfirmation.bind(this)}
                onBlur={this.handlePasswordErrorChange.bind(this)}
              />
              <FormHelperText
                error={this.state.passwordError}
                style={{ visibility: passwordVisibilityString }}
              >
                The passwords do not match
              </FormHelperText>
            </CardContent>
            <CardContent>
              <PhoneInput
                placeholder="Phone Number "
                country={'us'}
                value={this.state.phoneNumber}
                onChange={(phone, country) => {
                  this.setPhoneNumber(phone, country);
                }}
                inputProps={{
                  name: 'phone',
                  required: true,
                }}
              />

              <TextField
                required
                id="company"
                label="Company: "
                fullWidth
                rows="3"
                defaultValue=""
                margin="normal"
                onChange={this.setCompany.bind(this)}
              />

              <Button
                variant="contained"
                color="secondary"
                fullWidth
                disabled={this.state.disabled}
                onClick={() =>
                  this.handleClick({ plan: 'plan_HHense3Ui2808C' })
                }
              >
                Submit
              </Button>
            </CardContent>
            <CardContent>
              <Button href="dashboard" color="primary">
                Already A User? Sign in here.
              </Button>
            </CardContent>
          </Card>
        </Grid>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={this.state.open}
          onClose={this.handleClose}
          message={this.state.submitError}
        />
      </Grid>
    );
  }
}

export default withStyles(styles)(New);
