import React from 'react';
import { Grid } from '@mui/material';
import { PushNotificationFollow } from './pushNotificationFollow.component';
import { isIOSAndVersionAtLeast } from '../utilities';

const BottomToolbar = (params) => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobileSafari =
    /ipad|iphone|ipod/.test(userAgent) &&
    /applewebkit/.test(userAgent) &&
    !window.MSStream;

  const isCompatible = isIOSAndVersionAtLeast(16.4);
  const shouldPushNotification =
    window.PushManager || (isMobileSafari && isCompatible);
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        padding: '16px',
        zIndex: 100,
      }}
    >
      {shouldPushNotification && (
        <Grid item>
          <PushNotificationFollow
            owner={params.userId}
            from={params.from}
            callBack={params.callBack}
            existingUser={params.existingUser}
            beacon={params.beacon}
          />
        </Grid>
      )}
      <Grid item>{/* add other components here */}</Grid>
    </Grid>
  );
};

export default BottomToolbar;
