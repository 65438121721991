import React from 'react';
import '@fontsource/heebo';
import {
  Button,
  ButtonGroup,
  CardMedia,
  Dialog,
  Divider,
  Grid,
  Popover,
  Typography,
} from '@mui/material';
import Chip from '@material-ui/core/Chip';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { Get as Show, Update as Edit } from '../tags/tag.container';
import IPhoneComponent from './iPhone.component';
import CodeIcon from '@mui/icons-material/Code';
import { CardContent } from '@material-ui/core';
import PDF from './widgets/pdf.component';
import TagIframe from './tagIframe.component';
import { useTheme } from '@mui/material/styles';
// function SizeMe() {
//     return null;
// }

function TagPreview(props) {
  const theme = useTheme();
  const { tag, useMarkup, showEditButtons, autoSize, deleteTagButton } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [type, setType] = React.useState(null);
  const width = props.width || theme.custom.tagDivBox.width;
  const showWebContent = props.showWebContent || false;
  const showVertical = props.showVertical || false;

  const openPopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  if (!useMarkup) {
    tag.markUp = tag.message;
  }

  let hasMedia = false;
  if (tag.imgUrl || tag.hasWeb || tag.isVideo || tag.image) {
    hasMedia = true;
  }

  const renderPreviewModal = (type) => {
    setType(type);
    setAnchorEl(null);
    setModalOpen(true);
  };

  const renderMedia = () => {
    let renderWeb = tag.hasWeb && !tag.imgUrl && !tag.isVideo ? true : false;
    let hasUrl = tag.webUrl?.length ? tag.webUrl[0] : tag.hasWeb;
    const renderImage =
      (tag.image || tag.imgUrl) &&
      !tag.isVideo &&
      !tag.hasWeb &&
      tag.type != 'pdf'
        ? true
        : false;
    const fileURL = tag.image ? URL.createObjectURL(tag.image[0]) : tag.imgUrl;

    return (
      // <SizeMe> {({size}) =>
      <Grid item sx={{ height: '100%' }}>
        {renderImage && (
          <CardMedia
            src={fileURL}
            title={tag.message}
            component="img"
            sx={{
              borderRadius: '8px',
              paddingLeft: '15px',
              objectFit: 'cover',
              height: '100%',
              overflowY: 'hidden',
            }}
          />
        )}

        {tag.isVideo && (
          <CardMedia
            src={tag.imgUrl}
            component="video"
            controls
            sx={{ borderRadius: '8px', paddingLeft: '15px' }}
          />
        )}

        {tag.type === 'pdf' && (
          <Grid item xs={12}>
            <CardContent>
              <PDF advanceButtons={false} file={fileURL} />
            </CardContent>
          </Grid>
        )}
        {renderWeb && (
          <Grid item>
            {!showWebContent ? (
              <div>
                <CodeIcon />
                <Typography sx={{ size: '10px', fontSize: '12px' }}>
                  Web content~ Click . . . to preview.
                </Typography>
              </div>
            ) : (
              <CardContent
                sx={{
                  borderRadius: '8px',
                  justifyContent: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  boxShadow: '0px 4px 24px #D9E2FF',
                }}
              >
                <TagIframe
                  url={hasUrl}
                  metaData={tag.metaData}
                  iframeAble={tag.iframeAble}
                />
              </CardContent>
            )}
          </Grid>
        )}
      </Grid>
    );
    // }
    //  {/*</SizeMe>*/}
    //);
  };

  if (!showVertical) {
    return (
      <Grid
        container
        direction="row"
        item
        sx={
          autoSize
            ? { ...theme.custom.smallTagDivBox }
            : { ...theme.custom.tagDivBox, width: width }
        }
      >
        <Dialog
          open={modalOpen}
          onClose={handleModalClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Grid container>
            <Grid item xs={'auto'}>
              {type == 'preview' && (
                <IPhoneComponent
                  screen={
                    <Grid container>
                      {[tag].map((tag, key) => (
                        <Grid item xs={12} key={key}>
                          <Show
                            tag={tag}
                            style={{ width: '100%', height: '100%' }}
                            useMarkup={true}
                            useHtml={true}
                            mediaHeight="100%"
                            showHeader={tag.showHeader}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  }
                />
              )}
              {type == 'edit' && (
                <Edit tag={tag} handleClose={handleModalClose()} />
              )}
            </Grid>
          </Grid>
        </Dialog>
        <Grid item md={7}>
          <Grid container item xs={12} direction={'row'}>
            <Grid item md={10}>
              <Typography variant={'h6'}>{tag.from}</Typography>
            </Grid>
            {showEditButtons && (
              <Grid item md={2}>
                <Button
                  sx={{ color: theme.palette.primary.main, size: '18px' }}
                  variant="text"
                  onClick={openPopOver}
                >
                  ...
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <ButtonGroup
                    orientation="vertical"
                    aria-label="vertical outlined button group"
                  >
                    <Button
                      variant="contained"
                      sx={{
                        bgcolor: theme.palette.primary.main,
                        '&:hover': { bgcolor: theme.palette.primary.light },
                      }}
                      onClick={() => {
                        renderPreviewModal('preview');
                      }}
                    >
                      Preview
                    </Button>
                    {/*<Button*/}
                    {/*    sx={{*/}
                    {/*        bgcolor: theme.palette.primary.main,*/}
                    {/*        '&:hover': {bgcolor: theme.palette.primary.light}*/}
                    {/*    }}*/}
                    {/*    onClick={() => {renderPreviewModal('edit')}}*/}

                    {/*    variant="contained">*/}
                    {/*    Edit Tag*/}
                    {/*</Button>*/}
                  </ButtonGroup>
                </Popover>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} container direction={'row'} alignItems={'center'}>
            {tag.endDate && tag.startDate && (
              <>
                <Grid item container direction={'row'}>
                  <Grid item md={1}>
                    <AccessTimeOutlinedIcon
                      sx={{
                        color: theme.palette.primary.light,
                        height: '12px',
                      }}
                    />
                  </Grid>
                  <Grid item md={5}>
                    <Typography sx={{ fontSize: '.8vw' }} variant={'caption'}>
                      {tag.startDate.toLocaleTimeString([], {
                        year: '2-digit',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={1}>
                  <AccessTimeOutlinedIcon
                    sx={{ color: theme.palette.primary.light, height: '12px' }}
                  />
                </Grid>
                <Grid item md={5}>
                  <Typography sx={{ fontSize: '.8vw' }} variant={'caption'}>
                    {tag.endDate.toLocaleTimeString([], {
                      year: '2-digit',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>

          <Grid item xs={12} sx={{ paddingTop: '12px', paddingBottom: '15px' }}>
            <Typography
              variant={'caption'}
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 4,
              }}
            >
              {tag.message}
            </Typography>
          </Grid>
          <Divider />
          <Grid item xs={12} sx={{ marginTop: '17px' }}>
            <Typography
              sx={{
                paddingBottom: '15px',
                ...theme.custom.typography.tagBody,
              }}
            >
              Channels
            </Typography>
            <Grid container direction={'row'} spacing={1}>
              {tag.channels?.length !== 0 ? (
                tag.channels?.map((channel, i) => (
                  <Grid item key={i}>
                    <Chip label={channel} value={channel} />
                  </Grid>
                ))
              ) : (
                <Grid item style={{ marginLeft: '10px', marginBottom: '10px' }}>
                  <Chip label={'public'} value={'public'} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        {deleteTagButton && (
          <Grid item xs={12}>
            <Button
              variant={'contained'}
              color={'error'}
              onClick={() => {
                deleteTagButton(tag.id);
              }}
            >
              Delete
            </Button>
          </Grid>
        )}
        <Grid item md={5} sx={{ height: '100%' }}>
          <Grid container direction={'column'} sx={{ height: '100%' }}>
            {hasMedia && renderMedia()}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="container"
      item
      sx={
        autoSize
          ? { ...theme.custom.smallTagDivBox }
          : { ...theme.custom.tagDivBox, width: '100%', height: '100%' }
      }
    >
      <Grid item xs={12}>
        {hasMedia && renderMedia()}
      </Grid>
      <Grid item xs={12}>
        <Grid container item xs={12} direction={'row'}>
          <Grid item md={10}>
            <Typography variant={'h6'}>{tag.from}</Typography>
          </Grid>
          {showEditButtons && (
            <Grid item md={2}>
              <Button
                sx={{ color: theme.palette.primary.main, size: '18px' }}
                variant="text"
                onClick={openPopOver}
              >
                ...
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <ButtonGroup
                  orientation="vertical"
                  aria-label="vertical outlined button group"
                >
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: theme.palette.primary.main,
                      '&:hover': { bgcolor: theme.palette.primary.light },
                    }}
                    onClick={() => {
                      renderPreviewModal('preview');
                    }}
                  >
                    Preview
                  </Button>
                  {/*<Button*/}
                  {/*    sx={{*/}
                  {/*        bgcolor: theme.palette.primary.main,*/}
                  {/*        '&:hover': {bgcolor: theme.palette.primary.light}*/}
                  {/*    }}*/}
                  {/*    onClick={() => {renderPreviewModal('edit')}}*/}

                  {/*    variant="contained">*/}
                  {/*    Edit Tag*/}
                  {/*</Button>*/}
                </ButtonGroup>
              </Popover>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} container direction={'row'} alignItems={'center'}>
          {tag.endDate && tag.startDate && (
            <>
              <Grid item container direction={'row'}>
                <Grid item md={1}>
                  <AccessTimeOutlinedIcon
                    sx={{ color: theme.palette.primary.light, height: '12px' }}
                  />
                </Grid>
                <Grid item md={8}>
                  <Typography sx={{ fontSize: '.9vw' }} variant={'caption'}>
                    {tag.startDate.toLocaleTimeString([], {
                      year: '2-digit',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item md={1}>
                <AccessTimeOutlinedIcon
                  sx={{ color: theme.palette.primary.light, height: '12px' }}
                />
              </Grid>
              <Grid item md={8}>
                <Typography sx={{ fontSize: '.9vw' }} variant={'caption'}>
                  {tag.endDate.toLocaleTimeString([], {
                    year: '2-digit',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>

        <Grid item xs={12} sx={{ paddingTop: '12px', paddingBottom: '15px' }}>
          <Typography
            variant={'caption'}
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 4,
            }}
          >
            {tag.message}
          </Typography>
        </Grid>
        <Divider />
      </Grid>
      {deleteTagButton && (
        <Grid item xs={12}>
          <Button
            onClick={() => {
              deleteTagButton(tag.id);
            }}
          >
            Delete
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default TagPreview;
