export const HELP = '[HELP]';

// action types
export const FETCH_HELP = `${HELP} FETCH`;
export const DELETE_HELP = `${HELP} DELETE`;
export const SET_HELP = `${HELP} SET`;
export const CREATE_HELP = `${HELP} CREATE`;
export const UPDATE_HELP = `${HELP} UPDATE`;
export const SHOW_HELP = `${HELP} SHOW`;

export const fetchHelp = () => ({
  type: FETCH_HELP,
  payload: null,
  function: 'getHelp',
});

export const deleteHelp = ({ query }) => ({
  type: DELETE_HELP,
  payload: query,
  function: 'deleteHelp',
});

export const createHelp = ({ query }) => ({
  type: CREATE_HELP,
  payload: query,
  function: 'createHelp',
});

export const updateHelp = ({ query }) => ({
  type: UPDATE_HELP,
  index: query.index,
  payload: query.data,
});

export const showHelp = ({ query }) => ({
  type: SHOW_HELP,
  index: query.index,
  payload: query.data,
});

export const setHelp = ({ help }) => ({
  type: SET_HELP,
  payload: help,
});
