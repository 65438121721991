import { connect } from 'react-redux';
import { getProducts } from '../products/products.actions.js';
import { Show } from './products.show';

const mapStateToProps = (state) => ({
  //maps all channeldata for the state to the channel
  products: state.products.data,
});

const mapDispatchToProps = (dispatch) => ({
  getProducts: () => dispatch(getProducts()),

  show: (query) => dispatch(getProducts({ query })),
});

const productsConnector = connect(mapStateToProps, mapDispatchToProps);

//export const All = productsConnector(Index);
export const Get = productsConnector(Show);
