import React, { useEffect, useState } from 'react';
import {
  Grid,
  CardContent,
  CardMedia,
  Typography,
  Link,
  Divider,
  Button,
  Box,
} from '@mui/material';
import ListIcon from '@material-ui/icons/List';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useTheme } from '@mui/material/styles';
import { ShowQr } from './beacon.container';
import '@fontsource/heebo';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//import TagIframe from "../ui/tagIframe.component";
import * as R from 'ramda';
import TagIframe from '../ui/tagIframe.component';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTags, updateTag, deleteTag } from '../tags/tag.actions';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import TagPreview from '../ui/tagPreview.component';
import AnalyticChart from '../ui/chart.component';
import { fetchAnalytic, fetchImpression } from '../analytic/analytic.actions';

function QrCodeDetails(props) {
  const { history } = props;
  const dispatch = useDispatch();
  let tags = useSelector((state) => state.tag.data);
  let impressions = useSelector((state) => state.analytic.impressionData);
  let analytic = useSelector((state) => state.analytic.data);
  let [qrTags, setQrTags] = useState([]);

  const qrCodeId =
    window.location.href.split('/')[window.location.href.split('/').length - 1];
  const qrCode = props.beacons.find((beacon) => beacon.beaconId == qrCodeId);
  const theme = useTheme();
  const [currentTag, setCurrentTag] = useState(null);

  const tagSelected = (tag) => {
    // useEffect(() => {
    setCurrentTag(tag);
    //}, [])
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const removed = result[startIndex];
    result[startIndex] = result[endIndex];
    result[endIndex] = removed;
    return result;
  };

  useEffect(() => {
    dispatch(fetchTags({ id: qrCodeId }, 'getTagsByBeaconId'));
    dispatch(
      fetchAnalytic({
        query: { beaconId: qrCodeId },
        func: 'qrImpressionsById',
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (currentTag) {
      dispatch(
        fetchImpression({
          query: { id: currentTag.objectId, count: true },
          func: 'getImpressions',
        })
      );
    }
  }, [currentTag]);

  useEffect(() => {
    const newTags = R.sortBy(R.prop('order'))(tags);
    setQrTags(newTags);
    if (tags.length > 0) {
      setCurrentTag(newTags[0]);
    } else {
      setCurrentTag(null);
    }
  }, [tags]);

  const renderChart = (chart) => {
    return (
      <AnalyticChart
        ChartTitle={chart.chartTitle}
        ChartType={chart.chartType}
        DataArray={chart.data}
        xTitle={chart.xTitle}
        yTitle={chart.yTitle}
        colors={chart.colors}
      />
    );
  };

  const onDragEnd = (result) => {
    // dropped outside the list

    if (!result.destination) {
      return;
    }

    const items = reorder(
      qrTags,
      result.source.index,
      result.destination.index
    );

    for (let i = 0; i < items.length; i++) {
      let tag = items[i];
      tag.order = i;
      tag.id = tag.objectId;
      dispatch(updateTag({ query: tag }));
    }
  };

  const renderTagMedia = () => {
    const renderWeb = !!(
      currentTag?.hasWeb &&
      currentTag?.imgUrl &&
      currentTag?.isVideo
    );
    const dontShowIframe = false;
    return (
      currentTag && (
        <Grid item>
          {currentTag.imgUrl && !currentTag.isVideo && (
            <CardMedia
              // className={classes.media}
              sx={{ overflowY: 'hidden' }}
              src={currentTag.imgUrl}
              title={currentTag.message}
              component="img"
            />
          )}

          {currentTag.isVideo && (
            <CardMedia
              //className={classes.media}
              src={currentTag.imgUrl}
              component="video"
              controls
            />
          )}

          {currentTag.image &&
            currentTag.isVideo &&
            currentTag.image.map((file, i) => (
              <CardMedia
                //className={classes.media}
                src={URL.createObjectURL(file)}
                title={currentTag.message || ''}
                key={i}
                component="img"
              />
            ))}

          {renderWeb && !dontShowIframe ? (
            <CardContent>
              <TagIframe
                url={currentTag.hasWeb}
                metaData={currentTag.metaData}
                iframeAble={currentTag.iframeAble}
              />
            </CardContent>
          ) : (
            renderWeb && (
              <CardContent>
                <div>Web content</div>
              </CardContent>
            )
          )}
        </Grid>
      )
    );
  };

  return (
    // <div>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography sx={theme.custom.typography.title}>
          {qrCode.type == 'beacon' ? 'Beacon' : 'LifeCode'}
        </Typography>
      </Grid>
      <Grid container item xs={12} direction="row" spacing={2}>
        <Grid item xs={12} lg={3}>
          <Grid item xs={12}>
            <Link
              sx={theme.custom.typography.descriptionTitle}
              onClick={() =>
                history.push(qrCode.type == 'beacon' ? '/beacons' : '/qrcode')
              }
            >
              <ArrowBackIcon />
              Back
            </Link>
          </Grid>
          {qrCode && (
            <Grid item xs={12}>
              <ShowQr
                qrCode={qrCode}
                history={history}
                size={250}
                deleteButton={true}
                direction={'column'}
                justifyContent="flex-start"
              />
            </Grid>
          )}
          {analytic && (
            <Grid item xs={12}>
              {renderChart({
                chartType: 'ColumnChart',
                data: analytic,
                colors: ['#DD0B06', '#2F3F73'],
              })}
            </Grid>
          )}
        </Grid>
        <Grid item xs={0.5}>
          <Divider orientation="vertical" />
        </Grid>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <Grid
                item
                xs={12}
                lg={4}
                ref={provided.innerRef}
                {...provided.droppableProps}
                {...provided.dragHandleProps}
              >
                <Grid item xs={12}>
                  <Typography sx={theme.custom.typography.subTitleV2}>
                    Tags
                  </Typography>
                </Grid>
                {qrTags.length > 0 &&
                  qrTags.map((item, index) => (
                    <Draggable
                      key={index}
                      draggableId={index.toString()}
                      index={index}
                    >
                      {(provided) => (
                        // <div
                        //     ref={provided.innerRef}
                        //     {...provided.draggableProps}
                        //     {...provided.dragHandleProps}
                        //     sx={{marginBottom: "7px"}}
                        // >
                        <Grid
                          item
                          xs={12}
                          sx={{ m: 2 }}
                          onClick={() => tagSelected(item)}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <TagPreview
                            tag={item}
                            showEditButtons={true}
                            width={'380px'}
                            onClick={() => tagSelected(item)}
                          />
                          <Box
                            px={1}
                            style={{
                              alignItems: 'center',
                              justifyContent: 'center',
                              display: 'flex',
                            }}
                          >
                            <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item>
                                <Button
                                  aria-label="edit"
                                  size="small"
                                  sx={{ color: '#0F1F54' }}
                                  onClick={() => {
                                    history.push(`/tag/edit/${item.objectId}`);
                                    //this.renderEdit(item);
                                  }}
                                >
                                  <ListIcon />
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  aria-label="delete"
                                  sx={{ color: '#DD0B06' }}
                                  size="small"
                                  onClick={() => {
                                    dispatch(deleteTag({ id: item.objectId }));
                                  }}
                                >
                                  <DeleteForeverIcon />
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        // </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>

        <Grid item xs={1}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={12} lg={3}>
          <Grid container column={'column'} spacing={2}>
            <Grid item xs={12}>
              <Typography sx={theme.custom.typography.subTitleV2}>
                Tag
              </Typography>
            </Grid>
            {currentTag && (
              <Grid item xs={12}>
                <Typography>{currentTag.message}</Typography>
              </Grid>
            )}
            {currentTag && (
              <Grid item xs={12}>
                <Typography>{qrCode.name}</Typography>
              </Grid>
            )}
            {currentTag && (
              <Grid item xs={12} sx={{ marginBottom: '7px' }}>
                {renderTagMedia()}
              </Grid>
            )}
            <Grid container direction={'row'} spacing={1}>
              {currentTag && (
                <Grid item>
                  <Typography sx={{ marginleft: '7px' }}>
                    {'Created'}
                  </Typography>
                </Grid>
              )}

              {currentTag && (
                <Grid item>
                  <Typography>
                    {new Date(currentTag?.createdAt).toLocaleDateString()}
                  </Typography>
                </Grid>
              )}

              {currentTag && (
                <Grid item>
                  <Typography>{'Impressions'}</Typography>
                </Grid>
              )}

              {currentTag && (
                <Grid item>
                  {impressions && typeof impressions === 'number' && (
                    <Typography>{impressions}</Typography>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default QrCodeDetails;
