import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';

function TriggerButtonComponent(props) {
  const trigger = props.trigger;
  const activeTriggerStyle = {
    backgroundColor: '#FCFCFD',
    width: '68px',
    height: '67px',
    color: '#FCFCFD',
    padding: '5px',
    border: '1px solid #BF2420',
  };

  const inactiveTriggerStyle = {
    backgroundColor: '#FCFCFD',
    width: '68px',
    height: '67px',
    color: '#FCFCFD',
    padding: '3px',
    boxShadow: '0px 4px 4px 0px #00000040',
  };

  const triggerButtonCheckedStyle = {
    width: '12px',
    height: '12px',
    color: '#000',
  };

  return (
    <Grid
      container
      sx={{
        marginBottom: '24px',
        marginRight: '24px',
      }}
      direction={'row'}
    >
      <Grid item xs={'auto'}>
        <Button
          sx={
            props.selected?.triggerField === trigger?.triggerField
              ? activeTriggerStyle
              : inactiveTriggerStyle
          }
          onClick={() => props.onTriggerClick(trigger)}
        >
          <Grid container direction={'column'} justify={'space-between'}>
            <Grid item xs={12} style={{ textAlign: 'end' }}>
              {trigger?.active ? (
                <RadioButtonChecked sx={triggerButtonCheckedStyle} />
              ) : (
                <RadioButtonUnchecked sx={triggerButtonCheckedStyle} />
              )}
            </Grid>
            <Grid item xs={12}>
              {trigger?.icon}
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant={'caption'}
                sx={
                  props.selected?.triggerField === trigger?.triggerField
                    ? { color: '#BF2420', marginBottom: '2px' }
                    : { color: '#101828', marginBottom: '2px' }
                }
              >
                {trigger?.prettyName}
              </Typography>
            </Grid>
          </Grid>
        </Button>
      </Grid>
    </Grid>
  );
}

export default TriggerButtonComponent;
