import {
  CONNECTOR,
  FETCH_CONNECTOR,
  FETCH_CONNECTORS,
  FETCH_CONNECTORS_ACTIONS,
  setConnector,
  setConnectorActions,
} from './connector.actions';
import { setNotification } from '../shared/actions/notification.actions';
import { setLoader } from '../shared/actions/ui.actions';
import {
  API_ERROR,
  API_SUCCESS,
  apiRequest,
} from '../shared/actions/api.actions';

export const connectorMiddleWare = () => (next) => (action) => {
  next(action);
  switch (action.type) {
    case FETCH_CONNECTORS:
      next(
        apiRequest({
          feature: CONNECTOR,
          params: action.payload,
          api: action.api,
          method: action.method,
          endPoint: action.endPoint,
          func: action.function,
        })
      );
      next(setLoader({ state: true, feature: CONNECTOR }));
      break;
    case FETCH_CONNECTOR:
      next(
        apiRequest({
          feature: CONNECTOR,
          params: action.payload,
          api: action.api,
          method: action.method,
          endPoint: action.endPoint,
          func: action.function,
        })
      );
      next(setLoader({ state: true, feature: CONNECTOR }));
      break;
    case FETCH_CONNECTORS_ACTIONS:
      next(
        apiRequest({
          feature: CONNECTOR,
          params: action.payload,
          api: action.api,
          method: action.method,
          endPoint: action.endPoint,
          body: action.body,
          func: action.function,
        })
      );
      next(setLoader({ state: true, feature: CONNECTOR }));
      break;
    case `${CONNECTOR} ${API_SUCCESS}`:
      if (action.actionType == 'getConnectors') {
        next(setConnector({ connectors: action.payload }));
        next(setLoader({ state: false, feature: CONNECTOR }));
      }

      if (action.actionType == 'getConnectorActions') {
        ////console.log('FETCH_CONNECTOR_ACTIONS is the action', action.payload);
        next(setConnectorActions({ connectorActions: action.payload }));
        next(setLoader({ state: false, feature: CONNECTOR }));
      }
      next(setLoader({ state: false, feature: CONNECTOR }));
      break;
    case `${API_ERROR}`:
      //console.log("api error = ", action.payload);
      next(
        setNotification({ message: action.payload.message, feature: CONNECTOR })
      );
      next(setLoader({ state: false, feature: CONNECTOR }));
      break;
    default:
      break;
  }
};
