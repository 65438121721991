import { PRODUCT, FETCH_PRODUCT, setProducts } from './products.actions';
import { setNotification } from '../shared/actions/notification.actions';
import { setLoader } from '../shared/actions/ui.actions';
import {
  API_ERROR,
  API_SUCCESS,
  apiRequest,
} from '../shared/actions/api.actions';
//import { showSnack, dismissSnack } from 'react-redux-snackbar';
//import {reject} from 'ramda'

export const productsMiddleWare = () => (next) => (action) => {
  next(action);

  switch (action.type) {
    case FETCH_PRODUCT:
      //console.log('FETCH_PRODUCT is the action', action);
      next(
        apiRequest({
          feature: PRODUCT,
          params: action.payload,
          func: action.function,
        })
      );
      next(setLoader({ state: true, feature: PRODUCT }));
      break;

    case `${PRODUCT} ${API_SUCCESS}`:
      next(setProducts({ products: action.payload }));
      next(setLoader({ state: false, feature: PRODUCT }));

      break;

    case `${PRODUCT} ${API_ERROR}`:
      //console.log("error message", action.payload);
      next(
        setNotification({ message: action.payload.message, feature: PRODUCT })
      );
      next(setLoader({ state: false, feature: PRODUCT }));
      break;
    default:
      break;
  }
};
