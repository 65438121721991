export const MEDIA = '[MEDIA]';

// action types
export const FETCH_MEDIA = `${MEDIA} FETCH`;
export const DELETE_MEDIA = `${MEDIA} DELETE`;
export const SET_MEDIA = `${MEDIA} SET`;
export const CREATE_MEDIA = `${MEDIA} CREATE`;
export const UPDATE_MEDIA = `${MEDIA} UPDATE`;
export const SHOW_MEDIA = `${MEDIA} SHOW`;

export const fetchMedia = () => ({
  type: FETCH_MEDIA,
  payload: null,
  function: 'getMedia',
});

export const deleteMedia = ({ query }) => ({
  type: DELETE_MEDIA,
  payload: query,
  function: 'deleteMedia',
});

export const createMedia = ({ query }) => ({
  type: CREATE_MEDIA,
  payload: query,
  function: 'createMedia',
});

export const updateMedia = ({ query }) => ({
  type: UPDATE_MEDIA,
  index: query.index,
  payload: query.data,
});

export const showMedia = ({ query }) => ({
  type: SHOW_MEDIA,
  index: query.index,
  payload: query.data,
});

export const setMedia = ({ media }) => ({
  type: SET_MEDIA,
  payload: media,
});
