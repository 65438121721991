export const API_REQUEST = 'API_REQUEST';
export const API_SUCCESS = 'API_SUCCESS';
export const API_ERROR = 'API_ERROR';

export const apiRequest = ({
  body,
  method,
  feature,
  headers,
  params,
  func,
  api,
  endPoint,
}) => ({
  type: `${feature} ${API_REQUEST}`,
  payload: body,
  meta: { method, feature, headers, params, func, api, endPoint },
});

export const apiSuccess = ({ results, feature, func }) => ({
  type: `${feature} ${API_SUCCESS}`,
  payload: results,
  meta: feature,
  actionType: func,
});

export const apiError = ({ error, feature }) => ({
  type: `${feature} ${API_ERROR}`,
  payload: error,
  meta: { feature },
});
