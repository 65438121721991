export const TAGS = '[TAGS]';

// action types
export const FETCH_TAGS = `${TAGS} FETCH`;
export const DELETE_TAG = `${TAGS} DELETE`;
export const SET_TAGS = `${TAGS} SET`;
export const CREATE_TAG = `${TAGS} CREATE`;
export const UPDATE_TAG = `${TAGS} UPDATE`;
export const SHOW_TAG = `${TAGS} SHOW`;

export const fetchTags = (query, func) => ({
  type: FETCH_TAGS,
  payload: query,
  function: func,
});

export const deleteTag = (query) => ({
  type: DELETE_TAG,
  payload: query,
  function: 'deleteTag',
});

export const createTag = ({ query }) => ({
  type: CREATE_TAG,
  payload: query,
  function: 'createTag',
});

export const updateTag = ({ query }) => ({
  type: UPDATE_TAG,
  function: 'updateMyTag',
  payload: query,
});

export const showTag = ({ query }) => ({
  type: SHOW_TAG,
  index: query.index,
  payload: query.data,
});

export const setTags = ({ tags }) => ({
  type: SET_TAGS,
  payload: tags,
});
