import React, { useState } from 'react';
import Webcam from 'react-webcam';
import { Button, Grid } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import VideocamIcon from '@mui/icons-material/Videocam';

function TagMediaCapture() {
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);

  const [capturing, setCapturing] = React.useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [showWebCam, setShowWebCam] = useState(false);

  const captureImage = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setThumbnail(imageSrc);
    setShowWebCam(false);
  });

  const showTheWebCam = () => {
    setShowWebCam(true);
  };

  const startCapture = React.useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: 'video/webm',
    });
    mediaRecorderRef.current.addEventListener(
      'dataavailable',
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const stopCapture = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, setCapturing]);

  const handleDataAvailable = ({ data }) => {
    if (data.size > 0) {
      setThumbnail(URL.createObjectURL(data));
      setShowWebCam(false);
    }
  };

  return (
    <div>
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item xs={12}>
          {!showWebCam ? (
            <Button
              variant={'contained'}
              size="large"
              fullWidth
              startIcon={<PhotoCameraIcon />}
              onClick={showTheWebCam}
            ></Button>
          ) : (
            <Webcam
              audio={true}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                width: 220,
                height: 200,
                facingMode: { exact: 'environment' },
              }}
            />
          )}
        </Grid>
        {showWebCam && (
          <Grid item xs={6}>
            {capturing ? (
              <Button
                startIcon={<VideocamIcon />}
                variant={'contained'}
                color="secondary"
                onClick={stopCapture}
              >
                Stop
              </Button>
            ) : (
              <Button startIcon={<VideocamIcon />} onClick={startCapture}>
                Video
              </Button>
            )}
          </Grid>
        )}
        {showWebCam && (
          <Grid item xs={6}>
            <Button startIcon={<PhotoCameraIcon />} onClick={captureImage}>
              Photo
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          {thumbnail && <img src={thumbnail} alt="Captured media" />}
        </Grid>
      </Grid>
    </div>
  );
}

export default TagMediaCapture;
