import React from 'react';
import {
  Fab,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import AddIcon from '@material-ui/icons/Add';
import { RemoveOutlined } from '@mui/icons-material';

function Temperature() {
  const [temp, setTemp] = React.useState(74);
  const handleAdd = () => {
    const t = temp + 1;
    setTemp(t);
  };

  const handleMinus = () => {
    const t = temp - 1;
    setTemp(t);
  };

  // const handleChange = () => {
  //
  // };
  return (
    <Stack spacing={2} direction="column" sx={{ mb: 1 }} alignItems="center">
      <FormGroup>
        <FormControlLabel
          control={<Switch defaultChecked />}
          label="Heat/Cool"
        />
      </FormGroup>
      <Stack direction="row" sx={{ mr: 2, ml: 2 }} alignItems="center">
        <Fab
          color="primary"
          sx={{ mr: 2, ml: 2 }}
          aria-label="add"
          onClick={handleAdd}
        >
          <AddIcon />
        </Fab>
        <Typography>{temp}</Typography>
        <Fab
          color="primary"
          sx={{ mr: 2, ml: 2 }}
          aria-label="add"
          onClick={handleMinus}
        >
          <RemoveOutlined />
        </Fab>
      </Stack>
    </Stack>
  );
}

export default Temperature;
