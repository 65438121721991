import React, { useEffect } from 'react';
import { Grid, Typography, IconButton } from '@material-ui/core';
import { SizeMe } from 'react-sizeme';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button } from '@mui/material';
import axios from 'axios';

function PDF(props) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  const [zoom, setZoom] = React.useState(1);
  const [page, setPage] = React.useState(1);
  const [pages, setPages] = React.useState(0);
  const { advanceButtons, file } = props;
  const [fileData, setFileData] = React.useState();

  const addZoom = () => {
    if (zoom + 0.1 > 2) {
      setZoom(2);
    } else {
      const newZoom = zoom + 0.1;
      setZoom(parseFloat(newZoom));
    }
  };

  useEffect(() => {
    axios
      .get(file, { responseType: 'arraybuffer' })
      .then((value) => setFileData(value.data))
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const minusZoom = () => {
    if (zoom - 0.1 < 0.1) {
      setZoom(0.1);
    } else {
      const newZoom = zoom - 0.1;
      setZoom(parseFloat(newZoom));
    }
  };

  const advancePage = () => {
    if (page < pages) {
      setPage(page + 1);
    }
  };

  const previousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPages(numPages);
  };

  return (
    <div>
      <Grid container direction={'column'}>
        <Grid item xs>
          {fileData && (
            <SizeMe>
              {({ size }) => (
                <Document
                  file={{ data: fileData }}
                  onLoadSuccess={onDocumentLoadSuccess}
                  style={{ overflowY: 'scroll' }}
                >
                  <div>
                    <Grid container direction={'row'} xs={12} spacing={1}>
                      <Grid item xs>
                        <Page
                          key={`page_${page}/${pages}`}
                          pageNumber={page}
                          width={size.width ? size.width : 1}
                          scale={zoom}
                          renderMode={'svg'}
                          style={{ overflowY: 'scroll' }}
                        ></Page>
                      </Grid>
                      <Grid
                        container
                        item
                        direction={'row'}
                        justifyContent={'space-evenly'}
                      >
                        {pages > 1 && page > 1 && advanceButtons && (
                          <Grid item xs={'auto'}>
                            <Button
                              size={'small'}
                              variant={'contained'}
                              color={'primary'}
                              onClick={() => previousPage()}
                            >
                              Previous
                            </Button>
                          </Grid>
                        )}
                        {pages > 1 && advanceButtons && (
                          <Grid item xs={'auto'}>
                            <Button
                              size={'small'}
                              variant={'contained'}
                              onClick={() => advancePage()}
                            >
                              Next
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </Document>
              )}
            </SizeMe>
          )}
        </Grid>
        {advanceButtons && (
          <Grid item xs>
            <Grid
              container
              direction={'row'}
              alignItems={'center'}
              justify={'space-between'}
              wrap={'nowrap'}
            >
              <Grid item>
                <Typography variant={'caption'}>
                  Page {page}/{pages}
                </Typography>
              </Grid>
              <Grid item>
                <Grid container direction={'row'} alignItems={'center'}>
                  <Grid item xs>
                    <IconButton onClick={() => minusZoom()}>
                      <RemoveIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs>
                    <Typography variant={'caption'}>
                      {(zoom * 100).toFixed(0) + '%'}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <IconButton onClick={() => addZoom()}>
                      <AddIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default PDF;
