import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import { store, history } from './store';
import { Provider } from 'react-redux';
import { AppContainer } from './shared/app.container';
import * as serviceWorker from './serviceWorker';
import CssBaseline from '@material-ui/core/CssBaseline';
import { toggledTheme } from './theme';

import 'bootstrap/dist/css/bootstrap.css';
import 'typeface-roboto-condensed';

import './App.css';

import ReactGA from 'react-ga';
import { ThemeProvider } from '@mui/material/styles';

const trackingId = 'UA-58770692-1';
ReactGA.initialize(trackingId);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', async () => {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then(async (registration) => {
        console.log(
          'Service Worker registered with scope index.js....: ',
          registration.scope
        );
        console.log(
          'navigator service work...  ',
          navigator.serviceWorker.controller
        );
        await navigator.serviceWorker.ready;

        if (navigator.serviceWorker.controller) {
          const messageChannel = new MessageChannel();
          messageChannel.port1.onmessage = (event) => {
            console.log('this is the event data ', event.data);
            if (event.data.standalone) {
              // Redirect to the PWA's start URL
              window.location.href = `${window.location.href}`; // Replace with your PWA's start URL
            }
          };

          console.log('message channel ', messageChannel.port2);
          navigator.serviceWorker.controller.postMessage(
            'checkIfPWAInstalled',
            [messageChannel.port2]
          );
        }
      })
      .catch(function (err) {
        console.log('Service Worker registration failed: ', err);
      });
  });
}

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={toggledTheme('light')}>
      <CssBaseline />
      <AppContainer history={history} />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
