import { connect } from 'react-redux';
import { fetchUser, createUser } from './user.actions';
//import Index from './user.index';
import New from './user.new';
//import Show from './user.show';
import Edit from './user.edit';
import { fetchCheckoutSession } from '../stripe/stripe.actions.js';
import { getProducts } from '../products/products.actions.js';

const mapStateToProps = (state) => ({
  user: state.user,
  stripe: state.stripe.data,
  products: state.products.data,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUser: (query) => dispatch(fetchUser({ query })),
  createUser: (user) => dispatch(createUser({ user })),
  fetchCheckoutSession: (query) => dispatch(fetchCheckoutSession(query)),
  getProducts: () => dispatch(getProducts()),
});

const userConnector = connect(mapStateToProps, mapDispatchToProps);

//export const All = userConnector(Index);
export const Create = userConnector(New);
export const Update = userConnector(Edit);
// export const All = userConnector(Index);
// export const Get = userConnector(Show);
