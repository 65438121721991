import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import { LineChart, BarChart, ColumnChart, PieChart } from 'react-chartkick';
import 'chartkick/chart.js';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@mui/material';

const styles = {
  header: {
    fontWeight: 'bold',
    margin: '10px 0',
  },
  paragraph: {
    marginBottom: 20,
  },
};

class ChartComponent extends Component {
  drawTable(arrayData) {
    let allData = arrayData;
    let chartData = [];
    let currentDay = '',
      hits = 0,
      totalHits = 0,
      data,
      createdAt;

    try {
      //allAnalytics.forEach(data => {
      for (let i = 0; i < allData.length; i += 1) {
        totalHits += 1;
        data = allData[i];
        createdAt = data.createdAt.toLocaleDateString('en-US');
        if (currentDay == '') {
          currentDay = createdAt;
          hits = 1;
        } else {
          //date changed
          if (currentDay != '' && currentDay != createdAt) {
            chartData.push([currentDay, hits]);
            hits = 0;
            currentDay = createdAt;
          }
          hits += 1;
        }

        if (totalHits == allData.length) {
          chartData.push([currentDay, hits]);
        }
      } //);
    } catch (error) {
      ////console.log("error loading chart data:", error);
    }
    //console.log("print data array:", chartData);
    return chartData;
  }

  setTagMenuIsOpen(isOpen) {
    this.tagMenuIsOpen = isOpen;
  }

  render() {
    const { ChartTitle, ChartType, DataArray, xTitle, yTitle, colors } =
      this.props;
    const lineChartOptions = {
      scaleShowGridLines: true,
      scaleGridLineColor: 'rgba(0,0,0,.05)',
      scaleGridLineWidth: 1,
      scaleShowHorizontalLines: true,
      scaleShowVerticalLines: true,
      bezierCurve: true,
      bezierCurveTension: 0.4,
      pointDot: true,
      pointDotRadius: 4,
      pointDotStrokeWidth: 1,
      pointHitDetectionRadius: 20,
      datasetStroke: true,
      datasetStrokeWidth: 2,
      datasetFill: true,
      // legendTemplate: '<ul class="<%=name.toLowerCase()%>-legend"><% for (var i=0; i<datasets.length; i++){%><li><span style="background-color:<%=datasets[i].strokeColor%>"></span><%if(datasets[i].label){%><%=datasets[i].label%><%}%></li><%}%></ul>',
    };

    let analyticChart;
    if (ChartType == 'LineChart') {
      analyticChart = (
        <LineChart
          data={this.drawTable(DataArray)}
          xtitle={xTitle}
          ytitle={yTitle}
          curve={false}
          legend={false}
          messages={{ empty: 'No data available ' }}
          discrete={false}
          colors={colors}
          options={lineChartOptions}
          download={true}
        />
      );
    } else if (ChartType == 'ColumnChart') {
      analyticChart = (
        <ColumnChart
          data={this.drawTable(DataArray)}
          xtitle={xTitle}
          ytitle={yTitle}
          stacked={true}
          legend={false}
          messages={{ empty: 'No data available' }}
          discrete={true}
          colors={colors ? colors : '#b00'}
          options={lineChartOptions}
          download={true}
        />
      );
    } else if (ChartType == 'BarChart') {
      analyticChart = (
        <BarChart
          data={this.drawTable(DataArray)}
          xtitle={xTitle}
          ytitle={yTitle}
          stacked={true}
          legend={false}
          messages={{ empty: 'No data available' }}
          discrete={true}
          colors={colors ? colors : '#b00'}
          options={lineChartOptions}
          download={true}
        />
      );
    } else if (ChartType == 'PieChart') {
      analyticChart = (
        <PieChart
          data={this.drawTable(DataArray)}
          stacked={true}
          colors={colors ? colors : '#b00'}
          legend={false}
          messages={{ empty: 'No data available' }}
          options={lineChartOptions}
          download={true}
        />
      );
    }

    //console.log("the props in the chart component", this.props);
    return (
      <Grid item>
        <Typography variant="h8">{ChartTitle}</Typography>
        <Typography
          variant="h5"
          style={{
            color:
              JSON.stringify(colors) === JSON.stringify(['#0F1F54', '#0F1F54'])
                ? '#FFFFFF'
                : '#0F152A',
          }}
        >
          {DataArray != null ? DataArray.length : 0}
        </Typography>
        {analyticChart}
      </Grid>
    );
  }
}

export default withStyles(styles)(ChartComponent);
