import React from 'react';
import { Grid } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';

// const useStyles = makeStyles((theme) => ({
//   svgIcon: theme.custom.connectorSvg,
//   divBorder: theme.custom.divBorder,
//   header: theme.custom.typography.integrationsHeader,
//   subTitle: theme.custom.typography.tagSubTitle,
// }));

function Show(props) {
  const { connector } = props;
  const theme = useTheme();
  return (
    <Grid container justifyContent={'center'}>
      <Grid item xs={12}>
        {connector?.svg && (
          <Tooltip title={connector.name}>
            <div>
              <img
                style={theme.custom.connectorSvg}
                src={`data:image/svg+xml;utf8,${encodeURIComponent(
                  connector.svg
                )}`}
              />
            </div>
          </Tooltip>
        )}
      </Grid>

      <Grid item xs={'auto'}>
        <Grid container direction={'column'} justifyContent={'center'}>
          <Grid item xs={'auto'}>
            <Typography variant={'subtitle2'}>{connector.name}</Typography>
          </Grid>
          <Grid item>
            <Typography variant={'caption'}>
              {String(connector.steps)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Show;
