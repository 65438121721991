export const SESSIONS = '[SESSIONS]';

// action types
export const DELETE_SESSION = `${SESSIONS} DELETE`;
export const SET_SESSIONS = `${SESSIONS} SET`;
export const CREATE_SESSION = `${SESSIONS} CREATE`;

export const deleteSession = (query) => ({
  type: DELETE_SESSION,
  payload: query,
  function: 'deleteSession',
});

export const createSession = ({ query }) => ({
  type: CREATE_SESSION,
  payload: query,
  function: 'createSession',
});

export const setSessions = (sessions) => ({
  type: SET_SESSIONS,
  payload: sessions,
});
